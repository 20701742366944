var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "translate",
      style: _vm.backCss,
      on: {
        mousemove: _vm.mousemove,
        mouseup: _vm.mouseup,
        mousedown: _vm.handleMouseDown,
      },
    },
    [
      _vm.allLoadding || _vm.isSaved
        ? _c(
            "div",
            {
              staticClass: "all-loading",
              style: _vm.isSaved ? "background:rgba(0,0,0,0)" : "",
            },
            [
              _c(
                "a-spin",
                {
                  attrs: {
                    spinning: _vm.allLoadding,
                    size: "large",
                    tip: _vm.loaddingText,
                  },
                },
                [
                  _c("a-icon", {
                    staticStyle: { "font-size": "40px" },
                    attrs: { slot: "indicator", type: "loading", spin: "" },
                    slot: "indicator",
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("content-list", {
        ref: "contentList",
        attrs: {
          contentList: _vm.contentList,
          showOptions: _vm.showOptions,
          departName: _vm.departName,
          authList: _vm.authList,
          gloabalBtnLoading: _vm.gloabalBtnLoading,
          bottomHeight: _vm.bottomHeight,
          isRejectStatus: _vm.isRejectStatus,
          textList: _vm.textList,
          changeAll: _vm.changeAll,
          fontFamilyList: _vm.fontFamilyList,
          color: _vm.color,
          taskDetail: _vm.taskDetail,
          userInfo: _vm.userInfo,
          revisionList: _vm.revisionList,
          logList: _vm.logList,
          showAnnotationList: _vm.showAnnotationList,
          leftAnnotationList: _vm.leftAnnotationList,
          rightAnnotationList: _vm.rightAnnotationList,
          defualtTextTypeNo: _vm.defualtTextTypeNo,
          selectTextAreaData: _vm.selectTextAreaData,
          errorDictList: _vm.errorDictList,
          pageListData: _vm.pageListData,
          showImgLeftListPostions: _vm.showImgLeftListPostions,
          textBatchStyle:
            this.$refs.centerButtonList &&
            this.$refs.centerButtonList.textBatchStyle,
          oldTextBatchStyle:
            this.$refs.centerButtonList &&
            this.$refs.centerButtonList.oldTextBatchStyle,
          textTypes: _vm.textTypes,
        },
        on: {
          defualtIsDragText: _vm.defualtIsDragText,
          pushLoacal: _vm.pushLoacal,
          changeDefualtTextTypeNo: _vm.changeDefualtTextTypeNo,
          closeLocal: _vm.closeLocal,
          changeYahoo: _vm.changeYahoo,
          changeGloabalBtnLoading: _vm.changeGloabalBtnLoading,
          showImgHandleImgClick: _vm.showImgHandleImgClick,
          handleImgClick: _vm.handleImgClick,
          clearSelect: _vm.clearSelect,
          searchTextList: _vm.searchTextList,
          copyData: _vm.copyData,
          getShowimgRevisionList: _vm.getShowimgRevisionList,
          deleRevision: _vm.deleRevision,
          getLocalList: _vm.getLocalList,
          savePageList: _vm.savePageList,
          saveGloabal: _vm.saveGloabal,
          getPageListContent: _vm.getPageListContent,
          getRevisionList: _vm.getRevisionList,
          changeSelectList: _vm.changeSelectList,
          closeContentDarg: _vm.closeContentDarg,
          changeContentDarg: _vm.changeContentDarg,
          textTypeBatchChange: _vm.textTypeBatchChange,
          textTypeBatchChangeNo: _vm.textTypeBatchChangeNo,
          saveLocal: _vm.saveLocal,
          changeAnnotationListSelect: _vm.changeAnnotationListSelect,
          topCenterMouseover: _vm.topCenterMouseover,
          topCenterMouseout: _vm.topCenterMouseout,
        },
      }),
      _c("div", { staticClass: "translate-top" }, [
        _c("div", { staticClass: "translate-top-center" }, [
          _c(
            "div",
            {
              staticClass: "translate-top-center-content",
              on: {
                mouseover: _vm.topCenterMouseover,
                mouseout: _vm.topCenterMouseout,
              },
            },
            [
              _c("center-button-list", {
                ref: "centerButtonList",
                attrs: {
                  departName: _vm.departName,
                  globalSetting: _vm.globalSetting,
                  taskConfirmStatus: _vm.taskConfirmStatus,
                  authList: _vm.authList,
                  changeAll: _vm.changeAll,
                  defualtTextType: _vm.defualtTextType,
                  isRejectStatus: _vm.isRejectStatus,
                  color: _vm.color,
                  fontFamilyList: _vm.fontFamilyList,
                  isAddText: _vm.isAddText,
                  taskDetail: _vm.taskDetail,
                  userInfo: _vm.userInfo,
                  copyContent:
                    this.$refs.showImgContent &&
                    this.$refs.showImgContent.copyContent,
                  isAddAnnotation: _vm.isAddAnnotation,
                  phoneticTexts: _vm.phoneticTexts,
                  selectTextAreaData: _vm.selectTextAreaData,
                  isAutoSave: _vm.isAutoSave,
                },
                on: {
                  changeTaskConfirmStatus: _vm.changeTaskConfirmStatus,
                  changeEditType: _vm.changeEditType,
                  blackListModeChange: _vm.blackListModeChange,
                  topCenterMouseover: _vm.topCenterMouseover,
                  ocr: _vm.ocr,
                  clearCopyContent: _vm.clearCopyContent,
                  changeShowImgListWidth: _vm.changeShowImgListWidth,
                  downLoadImg: _vm.downLoadImg,
                  changeAutoSave: _vm.changeAutoSave,
                  openPhonetic: _vm.openPhonetic,
                  openEmj: _vm.openEmj,
                  addSentence: _vm.addSentence,
                  openSearch: _vm.openSearch,
                  openNote: _vm.openNote,
                  addTerm: _vm.addTerm,
                  addTermBack: _vm.addTermBack,
                  textBatchChange: _vm.textBatchChange,
                  backData: _vm.backData,
                  nextData: _vm.nextData,
                  copyData: _vm.copyData,
                  savePagelist: _vm.savePageList,
                  changeIsAddText: _vm.changeIsAddText,
                  changeIsAddTwo: _vm.changeIsAddTwo,
                  changeIsAddAnnotation: _vm.changeIsAddAnnotation,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "translate-bottom" },
        [
          _c("div", { ref: "pageList", staticClass: "translate-bottom-left" }, [
            _c(
              "div",
              [
                _c(
                  "div",
                  {
                    staticClass: "translate-bottom-left-show-icon",
                    on: { click: _vm.changePageListWidth },
                  },
                  [
                    _vm.pageListShow
                      ? _c("a-icon", { attrs: { type: "left" } })
                      : _c("a-icon", { attrs: { type: "right" } }),
                  ],
                  1
                ),
                _vm.checkPermission("pageList:view")
                  ? _c("page-list", {
                      ref: "pageListContent",
                      attrs: {
                        pageListData: _vm.pageListData,
                        jpgListData: _vm.jpgListData,
                        showOptions: _vm.showOptions,
                        chapterList: _vm.chapterList,
                        pageListShow: _vm.pageListShow,
                        selectIdx: _vm.selectIdx,
                        pageListPostions: _vm.pageListPostions,
                        taskDetail: _vm.taskDetail,
                      },
                      on: {
                        getPageListPostions: _vm.getPageListPostions,
                        changeShowScroll: _vm.changeShowScroll,
                        changePage: _vm.changePage,
                        changeSelectIdx: _vm.changeSelectIdx,
                      },
                    })
                  : _c("div", { staticClass: "no-permission" }, [
                      _c("span", [_vm._v("无权限")]),
                    ]),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { ref: "showImg", staticClass: "translate-bottom-center" },
            [
              _c(
                "div",
                {
                  staticClass: "drag-box",
                  attrs: { tabIndex: "-1" },
                  on: {
                    mousedown: _vm.showImgMousedown,
                    mouseleave: _vm.showImagMouseleave,
                    mousemove: _vm.showImgMouseout,
                    keydown: _vm.showImgKeydown,
                  },
                },
                [
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.is_show_mask,
                        expression: "is_show_mask",
                      },
                    ],
                    staticClass: "mask",
                    style:
                      "width:" +
                      _vm.mask_width +
                      "left:" +
                      _vm.mask_left +
                      "height:" +
                      _vm.mask_height +
                      "top:" +
                      _vm.mask_top,
                  }),
                  _c("show-img", {
                    ref: "showImgContent",
                    attrs: {
                      departName: _vm.departName,
                      isSaved: _vm.isSaved,
                      phoneticBlackList: _vm.phoneticBlackList,
                      isOpenPhoneticModal: _vm.isOpenPhoneticModal,
                      ocrLoading: _vm.ocrLoading,
                      ischangeScroll: _vm.ischangeScroll,
                      textTypes: _vm.textTypes,
                      allTermBacks: _vm.allTermBacks,
                      isDownLoad: _vm.isDownLoad,
                      defualtTextTypeNo: _vm.defualtTextTypeNo,
                      authList: _vm.authList,
                      blackListMode: _vm.blackListMode,
                      isRejectStatus: _vm.isRejectStatus,
                      jpgListData: _vm.jpgListData,
                      color: _vm.color,
                      isWindows: _vm.isWindows,
                      changeAll: _vm.changeAll,
                      userInfo: _vm.userInfo,
                      taskStatus: _vm.taskStatus,
                      changeScrollHeihgt: _vm.changeScrollHeihgt,
                      isCenterClick: _vm.isCenterClick,
                      showImgListWidth: _vm.showImgListWidth,
                      isAddAnnotation: _vm.isAddAnnotation,
                      showImgLeftListPostions: _vm.showImgLeftListPostions,
                      pageListData: _vm.pageListData,
                      is_show_mask: _vm.is_show_mask,
                      selectIdx: _vm.selectIdx,
                      textAreaRegions: _vm.textAreaRegions,
                      leftAnnotationList: _vm.leftAnnotationList,
                      rightAnnotationList: _vm.rightAnnotationList,
                      blacklist: _vm.blacklist,
                      filtrationList: _vm.filtrationList,
                      terms: _vm.terms,
                      termsAll: _vm.termsAll,
                      termBacks: _vm.termBacks,
                      termNotes: _vm.termNotes,
                      glossaryTags: _vm.glossaryTags,
                      errorDictList: _vm.errorDictList,
                      taskDetail: _vm.taskDetail,
                      select_list: _vm.select_list,
                      selectTextAreas: _vm.selectTextAreas,
                      selectTextAreaData: _vm.selectTextAreaData,
                      showOptions: _vm.showOptions,
                      isAddText: _vm.isAddText,
                      oldTextBatchStyle:
                        this.$refs.centerButtonList &&
                        this.$refs.centerButtonList.oldTextBatchStyle,
                      textBatchStyle:
                        this.$refs.centerButtonList &&
                        this.$refs.centerButtonList.textBatchStyle,
                    },
                    on: {
                      pushLoacal: _vm.pushLoacal,
                      toStageChange: _vm.toStageChange,
                      closeLocal: _vm.closeLocal,
                      textAreaDbclick: _vm.textAreaDbclick,
                      resizeChange: _vm.resizeChange,
                      changeTranslate: _vm.changeTranslate,
                      showImgClick: _vm.showImgClick,
                      ocr: _vm.ocr,
                      changeTextStyle: _vm.changeTextStyle,
                      changeTermsPhonetics: _vm.changeTermsPhonetics,
                      openTermBackEditModal: _vm.openTermBackEditModal,
                      clearAnchorNode: _vm.clearAnchorNode,
                      changeCardInput: _vm.changeCardInput,
                      clearSelect: _vm.clearSelect,
                      getTermsBack: _vm.getTermsBack,
                      copyData: _vm.copyData,
                      topCenterMouseout: _vm.topCenterMouseout,
                      changeAnchorNodeBefore: _vm.changeAnchorNodeBefore,
                      topCenterMouseover: _vm.topCenterMouseover,
                      changeLeftImgShow: _vm.changeLeftImgShow,
                      changeRightImgShow: _vm.changeRightImgShow,
                      changeAnchorNode: _vm.changeAnchorNode,
                      changeIsAddText: _vm.changeIsAddText,
                      savePageList: _vm.savePageList,
                      changeIsDragText: _vm.changeIsDragText,
                      changeSelectTexts: _vm.changeSelectTexts,
                      getRevisionList: _vm.getRevisionList,
                      delePageList: _vm.delePageList,
                      clearPhoneticTexts: _vm.clearPhoneticTexts,
                      batchDeleteTextArea: _vm.batchDeleteTextArea,
                      changeIsAddTerm: _vm.changeIsAddTerm,
                      openEditTermModal: _vm.openEditTermModal,
                      getLocalList: _vm.getLocalList,
                      changeLeftOption: _vm.changeLeftOption,
                      changeSelectIdx: _vm.changeSelectIdx,
                      getShowImgListPostions: _vm.getShowImgListPostions,
                      changeLeftAnnotationList: _vm.changeLeftAnnotationList,
                      changeRightAnnotationList: _vm.changeRightAnnotationList,
                      saveLocal: _vm.saveLocal,
                      onActivatedAnnotation: _vm.onActivatedAnnotation,
                      addTextArea: _vm.addTextArea,
                      changeError: _vm.changeError,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              ref: "optionList",
              staticClass: "translate-bottom-right",
              on: {
                mouseover: function ($event) {
                  _vm.isCenterClick = true
                },
                mouseout: function ($event) {
                  _vm.isCenterClick = false
                },
              },
            },
            [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "option-list" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "translate-bottom-right-show-icon",
                        on: { click: _vm.changeOptionListWidth },
                      },
                      [
                        _vm.optionListWidth <= 50
                          ? _c("a-icon", { attrs: { type: "left" } })
                          : _c("a-icon", { attrs: { type: "right" } }),
                      ],
                      1
                    ),
                    _vm.optionListWidth > 50
                      ? _vm._l(
                          _vm.contentList.filter(
                            (item) =>
                              (item.id != 7 ||
                                (item.id == 7 &&
                                  _vm.checkPermission("search:view"))) &&
                              ((item.id != 3 && item.id != 5 && item.id != 7) ||
                                _vm.showOptions.leftOptions.length == 1 ||
                                (_vm.showOptions.leftOptions.length > 1 &&
                                  !_vm.showOptions.leftOptions[1].checked))
                          ),
                          function (item) {
                            return _c(
                              "div",
                              {
                                key: item.id,
                                class: item.visible
                                  ? "select-option option"
                                  : "option",
                                style:
                                  item.id > 100
                                    ? _vm.checkPermission("allchange:view")
                                      ? `position: absolute;bottom:0;font-size:${
                                          (_vm.optionListWidth / 8).toFixed(0) -
                                            0 >
                                          14
                                            ? 14
                                            : (_vm.optionListWidth / 8).toFixed(
                                                0
                                              )
                                        }px;padding:0 ${
                                          (_vm.optionListWidth / 12).toFixed(
                                            0
                                          ) -
                                            0 >
                                          10
                                            ? 10
                                            : (
                                                _vm.optionListWidth / 12
                                              ).toFixed(0)
                                        }px 0 ${
                                          (_vm.optionListWidth / 12).toFixed(
                                            0
                                          ) -
                                            0 >
                                          10
                                            ? 10
                                            : (
                                                _vm.optionListWidth / 12
                                              ).toFixed(0)
                                        }px`
                                      : `display:none;font-size:${
                                          (_vm.optionListWidth / 8).toFixed(0) -
                                            0 >
                                          14
                                            ? 14
                                            : (_vm.optionListWidth / 8).toFixed(
                                                0
                                              )
                                        }px;padding:0 ${
                                          (_vm.optionListWidth / 12).toFixed(
                                            0
                                          ) -
                                            0 >
                                          10
                                            ? 10
                                            : (
                                                _vm.optionListWidth / 12
                                              ).toFixed(0)
                                        }px 0 ${
                                          (_vm.optionListWidth / 12).toFixed(
                                            0
                                          ) -
                                            0 >
                                          10
                                            ? 10
                                            : (
                                                _vm.optionListWidth / 12
                                              ).toFixed(0)
                                        }px`
                                    : `font-size:${
                                        (_vm.optionListWidth / 8).toFixed(0) -
                                          0 >
                                        14
                                          ? 14
                                          : (_vm.optionListWidth / 8).toFixed(0)
                                      }px;padding:0 ${
                                        (_vm.optionListWidth / 12).toFixed(0) -
                                          0 >
                                        10
                                          ? 10
                                          : (_vm.optionListWidth / 12).toFixed(
                                              0
                                            )
                                      }px 0 ${
                                        (_vm.optionListWidth / 12).toFixed(0) -
                                          0 >
                                        10
                                          ? 10
                                          : (_vm.optionListWidth / 12).toFixed(
                                              0
                                            )
                                      }px`,
                                on: {
                                  click: (e) => {
                                    _vm.optionClick(e, item)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.optionTitle) +
                                    "\n            "
                                ),
                              ]
                            )
                          }
                        )
                      : _vm._l(_vm.contentList, function (item) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              class: item.visible
                                ? "select-option option"
                                : "option",
                              style:
                                item.id > 100
                                  ? "position: absolute;bottom:0;padding: 0 5px 0 5px"
                                  : "padding: 0 5px 0 5px",
                              on: {
                                click: (e) => {
                                  _vm.optionClick(e, item)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.optionTitleMini) +
                                  "\n            "
                              ),
                            ]
                          )
                        }),
                  ],
                  2
                ),
              ]),
            ]
          ),
          _c("playvideo-modal", {
            ref: "videoModal",
            attrs: { innerWidth: _vm.imgInnerWidth, isSys: true },
            on: {
              topCenterMouseover: _vm.topCenterMouseover,
              topCenterMouseout: _vm.topCenterMouseout,
              cancel: _vm.videCancel,
            },
          }),
        ],
        1
      ),
      _c("phonetic-modal", {
        ref: "phoneticModal",
        attrs: {
          showImgLeftListPostions: _vm.showImgLeftListPostions,
          pageListData: _vm.pageListData,
          phoneticStyle:
            this.$refs.centerButtonList &&
            this.$refs.centerButtonList.phoneticStyle,
          textBatchStyle:
            this.$refs.centerButtonList &&
            this.$refs.centerButtonList.textBatchStyle,
          textLayout:
            _vm.selectTextAreaData.length &&
            _vm.selectTextAreaData[0].textLayout,
        },
        on: {
          savePhonetic: _vm.savePhonetic,
          changeIsOpenPhoneticModal: _vm.changeIsOpenPhoneticModal,
          topCenterMouseover: _vm.topCenterMouseover,
        },
      }),
      _c("emj-modal", {
        ref: "emjModal",
        attrs: { emjDictList: _vm.emjDictList },
        on: { topCenterMouseover: _vm.topCenterMouseover, addEmj: _vm.addEmj },
      }),
      _c("term-modal", {
        ref: "termModal",
        attrs: {
          termsAll: _vm.termsAll,
          departName: _vm.departName,
          isDepart: _vm.isDepart,
          terms: _vm.terms,
          taskDetail: _vm.taskDetail,
          selectTextAreaData: _vm.selectTextAreaData,
        },
        on: {
          changeIsAddTerm: _vm.changeIsAddTerm,
          getTerms: _vm.getTerms,
          openEditTermModal: _vm.openEditTermModal,
          topCenterMouseover: _vm.topCenterMouseover,
        },
      }),
      _c("term-edit-modal", {
        ref: "termEditModal",
        attrs: {
          isAddTerm: _vm.isAddTerm,
          departName: _vm.departName,
          termsAll: _vm.termsAll,
          isDepart: _vm.isDepart,
          terms: _vm.terms,
          taskDetail: _vm.taskDetail,
          selectTextAreaData: _vm.selectTextAreaData,
        },
        on: {
          getTerms: _vm.getTerms,
          openEditTermModal: _vm.openEditTermModal,
          changeIsAddTerm: _vm.changeIsAddTerm,
          topCenterMouseover: _vm.topCenterMouseover,
        },
      }),
      _c("term-back-edit-modal", {
        ref: "termBackEditModal",
        attrs: {
          taskDetail: _vm.taskDetail,
          color: _vm.color,
          isRejectStatus: _vm.isRejectStatus,
          selectTextAreaData: _vm.selectTextAreaData,
        },
        on: {
          getTermsBack: _vm.getTermsBack,
          backToText: _vm.backToText,
          topCenterMouseover: _vm.topCenterMouseover,
        },
      }),
      _c("term-back-modal", {
        ref: "termBackModal",
        attrs: {
          color: _vm.color,
          isRejectStatus: _vm.isRejectStatus,
          selectTextAreaData: _vm.selectTextAreaData,
          taskDetail: _vm.taskDetail,
        },
        on: {
          backToText: _vm.backToText,
          topCenterMouseover: _vm.topCenterMouseover,
        },
      }),
      _c("term-note-modal", {
        ref: "termNoteModal",
        attrs: { parent: _vm.taskDetail },
        on: {
          topCenterMouseover: _vm.topCenterMouseover,
          loadData: _vm.getTermsNote,
        },
      }),
      _c("sentence-modal", {
        ref: "sentenceModal",
        attrs: { taskDetail: _vm.taskDetail },
        on: { topCenterMouseover: _vm.topCenterMouseover },
      }),
      _c(
        "a-modal",
        {
          attrs: { closable: false, footer: false, title: false },
          on: {
            ok: function ($event) {
              return _vm.handleOk(1)
            },
            cancel: _vm.handleCancel,
          },
          model: {
            value: _vm.saveVisible,
            callback: function ($$v) {
              _vm.saveVisible = $$v
            },
            expression: "saveVisible",
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "font-size": "16px", "font-weight": "700" } },
            [
              _c("a-icon", {
                staticStyle: {
                  color: "orange",
                  "font-size": "20px",
                  "margin-right": "15px",
                },
                attrs: { type: "question-circle" },
              }),
              _vm._v("数据尚未保存，确认离开？\n    "),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
                "padding-top": "20px",
              },
            },
            [
              _c(
                "a-button",
                {
                  key: "back",
                  staticStyle: { border: "none" },
                  on: {
                    click: function ($event) {
                      return _vm.handleOk(0)
                    },
                  },
                },
                [_vm._v(" 不保存并离开 ")]
              ),
              _c(
                "div",
                [
                  _c(
                    "a-button",
                    {
                      key: "back",
                      staticStyle: { border: "none" },
                      on: { click: _vm.handleCancel },
                    },
                    [_vm._v(" 取消 ")]
                  ),
                  _c(
                    "a-button",
                    {
                      key: "submit",
                      staticStyle: { "margin-left": "15px", border: "none" },
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleOk(1)
                        },
                      },
                    },
                    [_vm._v("\n          保存并离开\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm.blackListMode == 2
        ? _c("black-list-modal", {
            ref: "blackListModal",
            attrs: {
              taskDetail: _vm.taskDetail,
              authList: _vm.authList,
              departName: _vm.departName,
              terms: _vm.terms,
              termsAll: _vm.termsAll,
              termBacks: _vm.termBacks,
              allTermBacks: _vm.allTermBacks,
              phoneticBlackList: _vm.phoneticBlackList,
              termNotes: _vm.termNotes,
              filtrationList: _vm.filtrationList,
              userInfo: _vm.userInfo,
              errorDictList: _vm.errorDictList,
              glossaryTags: _vm.glossaryTags,
              pageListData: _vm.pageListData,
              showImgLeftListPostions: _vm.showImgLeftListPostions,
              changeAll: _vm.changeAll,
              selectTextAreaData: _vm.selectTextAreaData,
              blacklist: _vm.blacklist,
            },
            on: {
              changeCardInput: _vm.changeCardInput,
              copyData: _vm.copyData,
              changeError: _vm.changeError,
              openEditTermModal: _vm.openEditTermModal,
              changeIscheckText: _vm.changeIscheckText,
              changeIsAddTerm: _vm.changeIsAddTerm,
              blackListChangeContentDarg: _vm.blackListChangeContentDarg,
              getRevisionList: _vm.getRevisionList,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }