<template>
  <div class="translate" :style="backCss" @mousemove="mousemove" @mouseup="mouseup" @mousedown="handleMouseDown">
    <div v-if="allLoadding || isSaved" class="all-loading" :style="isSaved ? 'background:rgba(0,0,0,0)' : ''">
      <a-spin :spinning="allLoadding" size="large" :tip="loaddingText">
        <a-icon slot="indicator" type="loading" style="font-size: 40px" spin />
      </a-spin>
    </div>
    <content-list
      ref="contentList"
      @defualtIsDragText="defualtIsDragText"
      @pushLoacal="pushLoacal"
      @changeDefualtTextTypeNo="changeDefualtTextTypeNo"
      @closeLocal="closeLocal"
      :contentList="contentList"
      :showOptions="showOptions"
      :departName="departName"
      @changeYahoo="changeYahoo"
      :authList="authList"
      :gloabalBtnLoading="gloabalBtnLoading"
      @changeGloabalBtnLoading="changeGloabalBtnLoading"
      @showImgHandleImgClick="showImgHandleImgClick"
      @handleImgClick="handleImgClick"
      @clearSelect="clearSelect"
      :bottomHeight="bottomHeight"
      :isRejectStatus="isRejectStatus"
      :textList="textList"
      @searchTextList="searchTextList"
      @copyData="copyData"
      @getShowimgRevisionList="getShowimgRevisionList"
      @deleRevision="deleRevision"
      :changeAll="changeAll"
      :fontFamilyList="fontFamilyList"
      :color="color"
      :taskDetail="taskDetail"
      :userInfo="userInfo"
      :revisionList="revisionList"
      :logList="logList"
      :showAnnotationList="showAnnotationList"
      :leftAnnotationList="leftAnnotationList"
      :rightAnnotationList="rightAnnotationList"
      :defualtTextTypeNo="defualtTextTypeNo"
      :selectTextAreaData="selectTextAreaData"
      :errorDictList="errorDictList"
      :pageListData="pageListData"
      :showImgLeftListPostions="showImgLeftListPostions"
      :textBatchStyle="this.$refs.centerButtonList && this.$refs.centerButtonList.textBatchStyle"
      :oldTextBatchStyle="this.$refs.centerButtonList && this.$refs.centerButtonList.oldTextBatchStyle"
      :textTypes="textTypes"
      @getLocalList="getLocalList"
      @savePageList="savePageList"
      @saveGloabal="saveGloabal"
      @getPageListContent="getPageListContent"
      @getRevisionList="getRevisionList"
      @changeSelectList="changeSelectList"
      @closeContentDarg="closeContentDarg"
      @changeContentDarg="changeContentDarg"
      @textTypeBatchChange="textTypeBatchChange"
      @textTypeBatchChangeNo="textTypeBatchChangeNo"
      @saveLocal="saveLocal"
      @changeAnnotationListSelect="changeAnnotationListSelect"
      @topCenterMouseover="topCenterMouseover"
      @topCenterMouseout="topCenterMouseout"
    />
    <div class="translate-top">
      <div class="translate-top-center">
        <div class="translate-top-center-content" @mouseover="topCenterMouseover" @mouseout="topCenterMouseout">
          <center-button-list
            ref="centerButtonList"
            :departName="departName"
            @changeTaskConfirmStatus="changeTaskConfirmStatus"
            :globalSetting="globalSetting"
            :taskConfirmStatus="taskConfirmStatus"
            @changeEditType="changeEditType"
            :authList="authList"
            :changeAll="changeAll"
            @blackListModeChange="blackListModeChange"
            @topCenterMouseover="topCenterMouseover"
            @ocr="ocr"
            @clearCopyContent="clearCopyContent"
            :defualtTextType="defualtTextType"
            :isRejectStatus="isRejectStatus"
            :color="color"
            :fontFamilyList="fontFamilyList"
            :isAddText="isAddText"
            :taskDetail="taskDetail"
            :userInfo="userInfo"
            :copyContent="this.$refs.showImgContent && this.$refs.showImgContent.copyContent"
            :isAddAnnotation="isAddAnnotation"
            :phoneticTexts="phoneticTexts"
            :selectTextAreaData="selectTextAreaData"
            :isAutoSave="isAutoSave"
            @changeShowImgListWidth="changeShowImgListWidth"
            @downLoadImg="downLoadImg"
            @changeAutoSave="changeAutoSave"
            @openPhonetic="openPhonetic"
            @openEmj="openEmj"
            @addSentence="addSentence"
            @openSearch="openSearch"
            @openNote="openNote"
            @addTerm="addTerm"
            @addTermBack="addTermBack"
            @textBatchChange="textBatchChange"
            @backData="backData"
            @nextData="nextData"
            @copyData="copyData"
            @savePagelist="savePageList"
            @changeIsAddText="changeIsAddText"
            @changeIsAddTwo="changeIsAddTwo"
            @changeIsAddAnnotation="changeIsAddAnnotation"
          />
        </div>
      </div>
    </div>
    <div class="translate-bottom">
      <div class="translate-bottom-left" ref="pageList">
        <div>
          <div class="translate-bottom-left-show-icon" @click="changePageListWidth">
            <a-icon v-if="pageListShow" type="left" />
            <a-icon v-else type="right" />
          </div>
          <page-list
            v-if="checkPermission('pageList:view')"
            ref="pageListContent"
            :pageListData="pageListData"
            :jpgListData="jpgListData"
            :showOptions="showOptions"
            @getPageListPostions="getPageListPostions"
            @changeShowScroll="changeShowScroll"
            @changePage="changePage"
            :chapterList="chapterList"
            :pageListShow="pageListShow"
            @changeSelectIdx="changeSelectIdx"
            :selectIdx="selectIdx"
            :pageListPostions="pageListPostions"
            :taskDetail="taskDetail"
          />
          <div v-else class="no-permission">
            <span>无权限</span>
          </div>
        </div>
      </div>
      <div class="translate-bottom-center" ref="showImg">
        <div
          @mousedown="showImgMousedown"
          @mouseleave="showImagMouseleave"
          @mousemove="showImgMouseout"
          @keydown="showImgKeydown"
          tabIndex="-1"
          class="drag-box"
        >
          <div
            class="mask"
            v-show="is_show_mask"
            :style="'width:' + mask_width + 'left:' + mask_left + 'height:' + mask_height + 'top:' + mask_top"
          ></div>
          <show-img
            ref="showImgContent"
            :departName="departName"
            :isSaved="isSaved"
            :phoneticBlackList="phoneticBlackList"
            @pushLoacal="pushLoacal"
            @toStageChange="toStageChange"
            @closeLocal="closeLocal"
            :isOpenPhoneticModal="isOpenPhoneticModal"
            :ocrLoading="ocrLoading"
            :ischangeScroll="ischangeScroll"
            @textAreaDbclick="textAreaDbclick"
            :textTypes="textTypes"
            @resizeChange="resizeChange"
            :allTermBacks="allTermBacks"
            :isDownLoad="isDownLoad"
            :defualtTextTypeNo="defualtTextTypeNo"
            :authList="authList"
            @changeTranslate="changeTranslate"
            @showImgClick="showImgClick"
            @ocr="ocr"
            @changeTextStyle="changeTextStyle"
            @changeTermsPhonetics="changeTermsPhonetics"
            @openTermBackEditModal="openTermBackEditModal"
            @clearAnchorNode="clearAnchorNode"
            @changeCardInput="changeCardInput"
            :blackListMode="blackListMode"
            :isRejectStatus="isRejectStatus"
            :jpgListData="jpgListData"
            @clearSelect="clearSelect"
            @getTermsBack="getTermsBack"
            @copyData="copyData"
            @topCenterMouseout="topCenterMouseout"
            @changeAnchorNodeBefore="changeAnchorNodeBefore"
            @topCenterMouseover="topCenterMouseover"
            @changeLeftImgShow="changeLeftImgShow"
            @changeRightImgShow="changeRightImgShow"
            @changeAnchorNode="changeAnchorNode"
            @changeIsAddText="changeIsAddText"
            @savePageList="savePageList"
            @changeIsDragText="changeIsDragText"
            @changeSelectTexts="changeSelectTexts"
            @getRevisionList="getRevisionList"
            @delePageList="delePageList"
            @clearPhoneticTexts="clearPhoneticTexts"
            @batchDeleteTextArea="batchDeleteTextArea"
            @changeIsAddTerm="changeIsAddTerm"
            :color="color"
            @openEditTermModal="openEditTermModal"
            :isWindows="isWindows"
            :changeAll="changeAll"
            @getLocalList="getLocalList"
            :userInfo="userInfo"
            :taskStatus="taskStatus"
            :changeScrollHeihgt="changeScrollHeihgt"
            :isCenterClick="isCenterClick"
            :showImgListWidth="showImgListWidth"
            :isAddAnnotation="isAddAnnotation"
            @changeLeftOption="changeLeftOption"
            @changeSelectIdx="changeSelectIdx"
            :showImgLeftListPostions="showImgLeftListPostions"
            @getShowImgListPostions="getShowImgListPostions"
            @changeLeftAnnotationList="changeLeftAnnotationList"
            @changeRightAnnotationList="changeRightAnnotationList"
            @saveLocal="saveLocal"
            @onActivatedAnnotation="onActivatedAnnotation"
            @addTextArea="addTextArea"
            @changeError="changeError"
            :pageListData="pageListData"
            :is_show_mask="is_show_mask"
            :selectIdx="selectIdx"
            :textAreaRegions="textAreaRegions"
            :leftAnnotationList="leftAnnotationList"
            :rightAnnotationList="rightAnnotationList"
            :blacklist="blacklist"
            :filtrationList="filtrationList"
            :terms="terms"
            :termsAll="termsAll"
            :termBacks="termBacks"
            :termNotes="termNotes"
            :glossaryTags="glossaryTags"
            :errorDictList="errorDictList"
            :taskDetail="taskDetail"
            :select_list="select_list"
            :selectTextAreas="selectTextAreas"
            :selectTextAreaData="selectTextAreaData"
            :showOptions="showOptions"
            :isAddText="isAddText"
            :oldTextBatchStyle="this.$refs.centerButtonList && this.$refs.centerButtonList.oldTextBatchStyle"
            :textBatchStyle="this.$refs.centerButtonList && this.$refs.centerButtonList.textBatchStyle"
          />
        </div>
      </div>
      <div
        class="translate-bottom-right"
        @mouseover="isCenterClick = true"
        @mouseout="isCenterClick = false"
        ref="optionList"
      >
        <div>
          <div class="option-list">
            <div class="translate-bottom-right-show-icon" @click="changeOptionListWidth">
              <a-icon v-if="optionListWidth <= 50" type="left" />
              <a-icon v-else type="right" />
            </div>
            <template v-if="optionListWidth > 50">
              <div
                v-for="item in contentList.filter(
                  item =>
                    (item.id != 7 || (item.id == 7 && checkPermission('search:view'))) &&
                    ((item.id != 3 && item.id != 5 && item.id != 7) ||
                      showOptions.leftOptions.length == 1 ||
                      (showOptions.leftOptions.length > 1 && !showOptions.leftOptions[1].checked))
                )"
                @click="
                  e => {
                    optionClick(e, item)
                  }
                "
                :style="
                  item.id > 100
                    ? checkPermission('allchange:view')
                      ? `position: absolute;bottom:0;font-size:${
                          (optionListWidth / 8).toFixed(0) - 0 > 14 ? 14 : (optionListWidth / 8).toFixed(0)
                        }px;padding:0 ${
                          (optionListWidth / 12).toFixed(0) - 0 > 10 ? 10 : (optionListWidth / 12).toFixed(0)
                        }px 0 ${(optionListWidth / 12).toFixed(0) - 0 > 10 ? 10 : (optionListWidth / 12).toFixed(0)}px`
                      : `display:none;font-size:${
                          (optionListWidth / 8).toFixed(0) - 0 > 14 ? 14 : (optionListWidth / 8).toFixed(0)
                        }px;padding:0 ${
                          (optionListWidth / 12).toFixed(0) - 0 > 10 ? 10 : (optionListWidth / 12).toFixed(0)
                        }px 0 ${(optionListWidth / 12).toFixed(0) - 0 > 10 ? 10 : (optionListWidth / 12).toFixed(0)}px`
                    : `font-size:${
                        (optionListWidth / 8).toFixed(0) - 0 > 14 ? 14 : (optionListWidth / 8).toFixed(0)
                      }px;padding:0 ${
                        (optionListWidth / 12).toFixed(0) - 0 > 10 ? 10 : (optionListWidth / 12).toFixed(0)
                      }px 0 ${(optionListWidth / 12).toFixed(0) - 0 > 10 ? 10 : (optionListWidth / 12).toFixed(0)}px`
                "
                :class="item.visible ? 'select-option option' : 'option'"
                :key="item.id"
              >
                {{ item.optionTitle }}
              </div>
            </template>
            <template v-else>
              <div
                v-for="item in contentList"
                @click="
                  e => {
                    optionClick(e, item)
                  }
                "
                :style="item.id > 100 ? 'position: absolute;bottom:0;padding: 0 5px 0 5px' : 'padding: 0 5px 0 5px'"
                :class="item.visible ? 'select-option option' : 'option'"
                :key="item.id"
              >
                {{ item.optionTitleMini }}
              </div>
            </template>
          </div>
        </div>
      </div>
      <playvideo-modal
        :innerWidth="imgInnerWidth"
        @topCenterMouseover="topCenterMouseover"
        @topCenterMouseout="topCenterMouseout"
        :isSys="true"
        ref="videoModal"
        @cancel="videCancel"
      />
    </div>
    <phonetic-modal
      @savePhonetic="savePhonetic"
      @changeIsOpenPhoneticModal="changeIsOpenPhoneticModal"
      @topCenterMouseover="topCenterMouseover"
      :showImgLeftListPostions="showImgLeftListPostions"
      :pageListData="pageListData"
      :phoneticStyle="this.$refs.centerButtonList && this.$refs.centerButtonList.phoneticStyle"
      :textBatchStyle="this.$refs.centerButtonList && this.$refs.centerButtonList.textBatchStyle"
      :textLayout="selectTextAreaData.length && selectTextAreaData[0].textLayout"
      ref="phoneticModal"
    />
    <emj-modal ref="emjModal" @topCenterMouseover="topCenterMouseover" :emjDictList="emjDictList" @addEmj="addEmj" />
    <term-modal
      ref="termModal"
      @changeIsAddTerm="changeIsAddTerm"
      @getTerms="getTerms"
      @openEditTermModal="openEditTermModal"
      :termsAll="termsAll"
      :departName="departName"
      :isDepart="isDepart"
      :terms="terms"
      @topCenterMouseover="topCenterMouseover"
      :taskDetail="taskDetail"
      :selectTextAreaData="selectTextAreaData"
    />
    <term-edit-modal
      ref="termEditModal"
      @getTerms="getTerms"
      @openEditTermModal="openEditTermModal"
      @changeIsAddTerm="changeIsAddTerm"
      :isAddTerm="isAddTerm"
      :departName="departName"
      :termsAll="termsAll"
      :isDepart="isDepart"
      :terms="terms"
      @topCenterMouseover="topCenterMouseover"
      :taskDetail="taskDetail"
      :selectTextAreaData="selectTextAreaData"
    />
    <term-back-edit-modal
      ref="termBackEditModal"
      @getTermsBack="getTermsBack"
      :taskDetail="taskDetail"
      @backToText="backToText"
      :color="color"
      :isRejectStatus="isRejectStatus"
      @topCenterMouseover="topCenterMouseover"
      :selectTextAreaData="selectTextAreaData"
    />
    <term-back-modal
      ref="termBackModal"
      :color="color"
      :isRejectStatus="isRejectStatus"
      @backToText="backToText"
      :selectTextAreaData="selectTextAreaData"
      :taskDetail="taskDetail"
      @topCenterMouseover="topCenterMouseover"
    />
    <term-note-modal
      @topCenterMouseover="topCenterMouseover"
      ref="termNoteModal"
      @loadData="getTermsNote"
      :parent="taskDetail"
    />
    <sentence-modal @topCenterMouseover="topCenterMouseover" ref="sentenceModal" :taskDetail="taskDetail" />
    <a-modal
      :closable="false"
      v-model="saveVisible"
      :footer="false"
      :title="false"
      @ok="handleOk(1)"
      @cancel="handleCancel"
    >
      <div style="font-size: 16px; font-weight: 700">
        <a-icon
          style="color: orange; font-size: 20px; margin-right: 15px"
          type="question-circle"
        />数据尚未保存，确认离开？
      </div>
      <div style="display: flex; justify-content: space-between; padding-top: 20px">
        <a-button style="border: none" key="back" @click="handleOk(0)"> 不保存并离开 </a-button>
        <div>
          <a-button style="border: none" key="back" @click="handleCancel"> 取消 </a-button>
          <a-button style="margin-left: 15px; border: none" key="submit" type="primary" @click="handleOk(1)">
            保存并离开
          </a-button>
        </div>
      </div>
    </a-modal>
    <black-list-modal
      :taskDetail="taskDetail"
      :authList="authList"
      :departName="departName"
      @changeCardInput="changeCardInput"
      @copyData="copyData"
      :terms="terms"
      :termsAll="termsAll"
      :termBacks="termBacks"
      :allTermBacks="allTermBacks"
      :phoneticBlackList="phoneticBlackList"
      :termNotes="termNotes"
      :filtrationList="filtrationList"
      :userInfo="userInfo"
      :errorDictList="errorDictList"
      :glossaryTags="glossaryTags"
      :pageListData="pageListData"
      :showImgLeftListPostions="showImgLeftListPostions"
      @changeError="changeError"
      @openEditTermModal="openEditTermModal"
      @changeIscheckText="changeIscheckText"
      @changeIsAddTerm="changeIsAddTerm"
      :changeAll="changeAll"
      v-if="blackListMode == 2"
      ref="blackListModal"
      :selectTextAreaData="selectTextAreaData"
      :blacklist="blacklist"
      @blackListChangeContentDarg="blackListChangeContentDarg"
      @getRevisionList="getRevisionList"
    />
  </div>
</template>

<script>
import { getAction, postAction, v1postAction, v1DeleteAction, deleteAction } from '@/api/manage'
import contentList from './components/contentList'
import centerButtonList from './components/centerButtonList'
import playvideoModal from '@/components/playvideo-move-modal'
import { checkPermission } from './utils/hasPermission'
import pageList from './components/pageList'
import font from './components/font'
import showImg from './components/showImg'
import phoneticModal from './components/phoneticModal'
import sentenceModal from './components/sentence-modal'
import blackListModal from './components/blackListModal'
import emjModal from './components/emjModal'
import searchModal from './components/searchModal'
import termModal from './components/termModal'
import termNoteModal from '@/views/production/details/terms-table-modal'
import termEditModal from './components/termEditModal'
import termBackModal from './components/termBackModal'
import termBackEditModal from './components/termBackEditModal'
import html2canvas from 'html2canvas'
import domtoimage from './dom-to-image' //更高dom-to-image逻辑，防止每次导出都加载字体
import { Aiming, Resting } from '@icon-park/vue'
import { simpleDebounce } from '@/utils/util'
import { batchStyleInsertHeader } from '@/utils/util'
import { USER_INFO } from '@/store/mutation-types'
import Vue from 'vue'
import JSZip, { forEach } from 'jszip'
import JsPDF from 'jspdf'
import FileSaver from 'file-saver'
import axios from 'axios'

import romaji from 'romaji'
let _ = require('lodash')
export default {
  components: {
    termBackEditModal,
    termEditModal,
    termNoteModal,
    blackListModal,
    sentenceModal,
    termModal,
    searchModal,
    termBackModal,
    Aiming,
    phoneticModal,
    emjModal,
    contentList,
    centerButtonList,
    showImg,
    font,
    playvideoModal,
    pageList
  },
  data() {
    return {
      inShowImgContent: false,
      isAddTerm: false,
      anchorNode: null,
      pageListShow: true,
      imgInnerWidth: 0,
      showOptions: {
        leftOptions: [
          {
            title: '原稿',
            value: 1,
            checked: true
          } /* ,
          {
            title: '原稿JPG',
            value: 2,
            checked: false
          } */
        ],
        rightOptions: [
          {
            title: '实时',
            value: 1,
            checked: true
          }
        ]
      },
      offsetX: -1,
      contentList: [
        {
          zIndex: 800,
          mouseX: 0,
          mouseY: 100,
          contentDrag: false,
          id: 1,
          title: '文本类型',
          optionTitle: '文本类型',
          optionTitleMini: '类',
          visible: false,
          pushpinColor: '#4a4a4a',
          isPushpin: false,
          allHeight: false
        },
        /*         {
          zIndex: 999,
          mouseX: 0,
          mouseY: 100,
          contentDrag: false,
          id: 2,
          title: '质检大师',
          optionTitle: '质检大师',
          optionTitleMini: '质',
          visible: false,
          isPushpin:false
        }, */
        {
          zIndex: 800,
          mouseX: 0,
          mouseY: 100,
          contentDrag: false,
          id: 3,
          title: '文字列表',
          optionTitle: '文字列表',
          optionTitleMini: '文',
          visible: false,
          pushpinColor: '#4a4a4a',
          isPushpin: false,
          allHeight: false
        },
        {
          zIndex: 800,
          mouseX: 0,
          mouseY: 100,
          contentDrag: false,
          id: 4,
          title: '批注列表',
          optionTitle: '批注列表',
          optionTitleMini: '批',
          visible: false,
          pushpinColor: '#4a4a4a',
          isPushpin: false,
          allHeight: false
        },
        {
          zIndex: 800,
          mouseX: 0,
          mouseY: 100,
          contentDrag: false,
          id: 5,
          title: '修改记录',
          optionTitle: '修改记录',
          optionTitleMini: '修',
          visible: false,
          pushpinColor: '#4a4a4a',
          isPushpin: false,
          allHeight: false
        },
        {
          zIndex: 800,
          mouseX: 0,
          mouseY: 100,
          contentDrag: false,
          id: 7,
          title: '搜索替换',
          optionTitle: '搜索替换',
          optionTitleMini: '搜',
          visible: false,
          pushpinColor: '#4a4a4a',
          isPushpin: false,
          allHeight: false
        },
        {
          zIndex: 800,
          mouseX: 0,
          mouseY: 100,
          contentDrag: false,
          id: 6,
          title: '时间轴',
          optionTitle: '时间轴',
          optionTitleMini: '时',
          visible: false,
          pushpinColor: '#4a4a4a',
          isPushpin: false,
          allHeight: false
        },
        /*         {
          zIndex: 800,
          mouseX: 0,
          mouseY: 100,
          contentDrag: false,
          id: 8,
          title: '人物关系',
          optionTitle: '人物关系',
          optionTitleMini: '人',
          visible: false,
          pushpinColor: '#4a4a4a',
          isPushpin: false,
          allHeight: false
        }, */
        {
          zIndex: 800,
          mouseX: 0,
          mouseY: 100,
          contentDrag: false,
          id: 101,
          title: '全局设定',
          optionTitle: '全局设定',
          optionTitleMini: '全',
          visible: false,
          pushpinColor: '#4a4a4a',
          isPushpin: false,
          allHeight: false
        }
      ],
      dragContent: {
        zIndex: 800,
        mouseX: 0,
        mouseY: 100,
        contentDrag: false
      },
      color: '',
      editType: '',
      ischangeScroll: false,
      iconColor: '#4a4a4a',
      textTypes: [],
      revisionList: [],
      phoneticBlackList: [],
      copyTextArea: null,
      allTermBacks: [],
      mouseX: 0,
      mouseY: 0,
      copyMouseX: 0,
      copyMouseY: 0,
      showImgChangeWidth: false,
      showImgWidth: 0,
      optionListWidth: 0,
      globalSettingId: -1,
      start_x: 0,
      start_y: 0,
      end_x: 0,
      end_y: 0,
      select_list: [],
      selectTextAreas: [],
      is_show_mask: true,
      box_screen_left: 0,
      box_screen_top: 0,
      leftTab: 'PSD',
      pageListData: [],
      taskDetail: {},
      selectIdx: 0,
      pageListPostions: [],
      showImgLeftListPostions: [],
      textAreaRegions: [],
      showImgRightListPostions: [],
      isDragText: false,
      ocrLoading: false,
      isOpenPhoneticModal: false,
      isOpenVideo: false,
      changeScrollHeihgt: 0,
      allShowImgWidth: 0,
      topHeight: 0,
      allLoadding: false,
      loaddingText: '加载中...',
      changeProcess: [],
      fontFamilyList: [],
      saveIndex: 0,
      showImgListWidth: '100%',
      selectTextAreaData: [],
      isCenterClick: false,
      isAddText: false,
      isSaved: false,
      isAddAnnotation: false,
      leftAnnotationList: [],
      rightAnnotationList: [],
      showAnnotationList: [],
      defualtTextTypeNo: '0',
      userInfo: {},
      defualtTextType: {
        fontFamilys: []
      },
      phoneticTexts: [],
      taskStatus: '等待',
      logList: [],
      errorDictList: [],
      emjDictList: [],
      glossaryTags: [],
      termBacks: [],
      termNotes: [],
      terms: [],
      blacklistAll: [],
      blacklist: [],
      filtrationList: [],
      searchList: [],
      showSeachIndex: 0,
      isAutoSave: false,
      isDeleteLoading: false,
      cardInput: false,
      saveVisible: false,
      isWindows: true,
      termsAll: [],
      changeAll: true,
      globalSetting: false,
      textList: [],
      bottomHeight: 0,
      departName: '',
      isDownLoad: false,
      isAnchorNodeBefore: false,
      gloabalBtnLoading: false,
      selectTextAreaDataCopy: [],
      innerWidth: 0,
      blackListMode: 1,
      chapterList: [],
      jpgListData: [],
      isDepart: false,
      isRejectStatus: false,
      toPageId: 0,
      toTextAreaId: 0,
      isSelected: false,
      authList: [],
      isEditble: false,
      changeTranslateType: 0,
      taskConfirmStatus: true,
      translate_download: '',
      isShear: false
    }
  },
  computed: {
    backCss() {
      this.color = this.$store.state.app.color
      return {
        '--theme-color': this.color
      }
    },
    mask_width() {
      return `${Math.abs(this.end_x - this.start_x)}px;`
    },
    mask_top() {
      return `${Math.min(this.start_y, this.end_y) - this.box_screen_top}px;`
    },
    mask_left() {
      return `${Math.min(this.start_x, this.end_x) - this.box_screen_left}px;`
    },
    mask_height() {
      return `${Math.abs(this.end_y - this.start_y)}px;`
    }
  },
  watch: {
    select_list() {
      this.selectTextAreas = []
      /* let oldColor = this.$refs.centerButtonList.textBatchStyle.fontColor */
      if (!this.select_list.length) {
        this.$refs.centerButtonList.textBatchStyle = JSON.parse(
          JSON.stringify(this.$refs.centerButtonList.oldTextBatchStyle)
        )
      }
      /* this.$refs.centerButtonList.textBatchStyle.fontColor = oldColor */
      if (this.select_list.length <= 1) return
      let doms = [...document.querySelectorAll('.ocr-show-item-right'), ...document.querySelectorAll('.ocr-show-item')]
      doms.forEach(node => {
        if (!node.getClientRects()[0]) return
        const rects = node.getClientRects()[0]
        if (this.select_list.some(item => item == node.id)) {
          let position = node.parentNode.style.transform
            .split('(')[1]
            .split(')')[0]
            .split(',')
          let rectsObj = JSON.parse(JSON.stringify(rects))
          rectsObj.x = position[0].split('px')[0]
          rectsObj.y = position[1].split('px')[0]
          this.selectTextAreas.push({ ...rectsObj, pageId: node.id.split('-')[0] })
        }
      })
    },
    selectTextAreaData(n, o) {
      this.selectTextAreaDataChange()
      if (this.contentList.every(item => !item.visible)) {
        this.topCenterMouseout()
      }
      if (
        this.$refs.blackListModal &&
        this.$refs.blackListModal.item &&
        this.selectTextAreaData[0] &&
        this.$refs.blackListModal.item.id == this.selectTextAreaData[0].id
      )
        return
      if (this.selectTextAreaData.length) {
        if (this.selectTextAreaData[0].translateTextAreaRegion.editble) {
          this.isAutoSave = true
          this.isEditble = true
        }
        this.getAllSelection()
        this.$nextTick(() => {
          let dom = document.getElementById(
            this.selectTextAreaData[0].pageId + '-' + this.selectTextAreaData[0].id + '-' + 'left'
          )
          if (dom) {
            let position = dom.getClientRects()[0]
            if (this.$refs.blackListModal) {
              if (!this.$refs.blackListModal.position.mouseX) {
                this.$refs.blackListModal.position.mouseX = position.x
              }
              if (!this.$refs.blackListModal.position.mouseY) {
                this.$refs.blackListModal.position.mouseY = position.y + position.height - 30
              }
              this.$refs.blackListModal.item = JSON.parse(JSON.stringify(this.selectTextAreaData[0]))
              this.$refs.blackListModal.yahooOrKdxList = this.$refs.showImgContent.yahooOrKdxList
              /* this.$refs.blackListModal.termsPhonetics = this.$refs.showImgContent.termsPhonetics */
              this.$refs.blackListModal.getRevisionList(this.selectTextAreaData[0])
            }
          }
        })
      }
    },
    changeProcess() {
      if (this.changeProcess.length > 5) {
        let arr = this.changeProcess.filter((c, cIdx) => this.changeProcess.length - cIdx <= 5)
        this.saveIndex = this.saveIndex - (this.changeProcess.length - arr.length)
        this.changeProcess = arr
      }
    }
  },
  created() {
    document.title = '加载中'
    this.translate_download = localStorage.getItem('translate_download')
    this.getPermsission()
    if (this.$route.params.pageId) {
      this.toPageId = this.$route.params.pageId
      this.toTextAreaId = this.$route.params.textAreaId
    }
    this.copyTextArea = null
    this.getAllData()
    this.isWindows = navigator.userAgent.toLowerCase().indexOf('windows') > -1
    this.keyDown()
    if (!this.innerWidth) {
      this.innerWidth = window.innerWidth
    }
    /*     window.onblur = () => {
      this.$nextTick(() => {
        if (this.$refs.showImgContent) {
          this.$refs.showImgContent.copyContent = localStorage.getItem('translate_copyContent')
        }
      })
    } */
    // 监听窗口获得焦点事件
    window.onfocus = () => {
      this.$nextTick(() => {
        if (this.$refs.showImgContent) {
          /* console.log('获取焦点') */
          this.$refs.showImgContent.copyContent = localStorage.getItem('translate_copyContent')
        }
      })
    }
    if (localStorage.getItem('translate_copy_text_area')) {
      this.copyTextArea = JSON.parse(localStorage.getItem('translate_copy_text_area')).data
      this.isShear = JSON.parse(localStorage.getItem('translate_copy_text_area')).isShear
    }
  },
  mounted() {
    this.userInfo = Vue.ls.get(USER_INFO)
    this.showImgWidth = this.$refs.showImg.clientWidth
    this.allShowImgWidth = this.$refs.showImg.clientWidth
    this.optionListWidth = this.$refs.optionList.clientWidth
    this.imgInnerWidth = window.innerWidth
    const dom_box = document.querySelector('.drag-box')
    this.$nextTick(() => {
      if (this.$refs.showImgContent) {
        this.$refs.showImgContent.copyContent = localStorage.getItem('translate_copyContent')
      }
      const topDom = document.querySelector('.translate-top')
      this.topHeight = topDom.getClientRects()[0].height - 0 + 12
      this.box_screen_left = dom_box.getBoundingClientRect().left - this.$refs.pageList.getClientRects()[0].width - 24
      this.box_screen_top = dom_box.getBoundingClientRect().top - this.topHeight
    })
    window.addEventListener('resize', () => {
      this.imgInnerWidth = window.innerWidth
      if (this.$refs.showImgContent) {
        this.$refs.showImgContent.rightImgShow = false
        this.$refs.showImgContent.leftImgShow = false
      }
      this.contentList.forEach(item => {
        item.mouseX = 0
        item.mouseY = 100
      })
      let timeOut1 = setTimeout(() => {
        if (this.$refs.showImg) this.$refs.showImg.style.width = '70%'
        if (this.$refs.optionList) this.$refs.optionList.style.width = '5%'
        clearTimeout(timeOut1)
        /* document.getElementsByClassName('show-img-bottom')[0].scrollTop = 0 */
        /* this.changeScrollHeihgt = 0 */
        /*  this.selectIdx = 0 */
        /* document.getElementsByClassName('show-img-bottom')[0].scrollTop=this.$refs.showImgContent.scrollTop */
        /*         let scrollTop =
          (document.getElementsByClassName('show-img-bottom')[0].scrollTop * window.innerWidth) / this.innerWidth
        document.getElementsByClassName('show-img-bottom')[0].scrollTop = scrollTop.toFixed(0) */
      }, 100)
      let timeOut = setTimeout(() => {
        if (this.$refs.showImg) {
          this.showImgWidth = this.$refs.showImg.clientWidth
          this.optionListWidth = this.$refs.optionList.clientWidth
          this.changeScrollHeihgt = 0
          const dom_box = document.querySelector('.drag-box')
          const topDom = document.querySelector('.translate-top')
          this.topHeight = topDom.getClientRects()[0].height - 0 + 12
          this.box_screen_left =
            dom_box.getBoundingClientRect().left - this.$refs.pageList.getClientRects()[0].width - 24
          this.box_screen_top = dom_box.getBoundingClientRect().top - this.topHeight
          this.getAllPositions()
          this.$refs.showImgContent.rightImgShow = false
          this.$refs.showImgContent.leftImgShow = false
          if (this.$refs.showImgContent) {
            this.$refs.showImgContent.getDragTextBox()
          }
        }

        clearTimeout(timeOut)
      }, 300)
    })
    let that = this
    window.addEventListener('beforeunload', event => {
      if (that.isAutoSave) {
        that.saveVisible = true
        event.preventDefault()
        event.returnValue = ''
        return ''
      }
    })
    this.autoSaveTsData()
  },
  methods: {
    showImgHandleImgClick() {
      this.$nextTick(() => {
        Array.from(document.getElementsByClassName('local-content-item-bottom'))?.forEach(d => {
          Array.from(d.getElementsByTagName('img')).forEach(img => {
            img.addEventListener('click', e => {
              this.handleImgClick(e)
            })
          })
        })
        Array.from(document.getElementsByClassName('revision-content'))?.forEach(d => {
          Array.from(d.getElementsByTagName('img')).forEach(img => {
            img.addEventListener('click', e => {
              this.handleImgClick(e)
            })
          })
        })
      })
    },
    handleImgClick(e) {
      e.stopPropagation()
      if (this.$refs.videoModal) {
        this.$refs.videoModal.open(e.target.src)
        this.isOpenVideo = true
      }
    },
    changeTaskConfirmStatus(type) {
      this.taskConfirmStatus = type
    },
    detectLanguage(text) {
      var chineseRegex = /[\u4e00-\u9fa5]/ // Unicode范围内的汉字
      var japaneseRegex = /[\u0800-\u4e00]/ // 包含全角、半角及片假名等特定符号的日文
      var koreanRegex = /[가-힣]/ // Unicode范围内的韩文
      if (chineseRegex.test(text)) {
        return '中文'
      } else if (japaneseRegex.test(text)) {
        return '日文'
      } else if (koreanRegex.test(text)) {
        return '韩文'
      } else {
        return '其他语言或无法确定'
      }
    },
    async getPermsission() {
      const res = await getAction(
        '/sys/permission/getUserPermissionByToken/translation?taskId=' + this.$route.params.taskId
      )
      if (res.code == 200) {
        this.authList = res.data.auth
      } else {
        this.$message.error(res.msg || res.errorMsg)
      }
    },
    async changeTranslate(textArea) {
      if (this.changeTranslateType) {
        this.changeTranslateType = 0
      } else {
        this.changeTranslateType = 1
      }
      let addItem = textArea
      if (!addItem.originalText) {
        this.$message.error('无原文')
        return
      }
      if (addItem.showText) {
        let that = this
        const restTr = await postAction('/kt/translate/ai/translate', {
          target: that.taskDetail.targetLang,
          source: that.taskDetail.sourceLang,
          content: addItem.originalText,
          operator: that.changeTranslateType,
          productionId: that.taskDetail.productionId
        })
        if (restTr.code == 200) {
          if (restTr.data) {
            let textType = that.textTypes.find(type => type.sequenceNo == 1)
            addItem.characterSentences = []
            restTr.data.split('').forEach((t, tIdx) => {
              addItem.characterSentences.push({
                ...that.$refs.showImgContent.oldTextBatchStyle,
                textAlign: that.$refs.showImgContent.textBatchStyle.textAlign,
                fontFamily: that.$refs.showImgContent.textBatchStyle.fontFamily,
                isBold: textType.isBold ? 1 : 0,
                characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime() + tIdx,
                characterText: t == '\n' ? '<br>' : t == '\r' ? ' ' : t
              })
            })
            addItem.showTexts = []
            that.$nextTick(() => {
              let pArr = []
              let brIds = []
              addItem.brIndexs = []
              addItem.pIndexs = []
              addItem.characterSentences?.forEach((t, pushTtextIndex) => {
                if (
                  addItem.characterSentences[pushTtextIndex + 1] &&
                  addItem.characterSentences[pushTtextIndex + 1].phonetic &&
                  addItem.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
                ) {
                  addItem.pIndexs.push(pushTtextIndex)
                }
                if (t.characterText != '<br>' && t.characterText != '\n') {
                  pArr.push(t)
                } else {
                  brIds.push(t.characterSentenceId)
                  addItem.brIndexs.push(pushTtextIndex)
                  if (pArr.length > 1) {
                    /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                  }
                  if (!pArr.length) {
                    pArr.push({
                      ...that.$refs.showImgContent.oldTextBatchStyle,
                      textAlign: that.$refs.showImgContent.textBatchStyle.textAlign,
                      fontFamily: that.$refs.showImgContent.textBatchStyle.fontFamily,
                      isBold: textType.isBold ? 1 : 0,
                      characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                      characterText: ' '
                    })
                  }
                  addItem.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                  pArr = []
                }
                if (pushTtextIndex == addItem.characterSentences.length - 1 && pArr.length) {
                  addItem.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                }
              })
              brIds.forEach((brId, brPushIdx) => {
                if (addItem.showTexts[brPushIdx + 1]) {
                  addItem.showTexts[brPushIdx + 1].pId =
                    brId + '-' + Math.round(Math.random() * 10000) + new Date().getTime()
                }
              })
              addItem.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
              addItem.showText = addItem.characterSentences
                .map(c => c.characterText)
                .join('')
                .replaceAll('<br>', '\n')
              that.$refs.showImgContent.getYahooPhonetics(addItem.showText, addItem.pageId, addItem.id, addItem)
              that.getTermsBack()
              that.copyData()
              that.savePageList()
            })
          } else {
            that.ocrLoading = false
          }
        } else {
          that.$message.error(restTr.msg)
        }
      }
    },
    async ocr(textArea) {
      /* let list = this.pageListData.find(page => page.id == textArea.pageId).textAreas */
      let addItem = textArea
      let pageWidth = this.pageListData.find(page => page.id == textArea.pageId).width
      let showPostion = this.showImgLeftListPostions[this.pageListData.findIndex(page => page.id == textArea.pageId)]
      let pageIdx = this.pageListData.findIndex(page => page.id == textArea.pageId)
      const dom = document.getElementsByClassName('show-img-bottom-left')[0].childNodes[0].childNodes[0].childNodes[
        pageIdx
      ]
      let num = showPostion.width / pageWidth - 0
      let width = (addItem.textAreaRegion.width * num).toFixed(0) - 0 || 1
      let height = (addItem.textAreaRegion.height * num).toFixed(0) - 0 || 1
      let top = (addItem.textAreaRegion.y * num).toFixed(0) - 0
      let left = (addItem.textAreaRegion.x * num).toFixed(0) - 0
      this.isAutoSave = true
      this.isSaved = true
      this.ocrLoading = true
      html2canvas(dom, {
        useCORS: true,
        width,
        x: left,
        y: top,
        height: height
      }).then(async canvas => {
        const image = canvas.toDataURL('image/jpeg')
        const rest = await v1postAction('/ocr/recognize/base64', { base64: image })
        if (rest.code == 200) {
          /* this.$message.success('识别成功') */
          if (!rest.data.length) {
            this.isAutoSave = false
            this.isSaved = false
            this.ocrLoading = false
            this.$message.error('暂未识别到文字，请重试')
            return
          }
          addItem.originalText = rest.data.join('\n')
          const restTr = await postAction('/kt/translate/ai/translate', {
            target: this.taskDetail.targetLang,
            source: this.taskDetail.sourceLang,
            content: addItem.originalText,
            operator: 0,
            productionId: this.taskDetail.productionId
          })
          if (restTr.code == 200) {
            if (restTr.data) {
              let textType = this.textTypes.find(type => type.sequenceNo == 1)
              addItem.characterSentences = []
              restTr.data.split('').forEach((t, tIdx) => {
                addItem.characterSentences.push({
                  ...this.$refs.showImgContent.oldTextBatchStyle,
                  textAlign: this.$refs.showImgContent.textBatchStyle.textAlign,
                  fontFamily: this.$refs.showImgContent.textBatchStyle.fontFamily,
                  isBold: textType.isBold ? 1 : 0,
                  characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime() + tIdx,
                  characterText: t == '\n' ? '<br>' : t == '\r' ? ' ' : t
                })
              })
              addItem.showTexts = []
              this.$nextTick(() => {
                let pArr = []
                let brIds = []
                addItem.brIndexs = []
                addItem.pIndexs = []
                addItem.characterSentences?.forEach((t, pushTtextIndex) => {
                  if (
                    addItem.characterSentences[pushTtextIndex + 1] &&
                    addItem.characterSentences[pushTtextIndex + 1].phonetic &&
                    addItem.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
                  ) {
                    addItem.pIndexs.push(pushTtextIndex)
                  }
                  if (t.characterText != '<br>' && t.characterText != '\n') {
                    pArr.push(t)
                  } else {
                    brIds.push(t.characterSentenceId)
                    addItem.brIndexs.push(pushTtextIndex)
                    if (pArr.length > 1) {
                      /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                    }
                    if (!pArr.length) {
                      pArr.push({
                        ...this.$refs.showImgContent.oldTextBatchStyle,
                        textAlign: this.$refs.showImgContent.textBatchStyle.textAlign,
                        fontFamily: this.$refs.showImgContent.textBatchStyle.fontFamily,
                        isBold: textType.isBold ? 1 : 0,
                        characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                        characterText: ' '
                      })
                    }
                    addItem.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                    pArr = []
                  }
                  if (pushTtextIndex == addItem.characterSentences.length - 1 && pArr.length) {
                    addItem.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                  }
                })
                brIds.forEach((brId, brPushIdx) => {
                  if (addItem.showTexts[brPushIdx + 1]) {
                    addItem.showTexts[brPushIdx + 1].pId =
                      brId + '-' + Math.round(Math.random() * 10000) + new Date().getTime()
                  }
                })
                addItem.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
                addItem.showText = addItem.characterSentences
                  .map(c => c.characterText)
                  .join('')
                  .replaceAll('<br>', '\n')
                this.$refs.showImgContent.getYahooPhonetics(addItem.showText, addItem.pageId, addItem.id, addItem)
                this.getTermsBack()
                this.copyData()
                this.savePageList()
              })
            } else {
              this.ocrLoading = false
            }
          } else {
            this.$message.error(restTr.msg)
          }
        } else {
          this.isAutoSave = true
          this.isSaved = false
          this.ocrLoading = false
          this.$message.eroor('OCR失败')
        }
      })
    },
    changeCardInput(value) {
      this.cardInput = value
    },
    getAllSelection() {
      if (this.$refs.contentList && this.selectTextAreaData.length) {
        if (this.$refs.contentList.textDefaultKey.every(key => key != this.selectTextAreaData[0].pageId)) {
          this.$refs.contentList.textDefaultKey.push(this.selectTextAreaData[0].pageId + '')
        }
        if (this.$refs.contentList.reDefaultKey.every(key => key != this.selectTextAreaData[0].pageId)) {
          this.$refs.contentList.reDefaultKey.push(this.selectTextAreaData[0].pageId + '')
        }
        this.$refs.contentList.selectTextId = this.selectTextAreaData[0].id
        this.$refs.contentList.selectRevisitionId = this.selectTextAreaData[0].id
        this.$refs.contentList.getSearchKey(this.selectTextAreaData)
        if (this.isSelected) {
          setTimeout(() => {
            if (
              this.selectTextAreaData &&
              this.selectTextAreaData[0] &&
              document.getElementById('text-list-modal') &&
              document.getElementById(`textList-${this.selectTextAreaData[0].id}`)
            ) {
              document.getElementById('text-list-modal').scrollTop = document.getElementById(
                `textList-${this.selectTextAreaData[0].id}`
              ).offsetTop
            }
            if (
              this.selectTextAreaData &&
              this.selectTextAreaData[0] &&
              document.getElementById('revisiton-modal') &&
              document.getElementById(`${this.selectTextAreaData[0].id}revision`)
            ) {
              document.getElementById('revisiton-modal').scrollTop = document.getElementById(
                `${this.selectTextAreaData[0].id}revision`
              ).offsetTop
            }
            if (
              this.selectTextAreaData &&
              this.selectTextAreaData[0] &&
              document.getElementById('search-modal') &&
              document.getElementById(`search-${this.selectTextAreaData[0].id}`)
            ) {
              document.getElementById('search-modal').scrollTop = document.getElementById(
                `search-${this.selectTextAreaData[0].id}`
              ).offsetTop
            }
          }, 300)
        }
      }
    },
    closeLocal(imgs) {
      if (imgs && this.$refs.videoModal) {
        imgs.forEach(url => {
          if (url == this.$refs.videoModal.url) {
            this.$refs.videoModal.handleCancel()
          }
        })
      }
    },
    async getChapterList() {
      const res = await getAction(
        `/original/skip/task?productionId=${this.taskDetail.productionId}&stageId=${this.taskDetail.stageId}`
      )
      if (res.data) {
        this.chapterList = res.data
        this.chapterList.forEach(chapter => {
          chapter.label = chapter.chapterOrder + '-' + this.taskDetail.stageName
        })
        if (this.chapterList.every(chapter => chapter.chapterId != this.taskDetail.chapterId)) {
          this.changeAll = false
        } else {
          let chapter = this.chapterList.find(chapter => chapter.chapterId == this.taskDetail.chapterId)
          if (
            chapter.ownerId != this.userInfo.id &&
            this.userInfo.roles.every(
              item => item.roleCode != 'boss' && item.roleCode != 'admin' && item.roleCode != 'tongchou'
            )
          ) {
            this.changeAll = false
          }
        }
        if (this.$refs.pageListContent) {
          this.$refs.pageListContent.chapterId = this.taskDetail.chapterId
        }
        const rest = await getAction('/production/guide/confirm/check/' + this.taskDetail.productionId)
        if (rest.code == 500) {
          this.changeAll = false
          this.$message.error(rest.msg)
        }
      } else {
        this.$message.error(res.msg || res.errorMsg || res.errorMsg)
      }
    },
    changeIscheckText(value) {
      if (this.$refs.showImgContent) {
        this.$refs.showImgContent.isCheckText = value
      }
    },
    blackListModeChange(value) {
      this.blackListMode = value
      if (this.selectTextAreaData.length) {
        let position = document
          .getElementById(this.selectTextAreaData[0].pageId + '-' + this.selectTextAreaData[0].id + '-' + 'left')
          .getClientRects()[0]
        this.$nextTick(() => {
          if (this.$refs.blackListModal) {
            this.$refs.blackListModal.position.mouseX = position.x + 20
            this.$refs.blackListModal.position.mouseY = position.y + position.height - 30
            this.$refs.blackListModal.item = JSON.parse(JSON.stringify(this.selectTextAreaData[0]))
            this.$refs.blackListModal.yahooOrKdxList = this.$refs.showImgContent.yahooOrKdxList
            this.$refs.blackListModal.termsPhonetics = this.$refs.showImgContent.termsPhonetics
            this.$refs.blackListModal.getRevisionList(this.selectTextAreaData[0])
          }
        })
      }
    },
    changeTextStyle(textType) {
      this.$refs.centerButtonList.oldTextBatchStyle.isBold = textType.isBold ? 1 : 0
      this.$refs.centerButtonList.textBatchStyle.isBold = textType.isBold ? 1 : 0
      this.$refs.centerButtonList.oldTextBatchStyle.fontColor = textType.fontColor || '#000000'
      this.$refs.centerButtonList.textBatchStyle.fontColor = textType.fontColor || '#000000'
      this.$refs.centerButtonList.oldTextBatchStyle.fontFamily = textType.fontFamilys[0].fontFamily
      this.$refs.centerButtonList.textBatchStyle.fontFamily = textType.fontFamilys[0].fontFamily
    },
    copyData() {
      if (this.selectTextAreaData.length)
        this.selectTextAreaDataCopy = JSON.parse(JSON.stringify(this.selectTextAreaData))
    },
    changeIsAddTerm(isAddTerm) {
      this.isAddTerm = isAddTerm
    },
    openEditTermModal(record) {
      if (this.$refs.termEditModal) {
        this.$refs.termEditModal.open(record)
      }
    },
    openTermBackEditModal(record) {
      if (this.$refs.termBackEditModal) {
        this.$refs.termBackEditModal.open(record)
      }
    },
    saveGloabal: simpleDebounce(async function(data) {
      this.saveGloabalSim(data)
    }, 500),
    async saveGloabalSim(data) {
      let params = {
        ...data.formData,
        chapterId: this.taskDetail.chapterId,
        productionId: this.taskDetail.productionId
      }
      if (!params.strokeColor) {
        params.strokeColor = null
      }
      if (!params.fontColor) {
        params.fontColor = null
      }
      if (this.globalSettingId > -1) {
        params.id = this.globalSettingId
      }
      const res = await postAction('/global/setting/edit', params)
      this.gloabalBtnLoading = false
      this.isDeleteLoading = false
      if (res.code == 200) {
        data.item.visible = false
        this.getAllData()
        this.$message.success('保存成功')
        this.$refs.contentList.formData = {
          strokeColor: '',
          fontColor: ''
        }
      } else {
        this.$message.error(res.msg || res.errorMsg)
      }
    },
    changeGloabalBtnLoading() {
      this.gloabalBtnLoading = true
    },
    getPageListContent() {
      this.isDeleteLoading = true
      this.getPageList()
    },
    getNextRevision(page, item, pageIdx, num, selectRevisitionId) {
      this.revisionList.forEach((p, pIdx) => {
        p.modifyHistoryList?.forEach((i, itemIdx) => {
          if (i.textAreaId == selectRevisitionId) {
            if (itemIdx < this.revisionList[pIdx + num].modifyHistoryList.length - 1) {
              if (!num) {
                item = this.revisionList[pIdx + num].modifyHistoryList[itemIdx + 1]
              } else {
                item = this.revisionList[pIdx + num].modifyHistoryList[itemIdx]
              }
              page = this.revisionList[pIdx + num]
              pageIdx = p.sortOrderId
            } else {
              let nextNum = -1
              this.revisionList.forEach((rpage, rpageIdx) => {
                if (rpageIdx > pIdx && rpage.modifyHistoryList.length && nextNum == -1) {
                  nextNum = rpage.sortOrderId
                  item = rpage.modifyHistoryList[0]
                  page = rpage
                }
              })
              num = nextNum
              pageIdx = num
              /* this.getNextRevision(page, item, pageIdx, num, selectRevisitionId) */
            }
          }
        })
      })
      if (item) {
        return { page, item, pageIdx }
      } else {
        return null
      }
    },
    showImagMouseleave() {
      this.inShowImgContent = false
    },
    showImgMouseout() {
      this.inShowImgContent = true
    },
    async showImgKeydown(e) {
      if (!this.inShowImgContent) return
      if (this.isWindows) {
        if (e.ctrlKey && (e.key == 'c' || e.key == 'C')) {
          if (
            this.changeAll &&
            this.selectTextAreaData &&
            this.selectTextAreaData.length &&
            this.selectTextAreaData.every(item => !item.translateTextAreaRegion.editble)
          ) {
            this.copyTextArea = JSON.parse(JSON.stringify(this.selectTextAreaData))
            localStorage.setItem(
              'translate_copy_text_area',
              JSON.stringify({ isShear: false, data: this.selectTextAreaData })
            )
            this.isShear = false
          } else {
            this.copyTextArea = null
            this.isShear = false
            localStorage.setItem('translate_copy_text_area', '')
          }
        } else if (e.ctrlKey && (e.key == 'a' || e.key == 'A')) {
          if (
            this.changeAll &&
            this.selectTextAreaData &&
            this.selectTextAreaData.length &&
            this.selectTextAreaData.every(item => item.translateTextAreaRegion.editble)
          ) {
            if (this.$refs.showImgContent) {
              this.$refs.showImgContent.selectionTexts = []
              this.selectTextAreaData[0].characterSentences.forEach((text, tIdx) => {
                this.$refs.showImgContent.selectionTexts.push(text)
              })
              this.changeSelectTexts(this.$refs.showImgContent.selectionTexts)
            }
          }
        } else if (e.ctrlKey && (e.key == 'x' || e.key == 'X')) {
          if (
            this.changeAll &&
            this.selectTextAreaData &&
            this.selectTextAreaData.length &&
            this.selectTextAreaData.every(item => !item.translateTextAreaRegion.editble)
          ) {
            this.copyTextArea = JSON.parse(JSON.stringify(this.selectTextAreaData))
            localStorage.setItem(
              'translate_copy_text_area',
              JSON.stringify({ isShear: true, data: this.selectTextAreaData })
            )
            this.isShear = true
            if (
              this.selectTextAreaData.length &&
              this.selectTextAreaData.some(item => item.translateTextAreaRegion.editble)
            ) {
              return
            }
            if (this.selectTextAreaData.length) {
              let selectTextArea = this.selectTextAreaData
              e.preventDefault()
              if (this.isRejectStatus && this.selectTextAreaData.some(item => !item.isReject)) {
                return this.$message.warning('当前文本框禁止操作！')
              }
              if (!this.changeAll) return
              if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
              if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
              let that = this
              that.selectTextAreaData = selectTextArea
              that.batchDeleteTextArea()
            }
            if (this.leftAnnotationList.some(page => page.annotationList.some(item => item.selected))) {
              if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
              if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
              let id = -1
              let aIdx = -1
              let page = null
              this.leftAnnotationList.forEach(p => {
                p.annotationList.forEach((item, idx) => {
                  if (item.selected) {
                    id = item.id || -1
                    aIdx = idx
                    page = p
                  }
                })
              })
              let that = this
              if (id > -1) {
                that.isDelete = true
                page.annotationList.splice(aIdx, 1)
                const res = await deleteAction('/annotation/delete/' + id)
                if (res.code == 200) {
                  /* that.$message.success('删除成功') */
                  that.$emit('getLocalList')
                } else {
                  that.$message.error(res.msg || res.errorMsg)
                }
              } else {
                that.isDelete = true
                page.annotationList.splice(aIdx, 1)
                /* that.$message.success('删除成功') */
              }
            }
            if (this.rightAnnotationList.some(page => page.annotationList.some(item => item.selected))) {
              if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
              if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
              let id = -1
              let aIdx = -1
              let page = null
              this.rightAnnotationList.forEach(p => {
                p.annotationList.forEach((item, idx) => {
                  if (item.selected) {
                    id = item.id || -1
                    aIdx = idx
                    page = p
                  }
                })
              })
              let that = this
              if (id > -1) {
                that.isDelete = true
                page.annotationList.splice(aIdx, 1)
                const res = await deleteAction('/annotation/delete/' + id)
                if (res.code == 200) {
                  /* that.$message.success('删除成功') */
                  that.$emit('getLocalList')
                } else {
                  that.$message.error(res.msg || res.errorMsg)
                }
              } else {
                that.isDelete = true
                page.annotationList.splice(aIdx, 1)
                /* that.$message.success('删除成功') */
              }
            }
          } else {
            this.copyTextArea = null
            this.isShear = false
            localStorage.setItem('translate_copy_text_area', '')
          }
        } else if (e.ctrlKey && (e.key == 'v' || e.key == 'V')) {
          if (
            (this.selectTextAreaData &&
              this.selectTextAreaData.length &&
              this.selectTextAreaData.every(item => !item.translateTextAreaRegion.editble)) ||
            (this.copyTextArea && this.copyTextArea.length && !this.selectTextAreaData.length)
          ) {
            if (e.target.tagName == 'INPUT') return
            this.batchAddTextArea()
          }
        }
      } else {
        if (e.metaKey && (e.key == 'c' || e.key == 'C')) {
          if (
            this.selectTextAreaData &&
            this.selectTextAreaData.length &&
            this.selectTextAreaData.every(item => !item.translateTextAreaRegion.editble)
          ) {
            this.copyTextArea = JSON.parse(JSON.stringify(this.selectTextAreaData))
            localStorage.setItem(
              'translate_copy_text_area',
              JSON.stringify({ isShear: false, data: this.selectTextAreaData })
            )
            this.isShear = false
          } else {
            this.isShear = false
            this.copyTextArea = null
            localStorage.setItem('translate_copy_text_area', '')
          }
        } else if (e.metaKey && (e.key == 'a' || e.key == 'A')) {
          if (
            this.changeAll &&
            this.selectTextAreaData &&
            this.selectTextAreaData.length &&
            this.selectTextAreaData.every(item => item.translateTextAreaRegion.editble)
          ) {
            if (this.$refs.showImgContent) {
              this.$refs.showImgContent.selectionTexts = []
              this.selectTextAreaData[0].characterSentences.forEach((text, tIdx) => {
                this.$refs.showImgContent.selectionTexts.push(text)
              })
              this.changeSelectTexts(this.$refs.showImgContent.selectionTexts)
            }
          }
        } else if (e.metaKey && (e.key == 'x' || e.key == 'X')) {
          if (
            this.selectTextAreaData &&
            this.selectTextAreaData.length &&
            this.selectTextAreaData.every(item => !item.translateTextAreaRegion.editble)
          ) {
            this.copyTextArea = JSON.parse(JSON.stringify(this.selectTextAreaData))
            localStorage.setItem(
              'translate_copy_text_area',
              JSON.stringify({ isShear: true, data: this.selectTextAreaData })
            )
            this.isShear = true
            if (this.cardInput) return
            if (this.contentList.some(item => item.visible)) return
            if (this.$refs.searchModal && this.$refs.searchModal.visible) return
            if (this.$refs.termModal && this.$refs.termModal.visible) return
            if (this.$refs.termEditModal && this.$refs.termEditModal.visible) return
            if (this.$refs.termBackModal && this.$refs.termBackModal.visible) return
            if (this.$refs.sentenceModal && this.$refs.sentenceModal.visible) return
            if (this.$refs.phoneticModal && this.$refs.phoneticModal.visible) return
            if (this.$refs.termNoteModal && this.$refs.termNoteModal.visible) return
            if (
              this.selectTextAreaData.length &&
              this.selectTextAreaData.every(item => !item.translateTextAreaRegion.editble)
            ) {
              let selectTextArea = this.selectTextAreaData
              e.preventDefault()
              if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
              if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
              let that = this
              that.selectTextAreaData = selectTextArea
              that.batchDeleteTextArea()
            }
          } else {
            this.copyTextArea = null
            this.isShear = false
            localStorage.setItem('translate_copy_text_area', '')
          }
        } else if (e.metaKey && (e.key == 'v' || e.key == 'V')) {
          if (
            (this.selectTextAreaData &&
              this.selectTextAreaData.length &&
              this.selectTextAreaData.every(item => !item.translateTextAreaRegion.editble)) ||
            (this.copyTextArea && this.copyTextArea.length && !this.selectTextAreaData.length)
          ) {
            if (e.target.tagName == 'INPUT') return
            this.batchAddTextArea()
          }
        }
      }
    },
    getPrevRevision(page, item, pageIdx, num, selectRevisitionId) {
      this.revisionList.forEach((p, pIdx) => {
        p.modifyHistoryList.forEach((i, itemIdx) => {
          if (i.textAreaId == selectRevisitionId) {
            if (itemIdx > 0) {
              if (!num) {
                item = this.revisionList[pIdx - num].modifyHistoryList[itemIdx - 1]
              } else {
                item = this.revisionList[pIdx - num].modifyHistoryList[
                  this.revisionList[pIdx - num].modifyHistoryList.length - 1
                ]
              }
              page = this.revisionList[pIdx - num]
              pageIdx = p.sortOrderId
            } else {
              let nextNum = -1
              this.revisionList.forEach((rpage, rpageIdx) => {
                if (rpageIdx < pIdx && rpage.modifyHistoryList && rpage.modifyHistoryList.length) {
                  nextNum = rpage.sortOrderId
                  item = rpage.modifyHistoryList[rpage.modifyHistoryList.length - 1]
                  page = rpage
                }
              })
              num = nextNum
              pageIdx = num
              /* this.getNextRevision(page, item, pageIdx, num, selectRevisitionId) */
            }
          }
        })
      })
      if (item) {
        return { page, item, pageIdx }
      } else {
        return null
      }
    },
    //键盘按钮截获
    keyDown() {
      document.onkeydown = async e => {
        if (this.$refs.searchModal && this.$refs.searchModal.visible) return
        if (this.$refs.termModal && this.$refs.termModal.visible) return
        if (this.$refs.termEditModal && this.$refs.termEditModal.visible) return
        if (this.$refs.termBackModal && this.$refs.termBackModal.visible) return
        if (this.$refs.sentenceModal && this.$refs.sentenceModal.visible) return
        if (this.$refs.phoneticModal && this.$refs.phoneticModal.visible) return
        if (this.isWindows) {
          if (e.ctrlKey && (e.key == 'z' || e.key == 'Z')) {
            e.preventDefault()
            if (!this.changeAll) return
            this.backData()
            /* console.log('撤回') */
          } else if (e.ctrlKey && (e.key == 'y' || e.key == 'Y')) {
            e.preventDefault()
            if (!this.changeAll) return
            this.nextData()
            /* console.log('重做') */
          } else if (e.ctrlKey && (e.key == 's' || e.key == 'S')) {
            e.preventDefault()
            this.changeAutoSave()
            /* console.log('保存') */
          } else if (e.ctrlKey && (e.key == '+' || e.key == '=')) {
            e.preventDefault()
            let num = this.showImgListWidth.replaceAll('%', '') - 0
            if (Math.floor(num / 0.7) < 100) {
              num = Math.floor(num / 0.7)
            } else {
              num = 100
            }
            if (this.$refs.centerButtonList) {
              this.$refs.centerButtonList.sliderValue = num
              this.$refs.centerButtonList.sliderHandledChange(num)
            }
            /* console.log('放大') */
          } else if (e.ctrlKey && e.key == '-') {
            e.preventDefault()
            let num = this.showImgListWidth.replaceAll('%', '') - 0
            if (Math.floor(num * 0.7) > 10) {
              num = Math.floor(num * 0.7)
            } else {
              num = 10
            }
            if (this.$refs.centerButtonList) {
              this.$refs.centerButtonList.sliderValue = num
              this.$refs.centerButtonList.sliderHandledChange(num)
            }
            /* console.log('缩小') */
          } else if (e.key == 'ArrowUp') {
            if (this.cardInput || this.isCenterClick) return
            if (this.selectTextAreaData.some(textArea => textArea.translateTextAreaRegion.editble)) return
            if (
              this.$refs.contentList &&
              this.$refs.contentList.selectRevisitionId &&
              (this.contentList[1].visible || this.contentList[3].visible || this.contentList[4].visible)
            ) {
              e.preventDefault()
              let selectRevisitionId = this.$refs.contentList.selectRevisitionId
              let page = null
              let item = null
              let pageIdx = null
              if (
                this.contentList[3].zIndex > this.contentList[4].zIndex &&
                this.contentList[3].zIndex > this.contentList[1].zIndex
              ) {
                const data = this.getPrevRevision(page, item, pageIdx, 0, selectRevisitionId)
                if (data) {
                  this.$refs.contentList.toRevisiton(data.page, data.item, data.pageIdx)
                }
              } else if (
                this.contentList[4].zIndex > this.contentList[3].zIndex &&
                this.contentList[4].zIndex > this.contentList[1].zIndex
              ) {
                this.$refs.contentList.toPrveText()
              } else if (
                this.contentList[1].zIndex > this.contentList[3].zIndex &&
                this.contentList[1].zIndex > this.contentList[4].zIndex
              ) {
                this.$refs.contentList.toPrveTextList()
              }
            } else if (
              this.selectTextAreaData.length == 1 &&
              this.selectTextAreaData.every(item => !item.translateTextAreaRegion.editble)
            ) {
              e.preventDefault()
              let pageIdx = this.pageListData.findIndex(page => this.selectTextAreaData[0].pageId == page.id)
              this.copyData()
              if (pageIdx > -1) {
                let textAreaIdx = this.pageListData[pageIdx].textAreas.findIndex(
                  textArea => textArea.id == this.selectTextAreaData[0].id
                )
                let num = this.showImgLeftListPostions[pageIdx].width / this.pageListData[pageIdx].width - 0
                let textArea = this.selectTextAreaData[0]
                if (textAreaIdx) {
                  textArea = this.pageListData[pageIdx].textAreas[textAreaIdx - 1]
                } else {
                  let nextNum = -1
                  this.pageListData.forEach((page, pageIndex) => {
                    if (pageIndex < pageIdx && page.textAreas.length) {
                      nextNum = pageIndex
                    }
                  })
                  pageIdx = nextNum
                  textArea = this.pageListData[pageIdx].textAreas[this.pageListData[pageIdx].textAreas.length - 1]
                }
                this.selectTextAreaData[0].textAreaRegion.selected = 0
                textArea.textAreaRegion.selected = 1
                let y = (textArea.textAreaRegion.y * num).toFixed(0) - 0
                this.select_list = [this.pageListData[pageIdx].id + '-' + textArea.id + '-right']
                this.selectTextAreaData[0] = textArea
                document.getElementsByClassName('show-img-bottom')[0].scrollTop =
                  this.showImgLeftListPostions[pageIdx].top - this.showImgLeftListPostions[0].top + y - 200
                this.changeSelectList(this.pageListData[pageIdx].id + '-' + textArea.id + '-right')
                /*                 if (this.$refs.showImgContent) {
                  this.$refs.showImgContent.getYahooOrKdxf(
                    textArea.showText,
                    this.pageListData[pageIdx].id,
                    textArea.id,
                    textArea
                  )
                } */
                this.savePageList()
                this.$forceUpdate()
              }
            }
            /* console.log('焦点在修改记录时切换上一个') */
          } else if (e.key == 'ArrowDown') {
            if (this.cardInput || this.isCenterClick) return
            if (this.selectTextAreaData.some(textArea => textArea.translateTextAreaRegion.editble)) return
            if (
              this.$refs.contentList &&
              this.$refs.contentList.selectRevisitionId &&
              (this.contentList[1].visible || this.contentList[3].visible || this.contentList[4].visible)
            ) {
              e.preventDefault()
              let selectRevisitionId = this.$refs.contentList.selectRevisitionId
              let page = null
              let item = null
              let pageIdx = null
              if (
                this.contentList[3].zIndex > this.contentList[4].zIndex &&
                this.contentList[3].zIndex > this.contentList[1].zIndex
              ) {
                const data = this.getNextRevision(page, item, pageIdx, 0, selectRevisitionId)
                if (data) {
                  this.$refs.contentList.toRevisiton(data.page, data.item, data.pageIdx)
                }
              } else if (
                this.contentList[4].zIndex > this.contentList[3].zIndex &&
                this.contentList[4].zIndex > this.contentList[1].zIndex
              ) {
                this.$refs.contentList.toNextText()
              } else if (
                this.contentList[1].zIndex > this.contentList[3].zIndex &&
                this.contentList[1].zIndex > this.contentList[4].zIndex
              ) {
                this.$refs.contentList.toNextTextList()
              }
            } else if (
              this.selectTextAreaData.length == 1 &&
              this.selectTextAreaData.every(item => !item.translateTextAreaRegion.editble)
            ) {
              e.preventDefault()
              this.copyData()
              let pageIdx = this.pageListData.findIndex(page => this.selectTextAreaData[0].pageId == page.id)
              if (pageIdx > -1) {
                let textAreaIdx = this.pageListData[pageIdx].textAreas.findIndex(
                  textArea => textArea.id == this.selectTextAreaData[0].id
                )
                let num = this.showImgLeftListPostions[pageIdx].width / this.pageListData[pageIdx].width - 0
                let textArea = this.selectTextAreaData[0]
                if (textAreaIdx < this.pageListData[pageIdx].textAreas.length - 1) {
                  textArea = this.pageListData[pageIdx].textAreas[textAreaIdx + 1]
                } else {
                  let isPush = false
                  this.pageListData.forEach((page, pageIndex) => {
                    if (pageIndex > pageIdx && page.textAreas.length && !isPush) {
                      isPush = true
                      pageIdx = pageIndex
                      textArea = this.pageListData[pageIdx].textAreas[0]
                    }
                  })
                }
                this.selectTextAreaData[0].textAreaRegion.selected = 0
                textArea.textAreaRegion.selected = 1
                let y = (textArea.textAreaRegion.y * num).toFixed(0) - 0
                this.select_list = [this.pageListData[pageIdx].id + '-' + textArea.id + '-right']
                this.selectTextAreaData[0] = textArea
                document.getElementsByClassName('show-img-bottom')[0].scrollTop =
                  this.showImgLeftListPostions[pageIdx].top - this.showImgLeftListPostions[0].top + y - 200
                this.changeSelectList(this.pageListData[pageIdx].id + '-' + textArea.id + '-right')
                /*                 if (this.$refs.showImgContent) {
                  this.$refs.showImgContent.getYahooOrKdxf(
                    textArea.showText,
                    this.pageListData[pageIdx].id,
                    textArea.id,
                    textArea
                  )
                } */
                this.savePageList()
                this.$forceUpdate()
              }
            }
            /* console.log('焦点在修改记录时切换下一个') */
          } else if (e.key == 'PageUp') {
            if (this.$refs.showImgContent) {
              this.$refs.showImgContent.selectionTexts = []
            }
            if (this.selectTextAreaData.some(textArea => textArea.translateTextAreaRegion.editble)) return
            if (this.cardInput) return
            if (this.contentList.some(item => item.visible)) return
            if (this.$refs.searchModal && this.$refs.searchModal.visible) return
            if (this.$refs.termModal && this.$refs.termModal.visible) return
            if (this.$refs.termEditModal && this.$refs.termEditModal.visible) return
            if (this.$refs.termBackModal && this.$refs.termBackModal.visible) return
            if (this.$refs.sentenceModal && this.$refs.sentenceModal.visible) return
            if (this.$refs.phoneticModal && this.$refs.phoneticModal.visible) return
            if (this.$refs.termNoteModal && this.$refs.termNoteModal.visible) return
            if (
              !this.selectTextAreaData.length ||
              this.selectTextAreaData.every(item => !item.translateTextAreaRegion.editble)
            ) {
              if (this.$refs.showImgContent && this.$refs.showImgContent.isSelectTextArea) return
              e.preventDefault()
              if (this.selectIdx) {
                this.changeSelectIdx(this.selectIdx - 1)
                if (this.$refs.showImgContent) {
                  this.$nextTick(() => {
                    this.$refs.showImgContent.changePage()
                  })
                  let timeOut2 = setTimeout(() => {
                    if (this.$refs.showImgContent) this.$refs.showImgContent.$forceUpdate()
                    clearTimeout(timeOut2)
                  }, 100)
                }
              }
              /* console.log('上页') */
            }
          } else if (e.key == 'PageDown') {
            if (this.$refs.showImgContent) {
              this.$refs.showImgContent.selectionTexts = []
            }
            if (this.selectTextAreaData.some(textArea => textArea.translateTextAreaRegion.editble)) return
            if (this.cardInput) return
            if (this.contentList.some(item => item.visible)) return
            if (this.$refs.searchModal && this.$refs.searchModal.visible) return
            if (this.$refs.termModal && this.$refs.termModal.visible) return
            if (this.$refs.termEditModal && this.$refs.termEditModal.visible) return
            if (this.$refs.termBackModal && this.$refs.termBackModal.visible) return
            if (this.$refs.sentenceModal && this.$refs.sentenceModal.visible) return
            if (this.$refs.phoneticModal && this.$refs.phoneticModal.visible) return
            if (this.$refs.termNoteModal && this.$refs.termNoteModal.visible) return
            if (
              !this.selectTextAreaData.length ||
              this.selectTextAreaData.every(item => !item.translateTextAreaRegion.editble)
            ) {
              if (this.$refs.showImgContent && this.$refs.showImgContent.isSelectTextArea) return
              e.preventDefault()
              if (this.selectIdx < this.pageListData.length - 1) {
                this.changeSelectIdx(this.selectIdx + 1)
                if (this.$refs.showImgContent) {
                  this.$nextTick(() => {
                    this.$refs.showImgContent.changePage()
                  })
                  let timeOut2 = setTimeout(() => {
                    if (this.$refs.showImgContent) this.$refs.showImgContent.$forceUpdate()
                    clearTimeout(timeOut2)
                  }, 100)
                }
              }
              /* console.log('下页') */
            }
          } else if (e.key == 'ArrowLeft') {
            if (this.cardInput || this.isCenterClick) return
            if (this.selectTextAreaData.some(textArea => textArea.translateTextAreaRegion.editble)) return
            if (this.contentList.some(item => item.visible)) return
            if (this.$refs.searchModal && this.$refs.searchModal.visible) return
            if (this.$refs.termModal && this.$refs.termModal.visible) return
            if (this.$refs.termEditModal && this.$refs.termEditModal.visible) return
            if (this.$refs.termBackModal && this.$refs.termBackModal.visible) return
            if (this.$refs.sentenceModal && this.$refs.sentenceModal.visible) return
            if (this.$refs.phoneticModal && this.$refs.phoneticModal.visible) return
            if (this.$refs.termNoteModal && this.$refs.termNoteModal.visible) return
            if (
              !this.selectTextAreaData.length ||
              this.selectTextAreaData.every(item => !item.translateTextAreaRegion.editble)
            ) {
              if (document.getElementsByClassName('show-img-bottom')[0].scrollTop) {
                e.preventDefault()
                for (var i = 0; i < 8; i++) {
                  let timeOut = setTimeout(() => {
                    document.getElementsByClassName('show-img-bottom')[0].scrollTop =
                      document.getElementsByClassName('show-img-bottom')[0].scrollTop - 5
                    clearTimeout(timeOut)
                  }, i * 12)
                }
              }
            }
          } else if (e.key == 'ArrowRight') {
            if (this.cardInput || this.isCenterClick) return
            if (this.selectTextAreaData.some(textArea => textArea.translateTextAreaRegion.editble)) return
            if (this.contentList.some(item => item.visible)) return
            if (this.$refs.searchModal && this.$refs.searchModal.visible) return
            if (this.$refs.termModal && this.$refs.termModal.visible) return
            if (this.$refs.termEditModal && this.$refs.termEditModal.visible) return
            if (this.$refs.termBackModal && this.$refs.termBackModal.visible) return
            if (this.$refs.sentenceModal && this.$refs.sentenceModal.visible) return
            if (this.$refs.phoneticModal && this.$refs.phoneticModal.visible) return
            if (this.$refs.termNoteModal && this.$refs.termNoteModal.visible) return
            if (
              !this.selectTextAreaData.length ||
              this.selectTextAreaData.every(item => !item.translateTextAreaRegion.editble)
            ) {
              e.preventDefault()
              for (var i = 0; i < 8; i++) {
                let timeOut = setTimeout(() => {
                  document.getElementsByClassName('show-img-bottom')[0].scrollTop =
                    document.getElementsByClassName('show-img-bottom')[0].scrollTop + 5
                  clearTimeout(timeOut)
                }, i * 12)
              }
            }
          } else if (e.key == 'Delete') {
            if (
              this.selectTextAreaData.length &&
              this.selectTextAreaData.some(item => item.translateTextAreaRegion.editble)
            ) {
              return
            }
            if (this.selectTextAreaData.length) {
              let selectTextArea = this.selectTextAreaData
              e.preventDefault()
              if (this.isRejectStatus && this.selectTextAreaData.some(item => !item.isReject)) {
                return this.$message.warning('当前文本框禁止操作！')
              }
              if (!this.changeAll) return
              if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
              if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
              if (this.isCenterClick) return
              let that = this
              this.$confirm({
                title: '确认删除?',
                okText: '确认',
                cancelText: '取消',
                zIndex: 9999,
                onOk() {
                  that.selectTextAreaData = selectTextArea
                  that.batchDeleteTextArea()
                },
                onCancel() {
                  /* console.log('Cancel') */
                }
              })
            }
            if (this.leftAnnotationList.some(page => page.annotationList.some(item => item.selected))) {
              if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
              if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
              let id = -1
              let aIdx = -1
              let page = null
              this.leftAnnotationList.forEach(p => {
                p.annotationList.forEach((item, idx) => {
                  if (item.selected) {
                    id = item.id || -1
                    aIdx = idx
                    page = p
                  }
                })
              })
              let that = this
              this.$confirm({
                title: '确认删除?',
                okText: '确认',
                cancelText: '取消',
                zIndex: 9999,
                async onOk() {
                  if (id > -1) {
                    that.isDelete = true
                    page.annotationList.splice(aIdx, 1)
                    const res = await deleteAction('/annotation/delete/' + id)
                    if (res.code == 200) {
                      /* that.$message.success('删除成功') */
                      that.$emit('getLocalList')
                    } else {
                      that.$message.error(res.msg || res.errorMsg)
                    }
                  } else {
                    that.isDelete = true
                    page.annotationList.splice(aIdx, 1)
                    /* that.$message.success('删除成功') */
                  }
                },
                onCancel() {
                  /* console.log('Cancel') */
                }
              })
            }
            if (this.rightAnnotationList.some(page => page.annotationList.some(item => item.selected))) {
              if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
              if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
              let id = -1
              let aIdx = -1
              let page = null
              this.rightAnnotationList.forEach(p => {
                p.annotationList.forEach((item, idx) => {
                  if (item.selected) {
                    id = item.id || -1
                    aIdx = idx
                    page = p
                  }
                })
              })
              let that = this
              this.$confirm({
                title: '确认删除?',
                okText: '确认',
                cancelText: '取消',
                zIndex: 9999,
                async onOk() {
                  if (id > -1) {
                    that.isDelete = true
                    page.annotationList.splice(aIdx, 1)
                    const res = await deleteAction('/annotation/delete/' + id)
                    if (res.code == 200) {
                      /* that.$message.success('删除成功') */
                      that.$emit('getLocalList')
                    } else {
                      that.$message.error(res.msg || res.errorMsg)
                    }
                  } else {
                    that.isDelete = true
                    page.annotationList.splice(aIdx, 1)
                    /* that.$message.success('删除成功') */
                  }
                },
                onCancel() {
                  /* console.log('Cancel') */
                }
              })
            }
            /* console.log('删除文本框') */
          } else if (e.key == 'Backspace') {
          }
        } else {
          if (e.metaKey && (e.key == 'z' || e.key == 'Z')) {
            e.preventDefault()
            if (!this.changeAll) return
            this.backData()
            /* console.log('撤回') */
          } else if (e.metaKey && (e.key == 'y' || e.key == 'Y')) {
            e.preventDefault()
            if (!this.changeAll) return
            this.nextData()
            /* console.log('重做') */
          } else if (e.metaKey && (e.key == 's' || e.key == 'S')) {
            e.preventDefault()
            this.changeAutoSave()
            /* console.log('保存') */
          } else if (e.metaKey && (e.key == '+' || e.key == '=')) {
            e.preventDefault()
            let num = this.showImgListWidth.replaceAll('%', '') - 0
            if (Math.floor(num / 0.7) < 100) {
              num = Math.floor(num / 0.7)
            } else {
              num = 100
            }
            if (this.$refs.centerButtonList) {
              this.$refs.centerButtonList.sliderValue = num
              this.$refs.centerButtonList.sliderHandledChange(num)
            }
            /* console.log('放大') */
          } else if (e.metaKey && e.key == '-') {
            e.preventDefault()
            let num = this.showImgListWidth.replaceAll('%', '') - 0
            if (Math.floor(num * 0.7) > 10) {
              num = Math.floor(num * 0.7)
            } else {
              num = 10
            }
            if (this.$refs.centerButtonList) {
              this.$refs.centerButtonList.sliderValue = num
              this.$refs.centerButtonList.sliderHandledChange(num)
            }
            /* console.log('缩小') */
          } else if (e.key == 'ArrowUp') {
            if (this.cardInput) return
            if (this.selectTextAreaData.some(textArea => textArea.translateTextAreaRegion.editble)) return
            if (
              this.$refs.contentList &&
              this.$refs.contentList.selectRevisitionId &&
              (this.contentList[1].visible || this.contentList[3].visible || this.contentList[4].visible)
            ) {
              e.preventDefault()
              let selectRevisitionId = this.$refs.contentList.selectRevisitionId
              let page = null
              let item = null
              let pageIdx = null
              if (
                this.contentList[3].zIndex > this.contentList[4].zIndex &&
                this.contentList[3].zIndex > this.contentList[1].zIndex
              ) {
                const data = this.getPrevRevision(page, item, pageIdx, 0, selectRevisitionId)
                if (data) {
                  this.$refs.contentList.toRevisiton(data.page, data.item, data.pageIdx)
                }
              } else if (
                this.contentList[4].zIndex > this.contentList[3].zIndex &&
                this.contentList[4].zIndex > this.contentList[1].zIndex
              ) {
                this.$refs.contentList.toPrveText()
              } else if (
                this.contentList[1].zIndex > this.contentList[3].zIndex &&
                this.contentList[1].zIndex > this.contentList[4].zIndex
              ) {
                let pageIdx = this.pageListData.findIndex(page => this.selectTextAreaData[0].pageId == page.id)
                if (pageIdx > -1) {
                  let textAreaIdx = this.pageListData[pageIdx].textAreas.findIndex(
                    textArea => textArea.id == this.selectTextAreaData[0].id
                  )
                  let num = this.showImgLeftListPostions[pageIdx].width / this.pageListData[pageIdx].width - 0
                  let textArea = this.selectTextAreaData[0]
                  if (textAreaIdx) {
                    textArea = this.pageListData[pageIdx].textAreas[textAreaIdx - 1]
                  } else {
                    this.pageListData.forEach((page, pageIndex) => {
                      if (pageIndex < pageIdx && page.textAreas.length) {
                        pageIdx = pageIndex
                        textArea = this.pageListData[pageIdx].textAreas[this.pageListData[pageIdx].textAreas.length - 1]
                      }
                    })
                  }
                  this.selectTextAreaData[0].textAreaRegion.selected = 0
                  textArea.textAreaRegion.selected = 1
                  let y = (textArea.textAreaRegion.y * num).toFixed(0) - 0
                  this.select_list = [this.pageListData[pageIdx].id + '-' + textArea.id + '-right']
                  this.selectTextAreaData[0] = textArea
                  document.getElementsByClassName('show-img-bottom')[0].scrollTop =
                    this.showImgLeftListPostions[pageIdx].top - this.showImgLeftListPostions[0].top + y - 200
                  this.changeSelectList(this.pageListData[pageIdx].id + '-' + textArea.id + '-right')
                  this.$forceUpdate()
                }
              }
            } else if (
              this.selectTextAreaData.length == 1 &&
              this.selectTextAreaData.every(item => !item.translateTextAreaRegion.editble)
            ) {
              e.preventDefault()
              this.copyData()
              let pageIdx = this.pageListData.findIndex(page => this.selectTextAreaData[0].pageId == page.id)
              if (pageIdx > -1) {
                let textAreaIdx = this.pageListData[pageIdx].textAreas.findIndex(
                  textArea => textArea.id == this.selectTextAreaData[0].id
                )
                let num = this.showImgLeftListPostions[pageIdx].width / this.pageListData[pageIdx].width - 0
                let textArea = this.selectTextAreaData[0]
                if (textAreaIdx) {
                  textArea = this.pageListData[pageIdx].textAreas[textAreaIdx - 1]
                } else {
                  this.pageListData.forEach((page, pageIndex) => {
                    if (pageIndex < pageIdx && page.textAreas.length) {
                      pageIdx = pageIndex
                      textArea = this.pageListData[pageIdx].textAreas[this.pageListData[pageIdx].textAreas.length - 1]
                    }
                  })
                }
                this.selectTextAreaData[0].textAreaRegion.selected = 0
                textArea.textAreaRegion.selected = 1
                let y = (textArea.textAreaRegion.y * num).toFixed(0) - 0
                this.select_list = [this.pageListData[pageIdx].id + '-' + textArea.id + '-right']
                this.selectTextAreaData[0] = textArea
                document.getElementsByClassName('show-img-bottom')[0].scrollTop =
                  this.showImgLeftListPostions[pageIdx].top - this.showImgLeftListPostions[0].top + y - 200
                this.changeSelectList(this.pageListData[pageIdx].id + '-' + textArea.id + '-right')
                /*                 if (this.$refs.showImgContent) {
                  this.$refs.showImgContent.getYahooOrKdxf(
                    textArea.showText,
                    this.pageListData[pageIdx].id,
                    textArea.id,
                    textArea
                  )
                } */
                this.savePageList()
                this.$forceUpdate()
              }
            }
            /* console.log('焦点在修改记录时切换上一个') */
          } else if (e.key == 'ArrowDown') {
            if (this.cardInput || this.isCenterClick) return
            if (this.selectTextAreaData.some(textArea => textArea.translateTextAreaRegion.editble)) return
            if (
              this.$refs.contentList &&
              this.$refs.contentList.selectRevisitionId &&
              (this.contentList[1].visible || this.contentList[3].visible || this.contentList[4].visible)
            ) {
              e.preventDefault()
              let selectRevisitionId = this.$refs.contentList.selectRevisitionId
              let page = null
              let item = null
              let pageIdx = null
              if (
                this.contentList[3].zIndex > this.contentList[4].zIndex &&
                this.contentList[3].zIndex > this.contentList[1].zIndex
              ) {
                const data = this.getNextRevision(page, item, pageIdx, 0, selectRevisitionId)
                if (data) {
                  this.$refs.contentList.toRevisiton(data.page, data.item, data.pageIdx)
                }
              } else if (
                this.contentList[4].zIndex > this.contentList[3].zIndex &&
                this.contentList[4].zIndex > this.contentList[1].zIndex
              ) {
                this.$refs.contentList.toNextText()
              } else if (
                this.contentList[1].zIndex > this.contentList[3].zIndex &&
                this.contentList[1].zIndex > this.contentList[4].zIndex
              ) {
                let pageIdx = this.pageListData.findIndex(page => this.selectTextAreaData[0].pageId == page.id)
                if (pageIdx > -1) {
                  let textAreaIdx = this.pageListData[pageIdx].textAreas.findIndex(
                    textArea => textArea.id == this.selectTextAreaData[0].id
                  )
                  let num = this.showImgLeftListPostions[pageIdx].width / this.pageListData[pageIdx].width - 0
                  let textArea = this.selectTextAreaData[0]
                  if (textAreaIdx < this.pageListData[pageIdx].textAreas.length - 1) {
                    textArea = this.pageListData[pageIdx].textAreas[textAreaIdx + 1]
                  } else {
                    let isPush = false
                    this.pageListData.forEach((page, pageIndex) => {
                      if (pageIndex > pageIdx && page.textAreas.length && !isPush) {
                        isPush = true
                        pageIdx = pageIndex
                        textArea = this.pageListData[pageIdx].textAreas[0]
                      }
                    })
                  }
                  this.selectTextAreaData[0].textAreaRegion.selected = 0
                  textArea.textAreaRegion.selected = 1
                  let y = (textArea.textAreaRegion.y * num).toFixed(0) - 0
                  this.select_list = [this.pageListData[pageIdx].id + '-' + textArea.id + '-right']
                  this.selectTextAreaData[0] = textArea
                  document.getElementsByClassName('show-img-bottom')[0].scrollTop =
                    this.showImgLeftListPostions[pageIdx].top - this.showImgLeftListPostions[0].top + y - 200
                  this.changeSelectList(this.pageListData[pageIdx].id + '-' + textArea.id + '-right')
                  this.$forceUpdate()
                }
              }
            } else if (
              this.selectTextAreaData.length == 1 &&
              this.selectTextAreaData.every(item => !item.translateTextAreaRegion.editble)
            ) {
              e.preventDefault()
              let pageIdx = this.pageListData.findIndex(page => this.selectTextAreaData[0].pageId == page.id)
              this.copyData()
              if (pageIdx > -1) {
                let textAreaIdx = this.pageListData[pageIdx].textAreas.findIndex(
                  textArea => textArea.id == this.selectTextAreaData[0].id
                )
                let num = this.showImgLeftListPostions[pageIdx].width / this.pageListData[pageIdx].width - 0
                let textArea = this.selectTextAreaData[0]
                if (textAreaIdx < this.pageListData[pageIdx].textAreas.length - 1) {
                  textArea = this.pageListData[pageIdx].textAreas[textAreaIdx + 1]
                } else {
                  let isPush = false
                  this.pageListData.forEach((page, pageIndex) => {
                    if (pageIndex > pageIdx && page.textAreas.length && !isPush) {
                      isPush = true
                      pageIdx = pageIndex
                      textArea = this.pageListData[pageIdx].textAreas[0]
                    }
                  })
                }
                this.selectTextAreaData[0].textAreaRegion.selected = 0
                textArea.textAreaRegion.selected = 1
                let y = (textArea.textAreaRegion.y * num).toFixed(0) - 0
                this.select_list = [this.pageListData[pageIdx].id + '-' + textArea.id + '-right']
                this.selectTextAreaData[0] = textArea
                document.getElementsByClassName('show-img-bottom')[0].scrollTop =
                  this.showImgLeftListPostions[pageIdx].top - this.showImgLeftListPostions[0].top + y - 200
                this.changeSelectList(this.pageListData[pageIdx].id + '-' + textArea.id + '-right')
                /*                 if (this.$refs.showImgContent) {
                  this.$refs.showImgContent.getYahooOrKdxf(
                    textArea.showText,
                    this.pageListData[pageIdx].id,
                    textArea.id,
                    textArea
                  )
                } */
                this.savePageList()
                this.$forceUpdate()
              }
            }
            /* console.log('焦点在修改记录时切换下一个') */
          } else if (e.key == 'PageUp') {
            if (this.$refs.showImgContent) {
              this.$refs.showImgContent.selectionTexts = []
            }
            if (this.cardInput) return
            if (this.selectTextAreaData.some(textArea => textArea.translateTextAreaRegion.editble)) return
            if (this.contentList.some(item => item.visible)) return
            if (this.$refs.searchModal && this.$refs.searchModal.visible) return
            if (this.$refs.termModal && this.$refs.termModal.visible) return
            if (this.$refs.termEditModal && this.$refs.termEditModal.visible) return
            if (this.$refs.termBackModal && this.$refs.termBackModal.visible) return
            if (this.$refs.sentenceModal && this.$refs.sentenceModal.visible) return
            if (this.$refs.phoneticModal && this.$refs.phoneticModal.visible) return
            if (this.$refs.termNoteModal && this.$refs.termNoteModal.visible) return
            if (
              !this.selectTextAreaData.length ||
              this.selectTextAreaData.every(item => !item.translateTextAreaRegion.editble)
            ) {
              if (this.isDelete) return
              e.preventDefault()
              if (this.selectIdx) {
                this.changeSelectIdx(this.selectIdx - 1)
                if (this.$refs.showImgContent) {
                  this.$nextTick(() => {
                    this.$refs.showImgContent.changePage()
                  })
                  let timeOut2 = setTimeout(() => {
                    if (this.$refs.showImgContent) this.$refs.showImgContent.$forceUpdate()
                    clearTimeout(timeOut2)
                  }, 100)
                }
              }
              /* console.log('上页') */
            }
          } else if (e.key == 'PageDown') {
            if (this.$refs.showImgContent) {
              this.$refs.showImgContent.selectionTexts = []
            }
            if (this.cardInput || this.isCenterClick) return
            if (this.selectTextAreaData.some(textArea => textArea.translateTextAreaRegion.editble)) return
            if (this.contentList.some(item => item.visible)) return
            if (this.$refs.searchModal && this.$refs.searchModal.visible) return
            if (this.$refs.termModal && this.$refs.termModal.visible) return
            if (this.$refs.termEditModal && this.$refs.termEditModal.visible) return
            if (this.$refs.termBackModal && this.$refs.termBackModal.visible) return
            if (this.$refs.sentenceModal && this.$refs.sentenceModal.visible) return
            if (this.$refs.phoneticModal && this.$refs.phoneticModal.visible) return
            if (this.$refs.termNoteModal && this.$refs.termNoteModal.visible) return
            if (
              !this.selectTextAreaData.length ||
              this.selectTextAreaData.every(item => !item.translateTextAreaRegion.editble)
            ) {
              if (this.isDelete) return
              e.preventDefault()
              if (this.selectIdx < this.pageListData.length - 1) {
                this.changeSelectIdx(this.selectIdx + 1)
                if (this.$refs.showImgContent) {
                  this.$nextTick(() => {
                    this.$refs.showImgContent.changePage()
                  })
                  let timeOut2 = setTimeout(() => {
                    if (this.$refs.showImgContent) this.$refs.showImgContent.$forceUpdate()
                    clearTimeout(timeOut2)
                  }, 100)
                }
              }
              /* console.log('下页') */
            }
          } else if (e.key == 'ArrowLeft') {
            if (this.cardInput || this.isCenterClick) return
            if (this.selectTextAreaData.some(textArea => textArea.translateTextAreaRegion.editble)) return
            if (this.contentList.some(item => item.visible)) return
            if (this.$refs.searchModal && this.$refs.searchModal.visible) return
            if (this.$refs.termModal && this.$refs.termModal.visible) return
            if (this.$refs.termEditModal && this.$refs.termEditModal.visible) return
            if (this.$refs.termBackModal && this.$refs.termBackModal.visible) return
            if (this.$refs.sentenceModal && this.$refs.sentenceModal.visible) return
            if (this.$refs.phoneticModal && this.$refs.phoneticModal.visible) return
            if (this.$refs.termNoteModal && this.$refs.termNoteModal.visible) return
            if (
              !this.selectTextAreaData.length ||
              this.selectTextAreaData.every(item => !item.translateTextAreaRegion.editble)
            ) {
              if (document.getElementsByClassName('show-img-bottom')[0].scrollTop) {
                e.preventDefault()
                document.getElementsByClassName('show-img-bottom')[0].scrollTop =
                  document.getElementsByClassName('show-img-bottom')[0].scrollTop - 10
              }
            }
          } else if (e.key == 'ArrowRight') {
            if (this.cardInput || this.isCenterClick) return
            if (this.selectTextAreaData.some(textArea => textArea.translateTextAreaRegion.editble)) return
            if (this.contentList.some(item => item.visible)) return
            if (this.$refs.searchModal && this.$refs.searchModal.visible) return
            if (this.$refs.termModal && this.$refs.termModal.visible) return
            if (this.$refs.termEditModal && this.$refs.termEditModal.visible) return
            if (this.$refs.termBackModal && this.$refs.termBackModal.visible) return
            if (this.$refs.sentenceModal && this.$refs.sentenceModal.visible) return
            if (this.$refs.phoneticModal && this.$refs.phoneticModal.visible) return
            if (this.$refs.termNoteModal && this.$refs.termNoteModal.visible) return
            if (
              !this.selectTextAreaData.length ||
              this.selectTextAreaData.every(item => !item.translateTextAreaRegion.editble)
            ) {
              e.preventDefault()
              document.getElementsByClassName('show-img-bottom')[0].scrollTop =
                document.getElementsByClassName('show-img-bottom')[0].scrollTop + 10
            }
          } else if (e.key == 'Backspace') {
            /* if (
              this.$refs.contentList &&
              (this.$refs.contentList.selectTextId || this.$refs.contentList.selectRevisitionId)
            )
              return */
            if (this.cardInput) return
            if (this.isCenterClick) return
            if (this.contentList.some(item => item.visible)) return
            if (this.$refs.searchModal && this.$refs.searchModal.visible) return
            if (this.$refs.termModal && this.$refs.termModal.visible) return
            if (this.$refs.termEditModal && this.$refs.termEditModal.visible) return
            if (this.$refs.termBackModal && this.$refs.termBackModal.visible) return
            if (this.$refs.sentenceModal && this.$refs.sentenceModal.visible) return
            if (this.$refs.phoneticModal && this.$refs.phoneticModal.visible) return
            if (this.$refs.termNoteModal && this.$refs.termNoteModal.visible) return
            if (
              this.selectTextAreaData.length &&
              this.selectTextAreaData.every(item => !item.translateTextAreaRegion.editble)
            ) {
              let selectTextArea = this.selectTextAreaData
              e.preventDefault()
              if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
              if (this.taskDetail.taskStatus == '驳回' && !this.taskDetail.rejectTaskId) return
              let that = this
              this.$confirm({
                title: '确认删除?',
                okText: '确认',
                cancelText: '取消',
                zIndex: 9999,
                onOk() {
                  that.selectTextAreaData = selectTextArea
                  that.batchDeleteTextArea()
                },
                onCancel() {
                  /* console.log('Cancel') */
                }
              })
            }
          }
        }
      }
    },
    backToText(data) {
      if (data.originContent != this.selectTextAreaData[0].originalText) {
        this.selectTextAreaData[0].originalText = data.originContent
      }
      if (data.processedContent != this.selectTextAreaData[0].showText) {
        this.selectTextAreaData[0].showText = data.processedContent
        this.selectTextAreaData[0].characterSentences = []
        data.processedContent.split('').forEach(t => {
          this.selectTextAreaData[0].characterSentences.push({
            ...this.$refs.centerButtonList.oldTextBatchStyle,
            textAlign: this.$refs.centerButtonList.textBatchStyle.textAlign,
            fontFamily: this.$refs.centerButtonList.textBatchStyle.fontFamily,
            characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
            characterText: t
          })
        })
      }
      if (this.$refs.showImgContent) {
        this.selectTextAreaData[0].showTexts = []
        this.getTermsBack()
        this.$nextTick(() => {
          this.$refs.showImgContent.changeShowText(this.selectTextAreaData[0])
          this.copyData()
          this.savePageList()
        })
      } else {
        this.copyData()
        this.savePageList()
      }
    },
    handleOk(type) {
      this.isAutoSave = false
      if (type) {
        this.copyData()
        this.savePageList()
        window.close()
      } else {
        window.close()
      }
    },
    handleCancel() {
      this.saveVisible = false
    },
    batchDeleteTextArea() {
      this.pageListData.forEach(page => {
        this.selectTextAreaData.forEach(selectData => {
          if (page.id == selectData.pageId) {
            page.textAreas.splice(
              page.textAreas.findIndex(i => i.id == selectData.id),
              1
            )
          }
        })
      })
      this.delePageList(this.selectTextAreaData.map(item => item.id))
      this.selectTextAreaData = []
      this.select_list = []
    },
    checkPermission(permission) {
      return checkPermission(permission, this.authList)
    },
    async changeAutoSave() {
      if (this.isAutoSave && !this.isEditble) {
        this.isAutoSave = false
        this.copyData()
        this.savePageList()
      } else {
        this.isEditble = false
        this.copyData()
        let changeData = null
        let oldData = JSON.parse(JSON.stringify(this.pageListData))
        let newData = null
        if (this.changeProcess.length) {
          newData = JSON.parse(JSON.stringify(this.changeProcess[this.changeProcess.length - 1]))
        } else {
          return
        } /*  else {
          newData = JSON.parse(JSON.stringify(this.changeProcess[this.changeProcess.length - 1]))
        } */
        for (let index in oldData) {
          if (changeData) break
          for (let idx in newData) {
            if (index == idx) {
              if (changeData) break
              if (oldData[index].textAreas.length == newData[idx].textAreas.length) {
                for (let oldDataIndex in oldData[index].textAreas) {
                  if (changeData) break
                  for (let newDataIdx in newData[idx].textAreas) {
                    if (changeData) break
                    if (newDataIdx == oldDataIndex) {
                      let oldText = JSON.parse(JSON.stringify(oldData[index].textAreas[oldDataIndex].textAreaRegion))
                      let newText = JSON.parse(JSON.stringify(newData[idx].textAreas[newDataIdx].textAreaRegion))
                      oldText.selected = 0
                      newText.selected = 0
                      if (JSON.stringify(oldText) != JSON.stringify(newText)) {
                        changeData = { ...newData[idx].textAreas[newDataIdx], changeIdx: idx }
                        break
                      }
                      let oldTranslateText = JSON.parse(JSON.stringify(oldData[index].textAreas[oldDataIndex]))
                      let newTranslateText = JSON.parse(JSON.stringify(newData[idx].textAreas[newDataIdx]))
                      oldTranslateText.translateTextAreaRegion.editble = 0
                      newTranslateText.translateTextAreaRegion.editble = 0
                      oldTranslateText.textAreaRegion.selected = 0
                      newTranslateText.textAreaRegion.selected = 0
                      if (!newTranslateText.backgroundColor) {
                        newTranslateText.backgroundColor = '#fff'
                      }
                      if (!oldTranslateText.backgroundColor) {
                        oldTranslateText.backgroundColor = '#fff'
                      }
                      for (let newTextKey in newTranslateText.characterSentences) {
                        if (!newTranslateText.characterSentences[newTextKey].strokeColor) {
                          newTranslateText.characterSentences[
                            newTextKey
                          ].strokeColor = this.$refs.centerButtonList.textBatchStyle.strokeColor
                        }
                        if (!newTranslateText.characterSentences[newTextKey].hasMark)
                          newTranslateText.characterSentences[newTextKey].hasMark = 0
                      }
                      for (let newTextKey in oldTranslateText.characterSentences) {
                        if (!oldTranslateText.characterSentences[newTextKey].strokeColor) {
                          oldTranslateText.characterSentences[
                            newTextKey
                          ].strokeColor = this.$refs.centerButtonList.textBatchStyle.strokeColor
                        }
                        if (!oldTranslateText.characterSentences[newTextKey].hasMark)
                          oldTranslateText.characterSentences[newTextKey].hasMark = 0
                      }
                      if (JSON.stringify(oldTranslateText) != JSON.stringify(newTranslateText)) {
                        changeData = { ...newData[idx].textAreas[newDataIdx], changeIdx: idx }
                        break
                      }
                    }
                  }
                }
              } else {
                changeData = true
                //添加或删除文本框
              }
            }
          }
        }
        if (changeData) {
          let pageListData = []
          if (this.selectTextAreaDataCopy.length) {
            let pages = []
            this.pageListData.forEach(page => {
              if (this.selectTextAreaDataCopy.some(selectItem => selectItem.pageId == page.id)) {
                pages.push(page)
              }
            })
            pageListData = pages.map(page => {
              return {
                id: page.id,
                filePath: page.filePath,
                fileType: page.fileType,
                textAreas: this.selectTextAreaDataCopy.filter(selectItem => selectItem.pageId == page.id),
                stageName: this.taskDetail.stageName,
                fileName: page.fileName,
                userId: this.userInfo.id
              }
            })
          } else {
            pageListData = this.pageListData.map(page => {
              return {
                id: page.id,
                filePath: page.filePath,
                fileType: page.fileType,
                textAreas: page.textAreas,
                stageName: this.taskDetail.stageName,
                fileName: page.fileName,
                userId: this.userInfo.id
              }
            })
          }
          let saveList = JSON.parse(JSON.stringify(pageListData))
          saveList.forEach(page => {
            page.textAreas.forEach(textArea => {
              textArea.characterSentences.forEach(text => {
                if (text.phonetic && text.phonetic.targetCharacterIds) {
                  text.phonetic.targetCharacterTempFrontIds = JSON.parse(
                    JSON.stringify(text.phonetic.targetCharacterIds)
                  )
                  text.phonetic.targetCharacterTempFrontIds[0] = text.characterSentenceId
                  text.phonetic.targetCharacterTempFrontIds.forEach(targetId => {
                    targetId = targetId + ''
                  })
                  text.phonetic.tempFrontId = text.characterSentenceId
                  text.phonetic.targetCharacterIds = []
                }
                if (
                  typeof text.characterSentenceId != 'number' &&
                  (text.characterSentenceId + '').indexOf('new') > -1
                ) {
                  text.tempFrontId = text.characterSentenceId
                  text.characterSentenceId = null
                }
              })
              if (textArea.id && typeof textArea.id != 'number' && textArea.id.indexOf('new') > -1) {
                textArea.id = null
              }
              if (textArea.characterSentences.length) {
                textArea.showText = textArea.characterSentences
                  .map(text => (text.characterText == '<br>' ? '\n' : text.characterText))
                  .join('')
                if (this.taskDetail.stageName == '翻译') {
                  textArea.translateText = textArea.showText
                } else if (this.taskDetail.stageName == '校对') {
                  textArea.revisionText = textArea.showText
                } else if (this.taskDetail.stageName == '终审') {
                  textArea.auditText = textArea.showText
                }
              } else {
                textArea.characterSentences = [
                  {
                    ...this.$refs.centerButtonList.oldTextBatchStyle,
                    fontFamily: this.$refs.centerButtonList.textBatchStyle.fontFamily,
                    characterSentenceId: null,
                    characterText: ''
                  }
                ]
              }
            })
          })
          //因为保存比较慢，所以暂时做成先保存撤回记录，再进行保存
          this.saveIndex++
          if (this.changeProcess[this.saveIndex]) {
            this.changeProcess[this.saveIndex] = JSON.parse(JSON.stringify(this.pageListData))
          } else {
            this.changeProcess.push(JSON.parse(JSON.stringify(this.pageListData)))
          }
          this.isAutoSave = true
          this.isSaved = true
          const res = await v1postAction('/textarea/save_list', {
            list: saveList,
            selectTextAreaId: changeData.id ? changeData.id : null,
            taskId: this.taskDetail.taskId
          })
          this.selectTextAreaDataCopy = []
          this.isSaved = false
          if (res.code == 200) {
            this.$message.success('保存成功')
            if (changeData.id) {
              this.pageListData.forEach((page, index) => {
                page.textAreas.forEach(textArea => {
                  if (textArea.id == changeData.id) {
                    res.data
                      ?.find(resPage => resPage.id == page.id)
                      .textAreas?.forEach(rt => {
                        if (rt.id == textArea.id) {
                          textArea.characterSentences.forEach((text, textIdx) => {
                            text.characterSentenceId = rt.characterSentences[textIdx].characterSentenceId
                            if (text.phonetic) {
                              text.phonetic.targetCharacterIds =
                                rt.characterSentences[textIdx].phonetic.targetCharacterIds
                            }
                          })
                        }
                      })
                  }
                })
              })
            }
            /* this.$message.success('保存成功') */
            /*           this.pageListData.forEach((page, index) => {
            page.textAreas = res.data[index].textAreas
          }) */
            this.isAutoSave = false
            this.getRevisionList()
            this.getTextList()
            this.rePositionDragBox()
          } else {
            this.isAutoSave = true
            this.$message.error(res.msg || res.errorMsg || res.message)
          }
          /* console.log(this.changeProcess, this.saveIndex) */
        } else {
          this.$message.error('当前记录已保存')
        }
      }
    },
    changeTermsPhonetics() {
      if (this.$refs.blackListModal) {
        this.$refs.blackListModal.termsPhonetics = this.$refs.showImgContent.termsPhonetics
      }
    },
    async addSentence() {
      if (!this.selectTextAreaData.length) {
        this.$message.error('请先选中文本框')
        return
      }
      if (this.selectTextAreaData.length > 1) {
        this.$message.error('佳句无法多选')
        return
      }
      let params = {
        productionId: this.taskDetail.productionId,
        chapterId: this.taskDetail.chapterId,
        productionName: this.taskDetail.productionName,
        userId: this.userInfo.id,
        username: this.userInfo.nickName,
        avatar: this.userInfo.userFile.avatar,
        originalText: this.selectTextAreaData[0].originalText,
        translateText: this.selectTextAreaData[0].showText
      }
      this.$refs.sentenceModal.edit(params)
      /*       const res = await postAction('/beautiful/sentence/edit', params)
      if (res.code == 200) {
        this.$message.success('添加成功')
      } else {
        this.$message.error(res.msg||res.errorMsg)
      } */
    },
    getShowimgRevisionList(item) {
      if (this.$refs.showImgContent) {
        this.$refs.showImgContent.getRevisionList(item)
      }
    },
    deleRevision(item) {
      if (this.$refs.showImgContent) {
        this.$refs.showImgContent.deleRevision(item)
      }
    },

    getSearchList(data) {
      /*       this.searchList = data.list
      this.showSeachIndex = 0
      let searchData = this.searchList[this.showSeachIndex]
      let pageIdx = this.pageListData.findIndex(page => page.id == searchData.pageId)
      this.changeSelectList(searchData.pageId + '-' + searchData.id + '-' + data.type)
      document.getElementsByClassName('show-img-bottom')[0].scrollTop =
        this.showImgLeftListPostions[pageIdx].top - this.showImgLeftListPostions[0].top + searchData.textAreaRegion.y */
    },
    async getBlacklist() {
      const res = await getAction('/production/listGuideByProduct', {
        productionId: this.taskDetail.productionId
      })
      if (res.code == 200) {
        let arr = []
        res.data
          .filter(item => item.classTypeEnum != 'GLOSSARY' && item.classId != 17 && item.classId != 18)
          .forEach(item => {
            item.blacklist?.forEach(i => {
              if (item.classId == 17) i.className = '不注音'
              if (item.classId == 16) i.className = '推荐用字'
              arr.push(i)
            })
          })
        this.blacklistAll = arr
        this.blacklist = this.blacklistAll.filter(item => item.className != '过滤词库')
        this.blacklist.sort(function compareFunction(a, b) {
          return a.className.localeCompare(b.className)
        })
        let phoneticBlackList = res.data.filter(item => item.classId == 17) || []
        if (phoneticBlackList.length && phoneticBlackList[0].blacklist && phoneticBlackList[0].blacklist.length) {
          this.phoneticBlackList = phoneticBlackList[0].blacklist[0].words || []
        }
        this.phoneticBlackList.sort(function compareFunction(a, b) {
          return a.value.localeCompare(b.value)
        })
        let notes = res.data.filter(item => item.classId == 18) || []
        if (notes.length && notes[0].blacklist && notes[0].blacklist.length) {
          notes[0].blacklist[0].words?.forEach(note => {
            if (
              this.termNotes.findIndex(n => n.value == note.value) > -1 &&
              this.termNotes[this.termNotes.findIndex(n => n.value == note.value)].target &&
              this.termNotes[this.termNotes.findIndex(n => n.value == note.value)].target == note.target
            ) {
            } else {
              this.termNotes.push({
                value: note.value,
                target: note.target,
                isProduction: false,
                id: note.id
              })
            }
          })
        }
        this.blacklistAll
          .filter(item => item.className == '过滤词库')
          .forEach(item => {
            this.filtrationList = [...this.filtrationList, ...item.words]
          })
        /* console.log(this.blacklist, this.phoneticBlackList, this.terms, this.glossaryTags, this.termBacks, '词汇') */
      }
    },
    async getListGuideByProduc() {
      const res = await getAction('/production/listGuideByProduct', {
        productionId: this.taskDetail.productionId,
        contentType: 'GLOSSARY'
      })
      if (res.code == 200) {
        this.glossaryTags = []
        res.data?.forEach(item => {
          item.blacklist.forEach(i => {
            i.words.forEach(w => {
              this.glossaryTags.push(w)
            })
          })
        })
        this.glossaryTags.sort(function compareFunction(a, b) {
          return a.value.localeCompare(b.value)
        })
      }
    },
    async getTerms() {
      if (this.$refs.showImgContent) {
        this.$refs.showImgContent.isOpenModal = false
      }
      const res = await getAction('/term/page', {
        productionId: this.taskDetail.productionId,
        size: -1,
        current: 1
      })
      if (res.code == 200) {
        this.terms = []
        this.termsAll = res.data.records
        res.data.records?.forEach(item => {
          this.terms.push({
            value: item.term.originName,
            valueX: item.term.originFamilyName,
            valueM: item.term.originGivenName,
            target: item.term.processedName,
            id: item.term.id,
            targetX: item.term.processedFamilyName,
            targetM: item.term.processedGivenName,
            status: item.term.status,
            type: item.term.termTypeName
          })
        })
        this.terms.sort(function compareFunction(a, b) {
          return a.value.localeCompare(b.value)
        })

        /*         let sortJson = this.getJapaneseSort(this.terms)
        this.terms= []
        for(let key in sortJson){
          this.terms=[...this.terms,...sortJson[key]]
        }
         console.log(sortJson, 789) */
      }
    },
    getJapaneseSort(list) {
      let sortJson = _.groupBy(list, function(item) {
        let indexes = item.value.charAt(0).toUpperCase()
        // 首字符是日文       平假名       片假名        半角片假名     A-Z
        if (indexes.match(/[\u3040-\u309f\u30a0-\u30ff\uff66-\uff9f]/) !== null) {
          let roma = romaji.fromKana(indexes) //转罗马音
          if (indexes == roma) {
            //fail roma
            indexes = item.slice(0, 2)
            roma = romaji.fromKana(indexes) //转罗马音
          }
          if (roma.length == 3) {
            //lqxy
            roma = roma[0] + roma[2]
          }
          // 特殊发音 特殊处理
          roma = roma.replace('f', 'h')
          roma = roma.replace('c', 't')
          roma = roma.replace('j', 's')
          // vi ve we 归u音 a行
          roma = roma.replace(/[wv]/, '')
          // 浊辅音转清辅音
          roma = roma.replace('g', 'k')
          roma = roma.replace('z', 's')
          roma = roma.replace('d', 't')
          roma = roma.replace('b', 'h')
          roma = roma.replace('p', 'h')
          // a i u e o 列合并到a列
          roma = roma.replace(/[iueo]/, 'a')
          indexes = romaji.toHiragana(roma) //索引 平假名
        } else if (indexes.match(/[A-Z]/) == null) {
          //非字母
          indexes = '#'
        }
        return indexes
      })
      return sortJson
    },
    async getTermsBack() {
      const res = await postAction('/backgroundBook/page/group', [
        {
          productionId: this.taskDetail.productionId,
          size: -1,
          current: 1,
          type: '背景文字'
        }
      ])
      if (res.code == 200) {
        this.termBacks = []
        res.data['背景文字'].records?.forEach(item => {
          this.termBacks.push({
            value: item.backgroundBook.originContent,
            target: item.backgroundBook.processedContent,
            id: item.backgroundBook.id
          })
        })
        this.termBacks.sort(function compareFunction(a, b) {
          return a.value.localeCompare(b.value)
        })
        this.allTermBacks = res.data['背景文字'].records
      }
    },
    changeDefualtTextTypeNo(type) {
      this.defualtTextTypeNo = type
    },
    async getTermsNote() {
      const res = await postAction('/term/table/notes', {
        productionId: this.taskDetail.productionId,
        size: -1,
        current: 1,
        searchText: ''
      })
      if (res.code == 200) {
        this.termNotes = res.data.records.map(item => {
          return {
            value: item.hint,
            target: item.revise,
            isProduction: true,
            id: item.id
          }
        })
        this.termNotes.sort(function compareFunction(a, b) {
          return a.value.localeCompare(b.value)
        })
      }
      this.getBlacklist()
    },
    async getDictList() {
      let dictType = 'hy-cf,hy-ys,hy-rs'
      if (this.departName.indexOf('日语部') > -1) {
        dictType = 'ry-cf,ry-ys,ry-rs'
        if (this.taskDetail.stageName == '校对' || this.taskDetail.stageName == '终审') {
          dictType = 'jd_fy-zs_jd_rs,jd_fy-zs_jd_ys,jd_fy-zs_jd_cf'
        }
      }
      const res = await getAction('/sys/dict/list', { pageNo: 1, pageSize: 50, dictCode: dictType })
      const rest = await getAction('/sys/dict/list', {
        pageNo: 1,
        pageSize: 50,
        dictCode: 'szfh,tsfh,tsfh-jsfh,tsfh-jt,tsfh-bd,tsfh-xz'
      })
      if (res.code == 200) {
        if (res.data && res.data.records && res.data.records.length) {
          this.errorDictList = res.data.records
        }
      } else {
        this.$message.error(res.msg || res.errorMsg)
      }
      if (rest.code == 200) {
        this.emjDictList = []
        if (rest.data && rest.data.records && rest.data.records.length) {
          rest.data.records.forEach(async item => {
            const restItem = await getAction('/sys/dictItem/list', { pageNo: 1, pageSize: 50, dictId: item.id })
            if (restItem.code == 200) {
              this.emjDictList.push({
                header: item.dictName,
                children: [...restItem.data.records.filter(r => r.status).map(r => r.itemValue)]
              })
            }
          })
        }
      } else {
        this.$message.error(rest.msg)
      }
    },
    changeIsOpenPhoneticModal() {
      this.isOpenPhoneticModal = false
      this.copyData()
      this.savePageList()
    },
    async changeError(data) {
      let params = {
        productionId: this.taskDetail.productionId,
        chapterId: this.taskDetail.chapterId,
        injuryType: data.type,
        pageId: data.item.pageId,
        remark: data.value,
        targetType: this.taskDetail.stageName == '校对' ? 1 : this.taskDetail.stageName == '终审' ? 2 : 0,
        textAreaId: data.item.textAreaId,
        taskId: this.taskDetail.taskId,
        userId: this.userInfo.id
      }
      const res = await v1postAction('/injury/add_record', params)
      if (res.code == 1) {
        if (this.taskDetail.stageName == '校对') {
          data.item.isProofreadInjury = true
        }
        if (this.taskDetail.stageName == '终审') {
          data.item.isFinalCheckInjury = true
        }
        this.$message.success('添加成功')
        if (this.$refs.blackListModal) {
          this.$refs.blackListModal.getRevisionList(data.item)
        } else {
          this.$refs.showImgContent.getRevisionList(data.item)
        }

        this.getRevisionList()
      } else {
        this.$message.error(res.msg || res.errorMsg)
      }
    },
    changeEditType(type) {
      this.editType = type
    },
    async getLogs() {
      const res = await postAction('/textarea/operation/logs/list', {
        current: 1,
        size: 1000,
        chapterId: this.taskDetail.chapterId,
        productionId: this.taskDetail.productionId
      })
      if (res.code == 200) {
        this.logList = res.data.records
      } else {
        this.$message.error(res.msg || res.errorMsg)
      }
    },
    changeYahoo(showText, pageId, textAreaId, textArea) {
      if (this.$refs.showImgContent) {
        this.$refs.showImgContent.getYahooOrKdxf(showText, pageId, textAreaId, textArea)
      }
    },
    async getRevisionList() {
      const res = await v1postAction('/textArea/modify', {
        current: 1,
        size: 1000,
        chapterId: this.taskDetail.chapterId,
        productionId: this.taskDetail.productionId,
        revisionType: this.$refs.contentList.revisionType,
        isFeedback: this.$refs.contentList.isFeedback,
        changeSign: this.$refs.contentList.changeSignValue,
        isReject: this.$refs.contentList.isReject,
        injuryType: this.$refs.contentList.errors,
        targetType: this.$refs.contentList.revisionType,
        openEditHistory: this.$refs.contentList.openEditHistory ? true : false
      })
      if (res.code == 200) {
        this.revisionList = res.data
      } else {
        this.$message.error(res.msg || res.errorMsg)
      }
    },
    videCancel() {
      this.isOpenVideo = false
    },
    changeSelectList(id) {
      this.savePageList()
      this.select_list = [id]
      let selectTextArea = null
      let selectPage = null
      this.pageListData.forEach(page => {
        if (page.id == id.split('-')[0]) {
          page.textAreas.forEach(textArea => {
            if (textArea.id == id.split('-')[1]) {
              textArea.textAreaRegion.selected = 1
              this.selectTextAreaData = [textArea]
              if (this.$refs.showImgContent) {
                this.$refs.showImgContent.getRevisionList(textArea)
              }
              selectTextArea = textArea
              selectPage = page
            } else {
              textArea.textAreaRegion.selected = 0
            }
          })
        } else {
          page.textAreas.forEach(textArea => {
            textArea.textAreaRegion.selected = 0
          })
        }
      })
      if (this.$refs.showImgContent && selectTextArea) {
        this.$refs.showImgContent.getYahooOrKdxf(
          selectTextArea.showText,
          selectPage.id,
          selectTextArea.id,
          selectTextArea
        )
      }
    },
    changeSelectListAdd(id) {
      this.select_list = [id]
      this.pageListData.forEach(page => {
        if (page.id == id.split('-')[0]) {
          page.textAreas.forEach(textArea => {
            if (textArea.id == id.split('-')[1]) {
              textArea.textAreaRegion.selected = 1
              this.selectTextAreaData = [textArea]
              if (this.$refs.showImgContent) {
                this.$refs.showImgContent.getRevisionList(textArea)
              }
            } else {
              textArea.textAreaRegion.selected = 0
            }
          })
        } else {
          page.textAreas.forEach(textArea => {
            textArea.textAreaRegion.selected = 0
          })
        }
      })
    },
    changeAnnotationListSelect(data) {
      if (data.type == 'left') {
        this.leftAnnotationList.forEach(page => {
          page.annotationList.forEach(item => {
            if (item.id == data.id) {
              item.selected = 1
            } else {
              item.selected = 0
            }
          })
        })
        this.leftAnnotationList = Object.assign([], this.leftAnnotationList)
      } else {
        this.rightAnnotationList.forEach(page => {
          page.annotationList.forEach(item => {
            if (item.id == data.id) {
              item.selected = 1
            } else {
              item.selected = 0
            }
          })
        })
      }
    },
    async getLocalList() {
      const res = await postAction('/annotation/list', {
        chapterId: this.taskDetail.chapterId,
        productionId: this.taskDetail.productionId
      })
      if (res.code == 200) {
        this.leftAnnotationList = []
        this.rightAnnotationList = []
        this.showAnnotationList = res.data.map(page => {
          return {
            annotationList: page.productAnnotationList,
            idx: this.pageListData.findIndex(p => p.id == page.pageId),
            pageId: page.pageId
          }
        })
        if (res.data.length) {
          res.data.forEach((page, idx) => {
            this.leftAnnotationList.push({
              annotationList: page.productAnnotationList.filter(item => item.around == 'left'),
              idx,
              pageId: page.pageId
            })
            this.rightAnnotationList.push({
              annotationList: page.productAnnotationList.filter(item => item.around == 'right'),
              idx,
              pageId: page.pageId
            })
          })
          if (this.leftAnnotationList.length != this.pageListData.length) {
            this.pageListData.forEach((page, idx) => {
              if (this.leftAnnotationList.findIndex(a => a.pageId == page.id) == -1) {
                this.leftAnnotationList.push({
                  annotationList: [],
                  idx,
                  pageId: page.id
                })
              }
            })
          }
          if (this.rightAnnotationList.length != this.pageListData.length) {
            this.pageListData.forEach((page, idx) => {
              if (this.rightAnnotationList.findIndex(a => a.pageId == page.id) == -1) {
                this.rightAnnotationList.push({
                  annotationList: [],
                  idx,
                  pageId: page.id
                })
              }
            })
          }
        } else {
          this.pageListData.forEach((page, idx) => {
            this.leftAnnotationList.push({
              annotationList: [],
              idx,
              pageId: page.id
            })
            this.rightAnnotationList.push({
              annotationList: [],
              idx,
              pageId: page.id
            })
          })
        }
        this.showAnnotationList.sort((a, b) => a.idx - b.idx)
      }
    },
    pushLoacal: simpleDebounce(async function(data) {
      this.pushLoacalSim(data)
    }, 200),
    async pushLoacalSim(data) {
      if (data.commentData) {
        data.commentData.push({
          id: null,
          content: data.postValue,
          productionchapterPageAnnotationId: null
        })
      } else {
        data.commentData = [
          {
            id: null,
            content: data.postValue,
            productionchapterPageAnnotationId: null
          }
        ]
      }
      let params = [
        {
          id: data.id || null,
          position: data.position,
          commentData: data.commentData,
          size: data.size,
          toStage: data.toStage,
          chapterId: this.taskDetail.chapterId,
          pageId: data.pageId,
          productionId: this.taskDetail.productionId,
          around: data.around
        }
      ]
      const res = await postAction('/annotation/edit_annotation', params)
      if (res.code == 200) {
        /* this.$message.success('保存成功') */
        data.postValue = ''
        if (data.around == 'left') {
          let list = this.leftAnnotationList
          list.forEach((page, pageIdx) => {
            if (page.pageId == data.pageId) {
              if (page.annotationList.every(i => i.id != res.data[0].id)) {
                page.annotationList.push({ ...res.data[0], selected: 1 })
                let deleteIdx = -1
                page.annotationList.forEach((a, aIdx) => {
                  if (!a.id) {
                    deleteIdx = aIdx
                  }
                })
                if (deleteIdx > -1) {
                  page.annotationList.splice(deleteIdx, 1)
                }
              } else {
                page.annotationList.forEach((i, aIdx) => {
                  if (i.id == res.data[0].id) {
                    list[pageIdx].annotationList[aIdx] = Object.assign({}, { ...res.data[0], selected: 1 })
                  }
                })
              }
            }
          })
          this.leftAnnotationList = JSON.parse(JSON.stringify(list))
        } else {
          let list = this.rightAnnotationList
          list.forEach((page, pageIdx) => {
            if (page.pageId == data.pageId) {
              res.data[0].postValue = ''
              if (page.annotationList.every(i => i.id != res.data[0].id)) {
                page.annotationList.push({ ...res.data[0], selected: 1 })
                let deleteIdx = -1
                page.annotationList.forEach((a, aIdx) => {
                  if (!a.id) {
                    deleteIdx = aIdx
                  }
                })
                if (deleteIdx > -1) {
                  page.annotationList.splice(deleteIdx, 1)
                }
              } else {
                page.annotationList.forEach((i, aIdx) => {
                  if (i.id == res.data[0].id) {
                    list[pageIdx].annotationList[aIdx] = Object.assign({}, { ...res.data[0], selected: 1 })
                  }
                })
              }
            }
          })
          this.rightAnnotationList = JSON.parse(JSON.stringify(list))
        }
        this.showAnnotationList.forEach(page => {
          if (page.pageId == data.pageId) {
            page.annotationList.forEach((i, idx) => {
              if (i.id == res.data[0].id) {
                page.annotationList[idx] = res.data[0]
                page.annotationList[idx].postValue = ''
              }
            })
          }
        })
        this.showAnnotationList.sort((a, b) => a.idx - b.idx)
        /* this.getLocalList() */
        this.showImgHandleImgClick()
        if (this.$refs.showImgContent) {
          this.$refs.showImgContent.editorOnsize()
        }
        if (this.$refs.contentList) {
          this.$refs.contentList.editorOnsize()
        }
        this.$forceUpdate()
      } else {
        this.$message.error(res.msg || res.errorMsg)
      }
    },
    toStageChange: simpleDebounce(async function(data) {
      this.toStageChangeSim(data)
    }, 200),
    async toStageChangeSim(data) {
      let params = [
        {
          id: data.id || null,
          position: data.position,
          commentData: data.commentData,
          size: data.size,
          toStage: data.toStage,
          chapterId: this.taskDetail.chapterId,
          pageId: data.pageId,
          productionId: this.taskDetail.productionId,
          around: data.around
        }
      ]
      const res = await postAction('/annotation/edit_annotation', params)
      if (res.code == 200) {
        /* this.$message.success('保存成功') */
        if (data.around == 'left') {
          let list = this.leftAnnotationList
          list.forEach((page, pageIdx) => {
            if (page.pageId == data.pageId) {
              if (page.annotationList.every(i => i.id != res.data[0].id)) {
                page.annotationList.push({ ...res.data[0], selected: 1 })
                let deleteIdx = -1
                page.annotationList.forEach((a, aIdx) => {
                  if (!a.id) {
                    deleteIdx = aIdx
                  }
                })
                if (deleteIdx > -1) {
                  page.annotationList.splice(deleteIdx, 1)
                }
              } else {
                page.annotationList.forEach((i, aIdx) => {
                  if (i.id == res.data[0].id) {
                    list[pageIdx].annotationList[aIdx] = Object.assign({}, { ...res.data[0], selected: 1 })
                  }
                })
              }
            }
          })
          this.leftAnnotationList = JSON.parse(JSON.stringify(list))
        } else {
          let list = this.rightAnnotationList
          list.forEach((page, pageIdx) => {
            if (page.pageId == data.pageId) {
              if (page.annotationList.every(i => i.id != res.data[0].id)) {
                page.annotationList.push({ ...res.data[0], selected: 1 })
                let deleteIdx = -1
                page.annotationList.forEach((a, aIdx) => {
                  if (!a.id) {
                    deleteIdx = aIdx
                  }
                })
                if (deleteIdx > -1) {
                  page.annotationList.splice(deleteIdx, 1)
                }
              } else {
                page.annotationList.forEach((i, aIdx) => {
                  if (i.id == res.data[0].id) {
                    list[pageIdx].annotationList[aIdx] = Object.assign({}, { ...res.data[0], selected: 1 })
                  }
                })
              }
            }
          })
          this.rightAnnotationList = JSON.parse(JSON.stringify(list))
        }
        this.showAnnotationList.forEach(page => {
          if (page.pageId == data.pageId) {
            page.annotationList.forEach((i, idx) => {
              if (i.id == res.data[0].id) {
                page.annotationList[idx] = res.data[0]
              }
            })
          }
        })
        this.showAnnotationList.sort((a, b) => a.idx - b.idx)
        /* this.getLocalList() */
        this.showImgHandleImgClick()
        this.$forceUpdate()
      } else {
        this.$message.error(res.msg || res.errorMsg)
      }
    },
    saveLocal: simpleDebounce(async function(data) {
      this.saveLocalSim(data)
    }, 200),
    async saveLocalSim(data) {
      let params = [
        {
          id: data.item.id || null,
          position: data.item.position,
          commentData: [
            {
              id: data.localId || null,
              content: data.value,
              productionchapterPageAnnotationId: data.item.id || null
            }
          ],
          size: data.item.size,
          toStage: data.item.toStage,
          chapterId: this.taskDetail.chapterId,
          pageId: data.item.pageId,
          productionId: this.taskDetail.productionId,
          around: data.type
        }
      ]
      const res = await postAction('/annotation/edit_annotation', params)
      if (res.code == 200) {
        /* this.$message.success('保存成功') */
        if (data.type == 'left') {
          let list = this.leftAnnotationList
          list.forEach((page, pageIdx) => {
            if (page.pageId == data.item.pageId) {
              if (page.annotationList.every(i => i.id != res.data[0].id)) {
                page.annotationList.push({ ...res.data[0], selected: 1 })
                let deleteIdx = -1
                page.annotationList.forEach((a, aIdx) => {
                  if (!a.id) {
                    deleteIdx = aIdx
                  }
                })
                if (deleteIdx > -1) {
                  page.annotationList.splice(deleteIdx, 1)
                }
              } else {
                page.annotationList.forEach((i, aIdx) => {
                  if (i.id == res.data[0].id) {
                    list[pageIdx].annotationList[aIdx] = Object.assign({}, { ...res.data[0], selected: 1 })
                  }
                })
              }
            }
          })
          this.leftAnnotationList = JSON.parse(JSON.stringify(list))
        } else {
          let list = this.rightAnnotationList
          list.forEach((page, pageIdx) => {
            if (page.pageId == data.item.pageId) {
              if (page.annotationList.every(i => i.id != res.data[0].id)) {
                page.annotationList.push({ ...res.data[0], selected: 1 })
                let deleteIdx = -1
                page.annotationList.forEach((a, aIdx) => {
                  if (!a.id) {
                    deleteIdx = aIdx
                  }
                })
                if (deleteIdx > -1) {
                  page.annotationList.splice(deleteIdx, 1)
                }
              } else {
                page.annotationList.forEach((i, aIdx) => {
                  if (i.id == res.data[0].id) {
                    list[pageIdx].annotationList[aIdx] = Object.assign({}, { ...res.data[0], selected: 1 })
                  }
                })
              }
            }
          })
          this.rightAnnotationList = JSON.parse(JSON.stringify(list))
        }
        this.showAnnotationList.forEach(page => {
          if (page.pageId == data.item.pageId) {
            page.annotationList.forEach((i, idx) => {
              if (i.id == res.data[0].id) {
                page.annotationList[idx] = res.data[0]
              }
            })
          }
        })
        this.showAnnotationList.sort((a, b) => a.idx - b.idx)
        /* this.getLocalList() */
        this.showImgHandleImgClick()
        this.$forceUpdate()
      } else {
        this.$message.error(res.msg || res.errorMsg)
      }
    },
    onActivatedAnnotation(isDragText) {
      this.isDragText = isDragText
      this.isAutoSave = false
      this.showImgHandleImgClick()
    },
    changeRightAnnotationList(data) {
      this.rightAnnotationList.forEach(item => {
        item.annotationList?.forEach(i => {
          i.selected = 0
        })
      })
      this.rightAnnotationList[data.idx].annotationList.push({
        position: data.position,
        pageId: this.rightAnnotationList[data.idx].pageId,
        selected: 1,
        size: 30,
        commentData: [],
        toStage: '翻译环节'
      })
      let timeOut = setTimeout(() => {
        this.isAddAnnotation = false
        clearTimeout(timeOut)
      }, 0)
    },
    changeLeftAnnotationList(data) {
      this.leftAnnotationList.forEach(item => {
        item.annotationList?.forEach(i => {
          i.selected = 0
        })
      })
      this.leftAnnotationList[data.idx].annotationList.push({
        position: data.position,
        pageId: this.leftAnnotationList[data.idx].pageId,
        selected: 1,
        size: 30,
        commentData: []
      })
      this.isAddAnnotation = false
    },
    selectTextAreaDataChange: simpleDebounce(async function() {
      this.selectTextAreaDataChangeSim()
    }, 200),
    selectTextAreaDataChangeSim() {
      if (this.$refs.centerButtonList && this.selectTextAreaData.length) {
        for (let key in this.$refs.centerButtonList.textBatchStyle) {
          this.selectTextAreaData.forEach(textAreaData => {
            if (textAreaData.characterSentences.length) {
              if (
                textAreaData.characterSentences.every(item => item[key] == textAreaData.characterSentences[0][key]) &&
                key != 'textLayout' &&
                key != 'fontFamily'
              ) {
                if (key != 'strokeWidth') {
                  this.$refs.centerButtonList.textBatchStyle[key] =
                    textAreaData.characterSentences[0][key] || this.$refs.centerButtonList.textBatchStyle[key]
                } else {
                  this.$refs.centerButtonList.textBatchStyle[key] =
                    textAreaData.characterSentences[0][key] >= 0
                      ? textAreaData.characterSentences[0][key]
                      : this.$refs.centerButtonList.textBatchStyle[key]
                }
              }
              if ((key == 'isIncline' || key == 'hasMark' || key == 'isBold') && !this.phoneticTexts.length) {
                if (textAreaData.characterSentences?.some(text => text[key] > 0)) {
                  this.$refs.centerButtonList.textBatchStyle[key] = 1
                } else {
                  this.$refs.centerButtonList.textBatchStyle[key] = 0
                }
                if (this.phoneticTexts.length) {
                  if (key == 'hasMark' || key == 'isIncline') {
                    this.$refs.centerButtonList.textBatchStyle[key] = this.phoneticTexts[0][key]
                  }
                }
              }
              if (
                this.$refs.centerButtonList.fontSizes.every(
                  item => item.value != this.$refs.centerButtonList.textBatchStyle.fontSize
                )
              ) {
                this.$refs.centerButtonList.fontSizes.push({
                  value: this.$refs.centerButtonList.textBatchStyle.fontSize,
                  label: (this.$refs.centerButtonList.textBatchStyle.fontSize - 1) / 4
                })
              }
              this.$refs.centerButtonList.textBatchStyle.textLayout = textAreaData.textLayout
              this.$refs.centerButtonList.textBatchStyle.backgroundColor = textAreaData.backgroundColor
              this.$refs.centerButtonList.textBatchStyle.fontFamily = textAreaData.characterSentences[0].fontFamily
              if (this.fontFamilyList.find(item => item.fontFamily == textAreaData.characterSentences[0].fontFamily)) {
                this.$refs.centerButtonList.textBatchStyle.fontFamilyId = this.fontFamilyList.find(
                  item => item.fontFamily == textAreaData.characterSentences[0].fontFamily
                ).id
                this.$refs.centerButtonList.textBatchStyle.styleName = this.fontFamilyList.find(
                  item => item.fontFamily == textAreaData.characterSentences[0].fontFamily
                ).styleName
              }
              this.$refs.centerButtonList.textBatchStyle.textAreaType = textAreaData.textAreaType - 0
              if (!this.$refs.centerButtonList.textBatchStyle.backgroundColor)
                this.$refs.centerButtonList.textBatchStyle.backgroundColor = this.$refs.centerButtonList.oldTextBatchStyle.backgroundColor
              this.defualtTextType = this.textTypes?.find(t => t.sequenceNo == textAreaData.textAreaType)
            }
          })
          if (this.selectTextAreaData.some(item => item.textAreaType != this.selectTextAreaData[0].textAreaType)) {
            this.defualtTextTypeNo = '-1'
          } else {
            if (this.textTypes?.find(item => this.selectTextAreaData[0].textAreaType == item.sequenceNo)) {
              this.defualtTextTypeNo = this.textTypes?.find(
                item => this.selectTextAreaData[0].textAreaType == item.sequenceNo
              ).sequenceNo
            }
            if (this.globalSetting) {
              if (
                this.defualtTextType &&
                this.defualtTextType.fontFamilys?.every(
                  item => item.fontFamily != this.$refs.centerButtonList.textBatchStyle.fontFamily
                )
              ) {
                this.defualtTextTypeNo = '-1'
              }
            }
          }
        }
        let textType = null
        if (
          this.textTypes &&
          this.textTypes.length &&
          this.textTypes.find(
            textType => textType.sequenceNo == this.$refs.centerButtonList.textBatchStyle.textAreaType
          )
        ) {
          textType = this.textTypes.find(
            textType => textType.sequenceNo == this.$refs.centerButtonList.textBatchStyle.textAreaType
          )
        }
        if (textType) {
          this.$refs.centerButtonList.textBatchStyle.fontColor = textType.fontColor || '#000000'
        }
      }
    },
    clearCopyContent() {
      if (this.$refs.showImgContent) {
        this.$refs.showImgContent.copyContent = ''
        localStorage.setItem('translate_copyContent', this.$refs.showImgContent.copyContent)
        this.$message.success('已清空')
      }
    },
    changeIsAddText() {
      this.isAddText = !this.isAddText
      this.isAddAnnotation = false
      this.$nextTick(() => {
        this.rePositionDragBox()
      })
    },
    changeIsAddTwo() {
      if (!this.isAddText && this.selectTextAreaData.length) {
        this.copyData()
        this.savePageList()
      }
    },
    changeIsAddAnnotation() {
      this.isAddText = false
      this.isAddAnnotation = !this.isAddAnnotation
      this.copyData()
      this.savePageList()
    },
    resizeChange() {
      this.isAddAnnotation = !this.isAddAnnotation
    },
    addEmj(emj) {
      this.selectTextAreaData.forEach(item => {
        let addText = null
        let idx = -1
        if (this.anchorNode.id) {
          item.characterSentences.forEach((text, index) => {
            if (text.characterSentenceId == this.anchorNode.id) {
              addText = {
                ...this.$refs.centerButtonList.oldTextBatchStyle,
                characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                characterText: emj
              }
              idx = index
            }
          })
        } else {
          item.characterSentences.push({
            ...this.$refs.centerButtonList.oldTextBatchStyle,
            characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
            characterText: emj
          })
        }
        if (addText && idx > -1) {
          if (this.anchorNode.isBefore) {
            item.characterSentences.splice(idx, 0, addText)
          } else {
            item.characterSentences.splice(idx + 1, 0, addText)
          }
          let pArr = []
          let brIds = []
          item.showTexts = []
          item.characterSentences?.forEach((t, pushTtextIndex) => {
            if (t.characterText != '<br>' && t.characterText != '\n') {
              pArr.push(t)
            } else {
              brIds.push(t.characterSentenceId)
              if (pArr.length > 1) {
                /* pArr = pArr.filter(pt => pt.characterText != ' ') */
              }
              if (!pArr.length) {
                pArr.push({
                  ...this.oldTextBatchStyle,
                  textAlign: this.textBatchStyle.textAlign,
                  fontFamily: this.textBatchStyle.fontFamily,
                  characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                  characterText: ' '
                })
              }
              item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
              pArr = []
            }
            if (pushTtextIndex == item.characterSentences.length - 1 && pArr.length) {
              item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
            }
          })
          if (!item.showTexts.length)
            item.showTexts.push({
              pId: '',
              texts: [
                {
                  ...this.oldTextBatchStyle,
                  textAlign: this.textBatchStyle.textAlign,
                  fontFamily: this.textBatchStyle.fontFamily,
                  characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                  characterText: ' '
                }
              ],
              pushTtextIndex: 0
            })
          brIds.forEach((brId, brPushIdx) => {
            if (item.showTexts[brPushIdx + 1]) {
              item.showTexts[brPushIdx + 1].pId = brId
            }
          })
          item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
          item.showText = item.characterSentences
            .map(c => c.characterText)
            .join('')
            .replaceAll('<br>', '\n')
        }
      })
      this.anchorNode = null
      this.copyData()
      this.savePageList()
    },
    openEmj() {
      if (this.anchorNode) {
        this.$refs.emjModal.open()
      } else {
        this.$message.error('未获取到光标位置')
      }
    },
    openSearch() {
      this.$refs.searchModal.open()
    },
    openNote() {
      this.$refs.termNoteModal.openModal()
    },
    addTerm() {
      this.$refs.termModal.open()
    },
    addTermBack() {
      if (!this.selectTextAreaData.length) return this.$message.warning('请选中文本框')
      this.$refs.termBackModal.open()
    },
    clearPhoneticTexts() {
      this.phoneticTexts = []
      /* this.$refs.centerButtonList.textBatchStyle = JSON.parse(
        JSON.stringify(this.$refs.centerButtonList.oldTextBatchStyle)
      ) */
    },
    changeSelectTexts(arr) {
      this.phoneticTexts = arr
      if (this.$refs.centerButtonList && this.phoneticTexts.length) {
        for (let key in this.$refs.centerButtonList.textBatchStyle) {
          if (key != 'textLayout' && key != 'fontFamily') {
            this.$refs.centerButtonList.textBatchStyle[key] =
              this.phoneticTexts[0][key] || this.$refs.centerButtonList.textBatchStyle[key]
            if (key == 'hasMark' || key == 'isIncline' || key == 'isBold') {
              this.$refs.centerButtonList.textBatchStyle[key] = this.phoneticTexts.find(t => t[key])
                ? this.phoneticTexts.find(t => t[key])[key]
                : 0
            }
          }
          if (
            this.$refs.centerButtonList.fontSizes.every(
              item => item.value != this.$refs.centerButtonList.textBatchStyle.fontSize
            )
          ) {
            this.$refs.centerButtonList.fontSizes.push({
              value: this.$refs.centerButtonList.textBatchStyle.fontSize,
              label: (this.$refs.centerButtonList.textBatchStyle.fontSize - 1) / 4
            })
          }
          this.$refs.centerButtonList.textBatchStyle.fontFamily = this.phoneticTexts[0].fontFamily
          if (this.fontFamilyList.find(item => item.fontFamily == this.phoneticTexts[0].fontFamily)) {
            this.$refs.centerButtonList.textBatchStyle.fontFamilyId = this.fontFamilyList.find(
              item => item.fontFamily == this.phoneticTexts[0].fontFamily
            ).id
            this.$refs.centerButtonList.textBatchStyle.styleName = this.fontFamilyList.find(
              item => item.fontFamily == this.phoneticTexts[0].fontFamily
            ).styleName
          }
        }
      }
      /* console.log('选中文字', this.phoneticTexts, this.$refs.centerButtonList.textBatchStyle) */
    },
    openPhonetic() {
      //不考虑换行注音的情况
      if (this.selectTextAreaData.length > 1) {
        this.$message.error('请选中单个文本框进行注音')
        return
      }
      if (!this.selectTextAreaData.length && !this.phoneticTexts.length) {
        this.$message.error('请选中文字或文本框')
      } else if (this.phoneticTexts.length) {
        if (
          this.phoneticTexts.some(
            text => text.characterSentenceId && toString(text.characterSentenceId).indexOf('new') > -1
          )
        ) {
          return this.$message.error('请保存文本后注音')
        }
        this.isOpenPhoneticModal = true
        this.$refs.phoneticModal.open(this.phoneticTexts, 'multiple', this.selectTextAreaData)
      } else if (!this.phoneticTexts.length && this.selectTextAreaData.length) {
        let textArr = []
        this.selectTextAreaData.forEach(item => {
          textArr = [...textArr, ...item.characterSentences]
        })
        if (!textArr.filter(text => this.getCharacterType(text.characterText) == '汉字').length)
          return this.$message.error('无注音内容')
        if (textArr.some(text => text.characterSentenceId && toString(text.characterSentenceId).indexOf('new') > -1))
          return this.$message.error('请保存文本后注音')
        this.isOpenPhoneticModal = true
        this.$refs.phoneticModal.open(textArr, 'single')
      }
    },
    getCharacterType(character) {
      const hiraganaRegex = /hiragana/ // 平假名正则表达式
      const katakanaRegex = /katakana/ // 片假名正则表达式
      const hanziRegex = /[\u4e00-\u9fff]|[\u3400-\u4dbf]|[\u2e80-\u2eff]|[\u3190-\u319f]|[\ufe30-\ufe4f]|[\uf900-\ufd3d]|[\u2f00-\u2fdf]/g // 汉字正则表达式

      if (hiraganaRegex.test(character)) {
        return '平假名'
      } else if (katakanaRegex.test(character)) {
        return '片假名'
      } else if (hanziRegex.test(character)) {
        return '汉字'
      } else {
        return '其他字符'
      }
    },
    savePhonetic(textArr) {
      this.selectTextAreaData.forEach(item => {
        item.brIndexs = []
        item.pIndexs = []
        item.characterSentences.forEach((t, pushTtextIndex) => {
          textArr.forEach(text => {
            if (t.characterSentenceId == text.characterSentenceId) {
              t.phonetic = text.phonetic
              if (t.phonetic && !t.phonetic.phoneticText) {
                t.phonetic = null
              }
            }
          })
          if (
            item.characterSentences[pushTtextIndex + 1] &&
            item.characterSentences[pushTtextIndex + 1].phonetic &&
            item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
          ) {
            item.pIndexs.push(pushTtextIndex)
          }
          if (t.characterText == '<br>') item.brIndexs.push(pushTtextIndex)
        })
      })
      this.isOpenPhoneticModal = false
      this.$forceUpdate()
      if (this.$refs.showImgContent) {
        this.$refs.showImgContent.rightImgShow = false
        let timeOut = setTimeout(() => {
          this.$refs.showImgContent.rightImgShow = true
          clearTimeout(timeOut)
        }, 0)
      }
      this.copyData()
      this.savePageList()
    },
    getFontFamily(departId) {
      getAction('/fonts/list', { current: '1', size: '-1', pageSize: '1000', pageNo: '1', fieId: 'fontFamily' }).then(
        res => {
          if (res.success) {
            this.fontFamilyList = res.data.records.filter(item => item.departments.some(d => d.departId == departId))
            let fontFamilyList = JSON.parse(JSON.stringify(this.fontFamilyList))
            fontFamilyList.forEach(item => {
              item.fontFamily = 'fontCssView' + item.fontFamily
            })
            batchStyleInsertHeader(fontFamilyList || res.data)
            let timeOut = setTimeout(() => {
              if (this.$refs.showImgContent) this.$refs.showImgContent.$forceUpdate()
              clearTimeout(timeOut)
            }, 0)
          }
          if (res.code === 510) {
            this.$message.warning(res.message)
          }
        }
      )
    },
    textTypeBatchChange(textBatchStyle) {
      this.isCenterClick = false
      this.$refs.centerButtonList.textBatchStyle.textAreaType = textBatchStyle.textAreaType - 0
      this.defualtTextType = this.textTypes.find(t => t.sequenceNo == textBatchStyle.textAreaType)
      let fontFamily = this.fontFamilyList.filter(
        font => this.defualtTextType && this.defualtTextType.fontFamilys?.some(item => item.id == font.id)
      )[0]
      let textType = null
      if (this.textTypes.find(textType => textType.sequenceNo == textBatchStyle.textAreaType)) {
        textType = this.textTypes.find(textType => textType.sequenceNo == textBatchStyle.textAreaType)
      }
      if (fontFamily) {
        this.$refs.centerButtonList.textBatchStyle.fontFamilyId = fontFamily.id
        this.$refs.centerButtonList.textBatchStyle.fontFamily = fontFamily.fontFamily
      }

      if (textType) {
        this.$refs.centerButtonList.textBatchStyle.isBold = textType.isBold || 0
        this.$refs.centerButtonList.oldTextBatchStyle.isBold = textType.isBold || 0
      }
      if (fontFamily) {
        this.selectTextAreaData.forEach(item => {
          item.characterSentences.forEach(text => {
            text.fontFamily = fontFamily.fontFamily
            if (textType) {
              text.isBold = textType.isBold || 0
              text.fontColor = textType.fontColor || '#000000'
            }
          })
          item.textAreaType = textBatchStyle.textAreaType - 0
        })
        this.$refs.centerButtonList.textBatchStyle.fontColor = textType.fontColor || '#000000'
      }
      this.copyData()
      this.savePageList()
    },
    textTypeBatchChangeNo(textBatchStyle) {
      this.$refs.centerButtonList.textBatchStyle.textAreaType = textBatchStyle.textAreaType - 0
      this.defualtTextType = this.textTypes.find(t => t.sequenceNo == textBatchStyle.textAreaType)
      let fontFamily = this.fontFamilyList.filter(
        font => this.defualtTextType && this.defualtTextType.fontFamilys?.some(item => item.id == font.id)
      )[0]
      let textType = null
      if (this.textTypes.find(textType => textType.sequenceNo == textBatchStyle.textAreaType)) {
        textType = this.textTypes.find(textType => textType.sequenceNo == textBatchStyle.textAreaType)
      }
      if (fontFamily) {
        this.$refs.centerButtonList.textBatchStyle.fontFamilyId = fontFamily.id
        this.$refs.centerButtonList.textBatchStyle.fontFamily = fontFamily.fontFamily
      }

      /*       if (textType) {
        this.$refs.centerButtonList.textBatchStyle.isBold = textType.isBold || 0
        this.$refs.centerButtonList.oldTextBatchStyle.isBold = textType.isBold || 0
      } */
      if (fontFamily) {
        this.selectTextAreaData.forEach(item => {
          item.textAreaType = textBatchStyle.textAreaType - 0
        })
      }

      this.copyData()
      this.savePageList()
    },
    textBatchChange(textBatchStyle) {
      if (!this.phoneticTexts.length) {
        this.selectTextAreaData.forEach(item => {
          item.characterSentences.forEach(text => {
            text.fontSize = textBatchStyle.fontSize || text.fontSize
            text.fontColor = textBatchStyle.fontColor || text.fontColor || '#000000'
            text.strokeColor = textBatchStyle.strokeColor || text.strokeColor
            text.strokeWidth = textBatchStyle.strokeWidth >= 0 ? textBatchStyle.strokeWidth : text.strokeWidth
            text.fontFamily = textBatchStyle.fontFamily || text.fontFamily
            text.isBold = textBatchStyle.isBold > -1 ? textBatchStyle.isBold : text.isBold
            if (
              textBatchStyle.hasMark > -1 &&
              text.characterText != ' ' &&
              text.characterText != '' &&
              text.characterText != '\r' &&
              text.characterText != '<br>'
            ) {
              text.hasMark = textBatchStyle.hasMark > -1 ? textBatchStyle.hasMark : text.hasMark
            }
            if (text.phonetic) {
              text.phonetic.fontSize = ((textBatchStyle.fontSize || text.fontSize) - 1) / 2 + 1
            }
            text.isIncline = textBatchStyle.isIncline > -1 ? textBatchStyle.isIncline : text.isIncline
            text.textAlign = textBatchStyle.textAlign || text.textAlign
            if (textBatchStyle.textLayout) {
              text.phonetic = null
            }
          })
          if (textBatchStyle.textAreaType) item.textAreaType = textBatchStyle.textAreaType - 0
          if (textBatchStyle.backgroundColor) item.backgroundColor = textBatchStyle.backgroundColor
          if (textBatchStyle.textLayout) {
            item.textLayout = textBatchStyle.textLayout
          }
        })
      } else {
        this.phoneticTexts.forEach(text => {
          text.fontSize = textBatchStyle.fontSize || text.fontSize
          text.fontColor = textBatchStyle.fontColor || text.fontColor || '#000000'
          text.strokeColor = textBatchStyle.strokeColor || text.strokeColor
          text.strokeWidth = textBatchStyle.strokeWidth >= 0 ? textBatchStyle.strokeWidth : text.strokeWidth
          text.fontFamily = textBatchStyle.fontFamily || text.fontFamily
          if (this.editType == 'isBold') {
            text.isBold = text.isBold ? 0 : 1
          }
          if (text.phonetic) {
            text.phonetic.fontSize = (text.fontSize - 1) / 2 + 1
          }
          if (
            textBatchStyle.hasMark > -1 &&
            text.characterText != ' ' &&
            text.characterText != '' &&
            text.characterText != '<br>'
          ) {
            text.hasMark = textBatchStyle.hasMark > -1 ? textBatchStyle.hasMark : text.hasMark
          }
          /* text.isIncline = textBatchStyle.isIncline > -1 ? textBatchStyle.isIncline : text.isIncline */
          if (this.editType == 'isIncline') {
            text.isIncline = text.isIncline ? 0 : 1
          }
          text.textAlign = textBatchStyle.textAlign || text.textAlign
        })
      }
      this.isCenterClick = true
      this.editType = ''
      this.copyData()
      this.savePageList()
    },
    async getTextStyle() {
      if (!this.globalSetting) {
        const res = await getAction('/production/get_guide', {
          id: this.taskDetail.productionId,
          current: 1,
          size: -1,
          typeId: 1
        })
        if (res.code == 200) {
          if (res.data.records.find(item => item.classId == 4)) {
            this.defualtTextType = res.data.records.find(item => item.classId == 4).fonts[0]
            this.textTypes = res.data.records.find(item => item.classId == 4).fonts
            this.defualtTextTypeNo = this.textTypes[0].sequenceNo
            this.$refs.centerButtonList.textBatchStyle.isBold =
              res.data.records.find(item => item.classId == 4).fonts[0].isBold || 0
            this.$refs.centerButtonList.textBatchStyle.fontColor =
              res.data.records.find(item => item.classId == 4).fonts[0].fontColor || '#000000'
          } else {
            this.$message.error('未设置文本类型')
          }
          if (this.$refs.centerButtonList) {
            if (!res.data.records.find(item => item.classId == 1)) {
              this.$message.error('未设置通用字符')
              return
            }
            let styleItem = res.data.records.find(item => item.classId == 1).styleItem
            /* this.$refs.centerButtonList.textBatchStyle.fontColor = styleItem.fontColor */
            this.$refs.centerButtonList.textBatchStyle.strokeColor = styleItem.strokeColor || 'rgb(0,0,0)'
            this.$refs.centerButtonList.textBatchStyle.backgroundColor = styleItem.backgroundColor || '#fff'
            this.$refs.centerButtonList.textBatchStyle.strokeWidth = styleItem.strokeWidth || 0
            this.$refs.centerButtonList.textBatchStyle.fontSize = styleItem.fontSize
            this.$refs.centerButtonList.textBatchStyle.lineSpacing = styleItem.lineSpacing || styleItem.lineHeight
            this.$refs.centerButtonList.textBatchStyle.textHeight = styleItem.textHeight || styleItem.scaleY
            this.$refs.centerButtonList.textBatchStyle.textWidth = styleItem.textWidth || styleItem.scaleX
            this.$refs.centerButtonList.textBatchStyle.outlineColor = styleItem.outlineColor
            this.$refs.centerButtonList.textBatchStyle.outlineWidth = styleItem.outlineWidth
            this.$refs.centerButtonList.textBatchStyle.scaleX = styleItem.scaleX
            this.$refs.centerButtonList.textBatchStyle.scaleY = styleItem.scaleY
            this.$refs.centerButtonList.textBatchStyle.textAlign = styleItem.textAlign || 'left'
            this.$refs.centerButtonList.textBatchStyle.hasMark = styleItem.hasMark || 0
            this.$refs.centerButtonList.textBatchStyle.isIncline = styleItem.isIncline || 0
            this.$refs.centerButtonList.textBatchStyle.textLayout = res.data.records.find(
              item => item.classId == 1
            ).textLayout
            this.$refs.centerButtonList.textBatchStyle.fontFamily = res.data.records.find(
              item => item.classId == 4
            ).fonts[0].fontFamilys[0].fontFamily
            this.$refs.centerButtonList.textBatchStyle.fontFamilyId = res.data.records.find(
              item => item.classId == 4
            ).fonts[0].fontFamilys[0].id
            this.$refs.centerButtonList.textBatchStyle.styleName = res.data.records.find(
              item => item.classId == 4
            ).fonts[0].fontFamilys[0].styleName
            this.$nextTick(() => {
              if (
                this.$refs.centerButtonList.fontSizes.every(
                  item => item.value != this.$refs.centerButtonList.textBatchStyle.fontSize
                )
              ) {
                this.$refs.centerButtonList.fontSizes.push({
                  value: this.$refs.centerButtonList.textBatchStyle.fontSize,
                  label: (this.$refs.centerButtonList.textBatchStyle.fontSize - 1) / 4
                })
              }
              this.$refs.centerButtonList.oldTextBatchStyle = JSON.parse(
                JSON.stringify(this.$refs.centerButtonList.textBatchStyle)
              )
            })
            /*           if (!res.data.records.find(item => item.classId == 15) && this.taskDetail.targetLang == 'JAPANESE') {
            this.$message.error('未设置注音')
            return
          }
          if (res.data.records.find(item => item.classId == 15)) {
            let phoneticStyle = res.data.records.find(item => item.classId == 15).phoneticStyle
            this.$refs.centerButtonList.phoneticStyle.fontColor = phoneticStyle.fontColor
            this.$refs.centerButtonList.phoneticStyle.fontSize = phoneticStyle.fontSize
            this.$refs.centerButtonList.phoneticStyle.lineHeight = phoneticStyle.lineHeight
            this.$refs.centerButtonList.phoneticStyle.scaleX = phoneticStyle.scaleX
            this.$refs.centerButtonList.phoneticStyle.scaleY = phoneticStyle.scaleY
            this.$refs.centerButtonList.phoneticStyle.textAlign = phoneticStyle.textAlign
          } */
          }
        } else {
          this.$message.error(res.msg || res.errorMsg)
        }
        this.isDeleteLoading = false
      } else {
        const request = await getAction('/production/get_guide', {
          id: this.taskDetail.productionId,
          current: 1,
          size: -1,
          typeId: 1
        })
        const rest = await getAction('/guide/list', {
          current: 1,
          fieId: 'guidName',
          size: -1,
          typeId: 1
        })
        this.defualtTextType = rest.data.records.find(item => item.classId == 4).fonts[0]
        this.textTypes = request.data.records.find(item => item.classId == 4).fonts
        this.defualtTextTypeNo = this.textTypes[0].sequenceNo
        const res = await postAction('/global/setting/page', {
          productionId: this.taskDetail.productionId,
          chapterId: this.taskDetail.chapterId,
          current: 1,
          size: -1,
          typeId: 1
        })
        if (res.code == 200) {
          if (this.$refs.centerButtonList) {
            let styleItem = res.data.records[0]
            this.globalSettingId = res.data.records[0].id
            this.$refs.centerButtonList.textBatchStyle.fontColor = styleItem.fontColor || '#000000'
            this.$refs.centerButtonList.textBatchStyle.strokeColor = styleItem.strokeColor || 'rgb(0,0,0)'
            this.$refs.centerButtonList.textBatchStyle.backgroundColor = styleItem.backgroundColor || '#fff'
            this.$refs.centerButtonList.textBatchStyle.strokeWidth = styleItem.strokeWidth || 0
            this.$refs.centerButtonList.textBatchStyle.fontSize = styleItem.fontSize
            this.$refs.centerButtonList.textBatchStyle.lineSpacing = styleItem.lineSpacing || styleItem.lineHeight
            this.$refs.centerButtonList.textBatchStyle.textHeight = styleItem.textHeight || styleItem.scaleY
            this.$refs.centerButtonList.textBatchStyle.textWidth = styleItem.textWidth || styleItem.scaleX
            this.$refs.centerButtonList.textBatchStyle.outlineColor = styleItem.outlineColor
            this.$refs.centerButtonList.textBatchStyle.outlineWidth = styleItem.outlineWidth
            this.$refs.centerButtonList.textBatchStyle.scaleX = styleItem.scaleX || styleItem.textWidth
            this.$refs.centerButtonList.textBatchStyle.scaleY = styleItem.scaleY || styleItem.textHeight
            this.$refs.centerButtonList.textBatchStyle.textAlign = styleItem.textAlign || 'left'
            this.$refs.centerButtonList.textBatchStyle.isBold = styleItem.isBold || 0
            this.$refs.centerButtonList.textBatchStyle.hasMark = styleItem.hasMark || 0
            this.$refs.centerButtonList.textBatchStyle.isIncline = styleItem.isIncline || 0
            this.$refs.centerButtonList.textBatchStyle.textLayout = styleItem.textLayout
            this.$refs.centerButtonList.textBatchStyle.fontFamily = styleItem.fontFamily
            this.$refs.centerButtonList.textBatchStyle.fontFamilyId = styleItem.fontFamilyId
            this.$refs.centerButtonList.textBatchStyle.styleName = styleItem.styleName
            this.$nextTick(() => {
              if (
                this.$refs.centerButtonList.fontSizes.every(
                  item => item.value != this.$refs.centerButtonList.textBatchStyle.fontSize
                )
              ) {
                this.$refs.centerButtonList.fontSizes.push({
                  value: this.$refs.centerButtonList.textBatchStyle.fontSize,
                  label: (this.$refs.centerButtonList.textBatchStyle.fontSize - 1) / 4
                })
              }
              this.$refs.centerButtonList.oldTextBatchStyle = JSON.parse(
                JSON.stringify(this.$refs.centerButtonList.textBatchStyle)
              )
            })
          }
        } else {
          this.$message.error(res.msg || res.errorMsg)
        }
        this.isDeleteLoading = false
      }
      const guideRes = await getAction('/guide/list', {
        current: 1,
        fieId: 'guidName',
        size: -1,
        typeId: 4
      })
      let arr = guideRes.data.records.filter(item => item.classId == 16)
      if (arr) {
        this.isDepart = arr.some(a => a.platforms.some(d => this.taskDetail.platformId == d.platformId))
      }
    },
    topCenterMouseover() {
      this.isCenterClick = true
    },
    changeAnchorNodeBefore() {
      this.isAnchorNodeBefore = true
    },
    changeAnchorNode(node) {
      if (node) {
        this.anchorNode = { id: node.id.split('-')[2] || node.id.split('-')[0] }
        if (this.isAnchorNodeBefore) {
          this.anchorNode.isBefore = true
        }
        this.isAnchorNodeBefore = false
      }
    },
    clearAnchorNode() {
      if (this.isCenterClick) return
      this.anchorNode = null
    },
    topCenterMouseout() {
      this.isCenterClick = false
    },
    changeShowImgListWidth(value) {
      let scrollTop =
        (document.getElementsByClassName('show-img-bottom')[0].scrollTop / (this.showImgListWidth.split('%')[0] - 0)) *
        100
      this.showImgListWidth = value
      document.getElementsByClassName('show-img-bottom')[0].scrollTop =
        scrollTop * ((this.showImgListWidth.split('%')[0] - 0) / 100)
      this.$nextTick(() => {
        this.showImgWidth = this.$refs.showImg.clientWidth
        this.optionListWidth = this.$refs.optionList.clientWidth
        this.getPageListPostions()
        if (this.$refs.showImgContent) {
          this.$refs.showImgContent.allUpload()
        }
      })
    },
    async backData() {
      if (this.isSaved) return
      this.saveIndex--
      if (this.changeProcess[this.saveIndex]) {
        let changeData = null
        let oldData = JSON.parse(JSON.stringify(this.pageListData))
        let newData = JSON.parse(JSON.stringify(this.changeProcess[this.saveIndex]))
        changeData = this.diffData(oldData, newData, 'back')
        if (changeData) {
          this.selectIdx = changeData.changeIdx - 0
        } else {
          this.selectIdx = 0
        }
        let num = this.showImgLeftListPostions[this.selectIdx].width / this.pageListData[this.selectIdx].width - 0
        let top = (changeData.textAreaRegion.y * num).toFixed(0) - 0
        let height = (changeData.textAreaRegion.height * num).toFixed(0) - 0
        document.getElementsByClassName('show-img-bottom')[0].scrollTop =
          this.showImgLeftListPostions.find(item => item.idx == this.selectIdx).top - 0 + top - height - 200
        this.pageListData = JSON.parse(JSON.stringify(this.changeProcess[this.saveIndex]))
        if (changeData.isDelete) {
          if ((changeData.id + '').indexOf('new') > -1) return
          const res = await v1DeleteAction('/textarea/delete/' + changeData.id)
          if (res.code != 200) {
            this.$message.error(res.msg || res.errorMsg)
          }
        }
        if (changeData.isAdd) {
          let saveData = {
            textAreas: [{ ...changeData, oldTextAreaId: changeData.id }],
            stageName: this.taskDetail.stageName,
            userId: this.userInfo.id
          }
          saveData.textAreas[0].id = null
          saveData.textAreas[0].characterSentences.forEach(c => {
            c.characterSentenceId = null
          })
          this.isAutoSave = true
          this.isSaved = true
          const res = await v1postAction('/textarea/insert_text_area/rollback', saveData)
          this.isAutoSave = false
          this.isSaved = false
          if (res.code != 200) {
            this.$message.error(res.msg || res.errorMsg)
          } else {
            this.pageListData.forEach(page => {
              page.textAreas?.forEach(textArea => {
                if (textArea.id == res.data.textAreas[0].oldTextAreaId) {
                  textArea.id = res.data.textAreas[0].id
                  changeData.id = res.data.textAreas[0].id
                }
              })
            })
            this.changeProcess.forEach(pagelistData => {
              pagelistData.forEach(page => {
                page.textAreas?.forEach(textArea => {
                  if (textArea.id == res.data.textAreas[0].oldTextAreaId) {
                    textArea.id = res.data.textAreas[0].id
                    changeData.id = res.data.textAreas[0].id
                  }
                })
              })
            })
          }
        }
        if (!changeData.isAdd && !changeData.isDelete) {
          this.selectTextAreaDataCopy = JSON.parse(JSON.stringify([changeData]))
          this.saveBack(changeData)
        }
      } else {
        this.saveIndex++
        this.$message.error('没有可撤回操作')
      }
    },
    async saveBack(changeData) {
      if (!changeData) return
      let pageListData = []
      if (this.selectTextAreaDataCopy.length) {
        let pages = []
        this.pageListData.forEach(page => {
          if (this.selectTextAreaDataCopy.some(selectItem => selectItem.pageId == page.id)) {
            pages.push(page)
          }
        })
        pageListData = pages.map(page => {
          return {
            id: page.id,
            filePath: page.filePath,
            fileType: page.fileType,
            textAreas: this.selectTextAreaDataCopy.filter(selectItem => selectItem.pageId == page.id),
            stageName: this.taskDetail.stageName,
            fileName: page.fileName,
            userId: this.userInfo.id
          }
        })
      } else {
        pageListData = []
      }
      if (!pageListData.length) {
        return
      }
      let saveList = JSON.parse(JSON.stringify(pageListData))
      saveList.forEach(page => {
        page.textAreas.forEach(textArea => {
          textArea.characterSentences.forEach(text => {
            if (text.phonetic && text.phonetic.targetCharacterIds) {
              text.phonetic.targetCharacterTempFrontIds = JSON.parse(JSON.stringify(text.phonetic.targetCharacterIds))
              text.phonetic.targetCharacterTempFrontIds[0] = text.characterSentenceId
              text.phonetic.targetCharacterTempFrontIds.forEach(targetId => {
                targetId = targetId + ''
              })
              text.phonetic.tempFrontId = text.characterSentenceId
              text.phonetic.targetCharacterIds = []
            }
            if (typeof text.characterSentenceId != 'number' && (text.characterSentenceId + '').indexOf('new') > -1) {
              text.tempFrontId = text.characterSentenceId
              text.characterSentenceId = null
            }
          })
          if (textArea.id && typeof textArea.id != 'number' && textArea.id.indexOf('new') > -1) {
            textArea.id = null
          }
          if (textArea.characterSentences.length) {
            textArea.showText = textArea.characterSentences
              .map(text => (text.characterText == '<br>' ? '\n' : text.characterText))
              .join('')
            if (this.taskDetail.stageName == '翻译') {
              textArea.translateText = textArea.showText
            } else if (this.taskDetail.stageName == '校对') {
              textArea.revisionText = textArea.showText
            } else if (this.taskDetail.stageName == '终审') {
              textArea.auditText = textArea.showText
            }
          } else {
            textArea.characterSentences = [
              {
                ...this.$refs.centerButtonList.oldTextBatchStyle,
                fontFamily: this.$refs.centerButtonList.textBatchStyle.fontFamily,
                characterSentenceId: null,
                characterText: ''
              }
            ]
          }
        })
      })
      this.isAutoSave = true
      this.isSaved = true
      let saveData = {
        list: saveList,
        selectTextAreaId: changeData.id ? changeData.id : null,
        taskId: this.taskDetail.taskId
      }
      const res = await v1postAction('/textarea/save_list', saveData)
      try {
        this.selectTextAreaDataCopy = []
        this.isSaved = false
        this.ocrLoading = false
        if (res.code == 200) {
          if (changeData.id) {
            this.pageListData.forEach((page, index) => {
              page.textAreas?.forEach(textArea => {
                if (textArea.id == changeData.id && res.data?.find(resPage => resPage.id == page.id)) {
                  res.data
                    ?.find(resPage => resPage.id == page.id)
                    .textAreas?.forEach(rt => {
                      if (rt.id == textArea.id) {
                        textArea.characterSentences.forEach((text, textIdx) => {
                          if (text) text.characterSentenceId = rt.characterSentences[textIdx].characterSentenceId
                          if (text.phonetic) {
                            text.phonetic.targetCharacterIds =
                              rt.characterSentences[textIdx].phonetic.targetCharacterIds
                          }
                        })
                        let pArr = []
                        let brIds = []
                        textArea.showTexts = []
                        textArea.brIndexs = []
                        textArea.pIndexs = []
                        textArea.characterSentences?.forEach((t, pushTtextIndex) => {
                          if (
                            textArea.characterSentences[pushTtextIndex + 1] &&
                            textArea.characterSentences[pushTtextIndex + 1].phonetic &&
                            textArea.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
                          ) {
                            textArea.pIndexs.push(pushTtextIndex)
                          }
                          if (t.characterText != '<br>' && t.characterText != '\n') {
                            pArr.push(t)
                          } else {
                            brIds.push(t.characterSentenceId)
                            textArea.brIndexs.push(pushTtextIndex)
                            if (pArr.length > 1) {
                              /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                            }
                            if (!pArr.length) {
                              pArr.push({
                                ...this.$refs.centerButtonList.oldTextBatchStyle,
                                textAlign: this.$refs.centerButtonList.textBatchStyle.textAlign,
                                fontFamily: this.$refs.centerButtonList.textBatchStyle.fontFamily,
                                characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                                characterText: ' '
                              })
                            }
                            textArea.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                            pArr = []
                          }
                          if (pushTtextIndex == textArea.characterSentences.length - 1 && pArr.length) {
                            textArea.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                          }
                        })
                        brIds.forEach((brId, brPushIdx) => {
                          if (textArea.showTexts[brPushIdx + 1]) {
                            textArea.showTexts[brPushIdx + 1].pId = brId
                          }
                        })
                        if (textArea.showTexts && textArea.showTexts.length) {
                          textArea.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
                        } else {
                          textArea.showTexts = [
                            {
                              pId: 'newP' + Math.round(Math.random() * 10000) + new Date().getTime(),
                              texts: [
                                {
                                  ...this.$refs.centerButtonList.oldTextBatchStyle,
                                  textAlign: this.$refs.centerButtonList.textBatchStyle.textAlign,
                                  fontFamily: this.$refs.centerButtonList.textBatchStyle.fontFamily,
                                  characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                                  characterText: ' '
                                }
                              ],
                              pushTtextIndex: 0
                            }
                          ]
                        }
                      }
                    })
                }
              })
            })
          }
          this.isAutoSave = false
          this.getRevisionList()
          if (this.$refs.contentList) {
            this.getTextList(
              this.$refs.contentList.textView == 'originalText' ? 1 : 2,
              this.$refs.contentList.textInputValue
            )
          }
        } else {
          this.isAutoSave = true
          this.isSaved = false
          this.$message.error(res.msg || res.errorMsg || res.message)
        }
      } catch {
        if (!res || (res && res.code != 200)) {
          this.$message.error('请求超时，数据已暂存')
          this.isAutoSave = true
          this.isSaved = false
          if (localStorage.getItem('translate_temporary_data')) {
            let translate_temporary_data = JSON.parse(localStorage.getItem('translate_temporary_data'))
            localStorage.setItem('translate_temporary_data', JSON.stringify([...translate_temporary_data, saveData]))
          } else {
            localStorage.setItem('translate_temporary_data', JSON.stringify([saveData]))
          }
          this.autoSaveTsData()
        }
      }
    },
    diffData(oldData, newData, type) {
      let changeData = null
      for (let index in oldData) {
        if (changeData) break
        for (let idx in newData) {
          if (index == idx) {
            if (changeData) break
            if (oldData[index].textAreas.length == newData[idx].textAreas.length) {
              for (let oldDataIndex in oldData[index].textAreas) {
                if (changeData) break
                for (let newDataIdx in newData[idx].textAreas) {
                  if (changeData) break
                  if (newDataIdx == oldDataIndex) {
                    let oldText = JSON.parse(JSON.stringify(oldData[index].textAreas[oldDataIndex].textAreaRegion))
                    let newText = JSON.parse(JSON.stringify(newData[idx].textAreas[newDataIdx].textAreaRegion))
                    oldText.selected = false
                    newText.selected = false
                    oldText.isYahoo = false
                    oldText.isBlack = false
                    newText.isYahoo = false
                    newText.isBlack = false
                    if (JSON.stringify(oldText) != JSON.stringify(newText)) {
                      changeData = { ...newData[idx].textAreas[newDataIdx], changeIdx: idx }
                      break
                    }
                    let oldTranslateText = JSON.parse(JSON.stringify(oldData[index].textAreas[oldDataIndex]))
                    let newTranslateText = JSON.parse(JSON.stringify(newData[idx].textAreas[newDataIdx]))
                    oldTranslateText.isYahoo = false
                    oldTranslateText.isBlack = false
                    newTranslateText.isYahoo = false
                    newTranslateText.isBlack = false
                    oldTranslateText.selected = false
                    newTranslateText.selected = false
                    if (JSON.stringify(oldTranslateText) != JSON.stringify(newTranslateText)) {
                      changeData = { ...newData[idx].textAreas[newDataIdx], changeIdx: idx }
                      break
                    }
                  }
                }
              }
            } else {
              if (oldData[index].textAreas.length > newData[index].textAreas.length) {
                if (type == 'back') {
                  changeData = {
                    ...oldData[index].textAreas.find(item => newData[index].textAreas.every(o => o.id != item.id)),
                    changeIdx: index,
                    isDelete: true
                  }
                } else {
                  changeData = {
                    ...oldData[index].textAreas.find(item => newData[index].textAreas.every(o => o.id != item.id)),
                    changeIdx: index,
                    isDelete: true
                  }
                }
              } else {
                if (type == 'back') {
                  changeData = {
                    ...newData[index].textAreas.find(item => oldData[index].textAreas.every(o => o.id != item.id)),
                    changeIdx: index,
                    isAdd: true
                  }
                } else {
                  changeData = {
                    ...newData[index].textAreas.find(item => oldData[index].textAreas.every(o => o.id != item.id)),
                    changeIdx: index,
                    isAdd: true
                  }
                }
              }
              //添加或删除文本框
            }
          }
        }
      }
      return changeData
    },
    async nextData() {
      if (this.isSaved) return
      this.saveIndex++
      if (this.changeProcess[this.saveIndex]) {
        let changeData = null
        let oldData = JSON.parse(JSON.stringify(this.pageListData))
        let newData = JSON.parse(JSON.stringify(this.changeProcess[this.saveIndex]))
        changeData = this.diffData(oldData, newData, 'next')
        this.selectIdx = changeData.changeIdx - 0
        let num = this.showImgLeftListPostions[this.selectIdx].width / this.pageListData[this.selectIdx].width - 0
        let top = (changeData.textAreaRegion.y * num).toFixed(0) - 0
        let height = (changeData.textAreaRegion.height * num).toFixed(0) - 0
        document.getElementsByClassName('show-img-bottom')[0].scrollTop =
          this.showImgLeftListPostions.find(item => item.idx == this.selectIdx).top - 0 + top - height - 200
        this.pageListData = JSON.parse(JSON.stringify(this.changeProcess[this.saveIndex]))
        if (changeData.isDelete) {
          if ((changeData.id + '').indexOf('new') > -1) return
          const res = await v1DeleteAction('/textarea/delete/' + changeData.id)
          if (res.code != 200) {
            this.$message.error(res.msg || res.errorMsg)
          }
        }
        if (changeData.isAdd) {
          let saveData = {
            textAreas: [{ ...changeData, oldTextAreaId: changeData.id }],
            stageName: this.taskDetail.stageName,
            userId: this.userInfo.id
          }
          saveData.textAreas[0].id = null
          saveData.textAreas[0].characterSentences.forEach(c => {
            c.characterSentenceId = null
          })
          this.isAutoSave = true
          this.isSaved = true
          const res = await v1postAction('/textarea/insert_text_area/rollback', saveData)
          this.isAutoSave = false
          this.isSaved = false
          if (res.code != 200) {
            this.$message.error(res.msg || res.errorMsg)
          } else {
            this.pageListData.forEach(page => {
              page.textAreas?.forEach(textArea => {
                if (textArea.id == res.data.textAreas[0].oldTextAreaId) {
                  textArea.id = res.data.textAreas[0].id
                  changeData.id = res.data.textAreas[0].id
                }
              })
            })
            this.changeProcess.forEach(pagelistData => {
              pagelistData.forEach(page => {
                page.textAreas?.forEach(textArea => {
                  if (textArea.id == res.data.textAreas[0].oldTextAreaId) {
                    textArea.id = res.data.textAreas[0].id
                    changeData.id = res.data.textAreas[0].id
                  }
                })
              })
            })
          }
        }
        if (!changeData.isAdd && !changeData.isDelete) {
          this.selectTextAreaDataCopy = JSON.parse(JSON.stringify([changeData]))
          this.saveBack(changeData)
        }
      } else {
        this.saveIndex--
        this.$message.error('没有可重做操作')
      }
    },
    async delePageList(ids) {
      this.saveIndex++
      if (this.changeProcess[this.saveIndex]) {
        this.changeProcess[this.saveIndex] = JSON.parse(JSON.stringify(this.pageListData))
      } else {
        this.changeProcess.push(JSON.parse(JSON.stringify(this.pageListData)))
      }
      let deleIds = ids.join(',')
      this.isSaved = true
      const res = await v1DeleteAction('/textarea/delete/' + deleIds)
      this.isSaved = false
      if (res.code == 200) {
        /* this.$message.success('删除成功') */
        this.rePositionDragBox()
        this.isDeleteLoading = true
        if (res.data && res.data.length) {
          this.pageListData
            .find(page => page.id == res.data[0].pageId)
            .textAreas?.forEach((textArea, textAreaIndex) => {
              textArea.sequenceNo = textAreaIndex + 1
            })
        }
        /* this.getPageList() */
      } else {
        this.$message.error(res.msg || res.errorMsg)
      }
      this.$refs.showImgContent.isDelete = false
    },
    /*     savePageList: simpleDebounce(async function() {
      if (this.$refs.showImgContent && this.$refs.showImgContent.isDelete) return
      this.savePageListSim()
    }, 100), */
    savePageList() {
      if (this.$refs.showImgContent && this.$refs.showImgContent.isDelete) return
      this.savePageListSim()
    },
    async savePageListSim() {
      if (!this.changeAll) return
      let changeData = null
      let oldData = JSON.parse(JSON.stringify(this.pageListData))
      let newData = null
      if (this.changeProcess[this.saveIndex]) {
        newData = JSON.parse(JSON.stringify(this.changeProcess[this.saveIndex]))
      } else {
        newData = JSON.parse(JSON.stringify(this.changeProcess[this.changeProcess.length - 1]))
      }
      for (let index in oldData) {
        if (changeData) break
        for (let idx in newData) {
          if (index == idx) {
            if (changeData) break
            if (oldData[index].textAreas.length == newData[idx].textAreas.length) {
              for (let oldDataIndex in oldData[index].textAreas) {
                if (changeData) break
                for (let newDataIdx in newData[idx].textAreas) {
                  if (changeData) break
                  if (newDataIdx == oldDataIndex) {
                    let oldText = JSON.parse(JSON.stringify(oldData[index].textAreas[oldDataIndex].textAreaRegion))
                    let newText = JSON.parse(JSON.stringify(newData[idx].textAreas[newDataIdx].textAreaRegion))
                    oldText.selected = 0
                    newText.selected = 0
                    if (JSON.stringify(oldText) != JSON.stringify(newText)) {
                      changeData = {
                        ...newData[idx].textAreas[newDataIdx],
                        id: oldData[idx].textAreas[newDataIdx].id,
                        changeIdx: idx
                      }
                      break
                    }
                    let oldTranslateText = JSON.parse(JSON.stringify(oldData[index].textAreas[oldDataIndex]))
                    let newTranslateText = JSON.parse(JSON.stringify(newData[idx].textAreas[newDataIdx]))
                    oldTranslateText.translateTextAreaRegion.editble = 0
                    newTranslateText.translateTextAreaRegion.editble = 0
                    oldTranslateText.textAreaRegion.selected = 0
                    newTranslateText.textAreaRegion.selected = 0
                    if (!newTranslateText.backgroundColor) {
                      newTranslateText.backgroundColor = '#fff'
                    }
                    if (!oldTranslateText.backgroundColor) {
                      oldTranslateText.backgroundColor = '#fff'
                    }
                    for (let newTextKey in newTranslateText.characterSentences) {
                      if (!newTranslateText.characterSentences[newTextKey].strokeColor) {
                        newTranslateText.characterSentences[
                          newTextKey
                        ].strokeColor = this.$refs.centerButtonList.textBatchStyle.strokeColor
                      }
                      if (!newTranslateText.characterSentences[newTextKey].hasMark)
                        newTranslateText.characterSentences[newTextKey].hasMark = 0
                    }
                    for (let newTextKey in oldTranslateText.characterSentences) {
                      if (!oldTranslateText.characterSentences[newTextKey].strokeColor) {
                        oldTranslateText.characterSentences[
                          newTextKey
                        ].strokeColor = this.$refs.centerButtonList.textBatchStyle.strokeColor
                      }
                      if (!oldTranslateText.characterSentences[newTextKey].hasMark)
                        oldTranslateText.characterSentences[newTextKey].hasMark = 0
                    }
                    if (JSON.stringify(oldTranslateText) != JSON.stringify(newTranslateText)) {
                      changeData = {
                        ...newData[idx].textAreas[newDataIdx],
                        id: oldData[idx].textAreas[newDataIdx].i,
                        changeIdx: idx
                      }
                      break
                    }
                  }
                }
              }
            } else {
              changeData = true
              //添加或删除文本框
            }
          }
        }
      }
      if (changeData) {
        let pageListData = []
        if (this.selectTextAreaDataCopy.length) {
          let pages = []
          this.pageListData.forEach(page => {
            if (this.selectTextAreaDataCopy.some(selectItem => selectItem.pageId == page.id)) {
              pages.push(page)
            }
          })
          pageListData = pages.map(page => {
            return {
              id: page.id,
              filePath: page.filePath,
              fileType: page.fileType,
              textAreas: this.selectTextAreaDataCopy.filter(selectItem => selectItem.pageId == page.id),
              stageName: this.taskDetail.stageName,
              fileName: page.fileName,
              userId: this.userInfo.id
            }
          })
        } else {
          /*           pageListData = this.pageListData.map(page => {
            return {
              id: page.id,
              filePath: page.filePath,
              fileType: page.fileType,
              textAreas: page.textAreas,
              stageName: this.taskDetail.stageName,
              fileName: page.fileName,
              userId: this.userInfo.id
            }
          }) */
          pageListData = []
        }
        if (!pageListData.length) {
          /* this.$message.error('保存失败，请重试') */
          return
        }
        let saveList = JSON.parse(JSON.stringify(pageListData))
        saveList.forEach(page => {
          page.textAreas.forEach(textArea => {
            textArea.characterSentences.forEach(text => {
              if (text.phonetic && text.phonetic.targetCharacterIds) {
                text.phonetic.targetCharacterTempFrontIds = JSON.parse(JSON.stringify(text.phonetic.targetCharacterIds))
                text.phonetic.targetCharacterTempFrontIds[0] = text.characterSentenceId
                text.phonetic.targetCharacterTempFrontIds.forEach(targetId => {
                  targetId = targetId + ''
                })
                text.phonetic.tempFrontId = text.characterSentenceId
                text.phonetic.targetCharacterIds = []
              }
              if (text.characterText == ' ' || text.characterText == '<br>') {
                if (textArea.characterSentences[0]) text.isBold = textArea.characterSentences[0].isBold
              }
              if (typeof text.characterSentenceId != 'number' && (text.characterSentenceId + '').indexOf('new') > -1) {
                text.tempFrontId = text.characterSentenceId
                text.characterSentenceId = null
              }
            })
            if (textArea.id && typeof textArea.id != 'number' && textArea.id.indexOf('new') > -1) {
              textArea.id = null
            }
            if (textArea.characterSentences.length) {
              textArea.showText = textArea.characterSentences
                .map(text => (text.characterText == '<br>' ? '\n' : text.characterText))
                .join('')
              if (this.taskDetail.stageName == '翻译') {
                textArea.translateText = textArea.showText
              } else if (this.taskDetail.stageName == '校对') {
                textArea.revisionText = textArea.showText
              } else if (this.taskDetail.stageName == '终审') {
                textArea.auditText = textArea.showText
              }
              if (textArea.characterSentences.length == 1 && textArea.characterSentences[0].characterText == '') {
                textArea.characterSentences[0].isBold = this.$refs.centerButtonList.textBatchStyle.isBold ? 1 : 0
              }
            } else {
              textArea.characterSentences = [
                {
                  ...this.$refs.centerButtonList.oldTextBatchStyle,
                  isBold: this.$refs.centerButtonList.textBatchStyle.isBold ? 1 : 0,
                  fontFamily: this.$refs.centerButtonList.textBatchStyle.fontFamily,
                  characterSentenceId: null,
                  characterText: ''
                }
              ]
            }
          })
        })
        //因为保存比较慢，所以暂时做成先保存撤回记录，再进行保存
        this.saveIndex++
        if (this.changeProcess[this.saveIndex]) {
          this.changeProcess[this.saveIndex] = JSON.parse(JSON.stringify(this.pageListData))
        } else {
          this.changeProcess.push(JSON.parse(JSON.stringify(this.pageListData)))
        }
        this.isAutoSave = true
        this.isSaved = true //保存时禁止操作
        let saveData = {
          list: saveList,
          selectTextAreaId: changeData.id ? changeData.id : null,
          taskId: this.taskDetail.taskId
        }
        if (saveList[0] && saveList[0].textAreas && saveList[0].textAreas.length > 1) {
          this.isAutoSave = false
          this.isSaved = false
          this.isCenterClick = false
          this.isDragText = false
          this.isSaved = false
          this.ocrLoading = false
          const res = await v1postAction('/textarea/save_style_list', saveData)
          /*           this.isCenterClick = false
          this.isDragText = false
          this.isSaved = false
          this.ocrLoading = false
          this.isAutoSave = false
          this.isSaved = false */
          return
        }
        if (saveList[0] && saveList[0].textAreas && saveList[0].textAreas.length == 1) {
          saveData.selectTextAreaId = saveList[0].textAreas[0].id
        }
        let isCenterClick = this.isCenterClick
        const res = await v1postAction('/textarea/save_list', saveData)
        this.isCenterClick = isCenterClick
        this.isDragText = false
        try {
          this.selectTextAreaDataCopy = []
          this.isSaved = false
          this.ocrLoading = false
          if (res.code == 200) {
            if (changeData.id) {
              this.pageListData.forEach((page, index) => {
                page.textAreas?.forEach(textArea => {
                  if (textArea.id == changeData.id && res.data?.find(resPage => resPage.id == page.id)) {
                    res.data
                      ?.find(resPage => resPage.id == page.id)
                      .textAreas?.forEach(rt => {
                        if (rt.id == textArea.id) {
                          let isBold = 0
                          if (rt.characterSentences?.some(text => text['isBold'] > 0)) {
                            isBold = 1
                          }
                          textArea.characterSentences.forEach((text, textIdx) => {
                            if (text) {
                              text.characterSentenceId = rt.characterSentences[textIdx].characterSentenceId
                            }
                            if (text.phonetic) {
                              text.phonetic.targetCharacterIds =
                                rt.characterSentences[textIdx].phonetic.targetCharacterIds
                            }
                          })
                          let pArr = []
                          let brIds = []
                          textArea.showTexts = []
                          textArea.brIndexs = []
                          textArea.pIndexs = []
                          textArea.characterSentences?.forEach((t, pushTtextIndex) => {
                            if (
                              textArea.characterSentences[pushTtextIndex + 1] &&
                              textArea.characterSentences[pushTtextIndex + 1].phonetic &&
                              textArea.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
                            ) {
                              textArea.pIndexs.push(pushTtextIndex)
                            }
                            if (t.characterText != '<br>' && t.characterText != '\n') {
                              pArr.push(t)
                            } else {
                              brIds.push(t.characterSentenceId)
                              textArea.brIndexs.push(pushTtextIndex)
                              if (pArr.length > 1) {
                                /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                              }
                              if (!pArr.length) {
                                pArr.push({
                                  ...this.$refs.centerButtonList.oldTextBatchStyle,
                                  textAlign: this.$refs.centerButtonList.textBatchStyle.textAlign,
                                  fontFamily: this.$refs.centerButtonList.textBatchStyle.fontFamily,
                                  isBold,
                                  characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                                  characterText: ' '
                                })
                              }
                              textArea.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                              pArr = []
                            }
                            if (pushTtextIndex == textArea.characterSentences.length - 1 && pArr.length) {
                              textArea.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                            }
                          })
                          brIds.forEach((brId, brPushIdx) => {
                            if (textArea.showTexts[brPushIdx + 1]) {
                              textArea.showTexts[brPushIdx + 1].pId = brId
                            }
                          })
                          if (textArea.showTexts && textArea.showTexts.length) {
                            textArea.showTexts[0].pId =
                              'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
                            if (
                              textArea.showTexts.length == 1 &&
                              textArea.showTexts[0].texts &&
                              textArea.showTexts[0].texts.length &&
                              textArea.showTexts[0].texts.length == 1 &&
                              textArea.showTexts[0].texts[0].characterText == ''
                            ) {
                              textArea.showTexts[0].texts[0].isBold =
                                saveData.list[0].textAreas[0].characterSentences[0].isBold
                              textArea.characterSentences[0].isBold =
                                saveData.list[0].textAreas[0].characterSentences[0].isBold
                            }
                          } else {
                            textArea.showTexts = [
                              {
                                pId: 'newP' + Math.round(Math.random() * 10000) + new Date().getTime(),
                                texts: [
                                  {
                                    ...this.$refs.centerButtonList.oldTextBatchStyle,
                                    textAlign: saveData.list[0].textAreas[0].characterSentences[0].textAlign,
                                    fontFamily: saveData.list[0].textAreas[0].characterSentences[0].fontFamily,
                                    isBold: saveData.list[0].textAreas[0].characterSentences[0].isBold,
                                    characterSentenceId:
                                      'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                                    characterText: ' '
                                  }
                                ],
                                pushTtextIndex: 0
                              }
                            ]
                            textArea.characterSentences = [
                              {
                                ...this.$refs.centerButtonList.oldTextBatchStyle,
                                textAlign: saveData.list[0].textAreas[0].characterSentences[0].textAlign,
                                fontFamily: saveData.list[0].textAreas[0].characterSentences[0].fontFamily,
                                isBold: saveData.list[0].textAreas[0].characterSentences[0].isBold,
                                characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                                characterText: ''
                              }
                            ]
                          }
                        }
                      })
                  }
                })
              })
            }
            /* this.$message.success('保存成功') */
            /*           this.pageListData.forEach((page, index) => {
            page.textAreas = res.data[index].textAreas
          }) */
            this.isAutoSave = false
            this.getRevisionList()
            if (this.$refs.contentList) {
              this.getTextList(
                this.$refs.contentList.textView == 'originalText' ? 1 : 2,
                this.$refs.contentList.textInputValue
              )
            }
            if (
              this.selectTextAreaData &&
              this.selectTextAreaData.length &&
              this.selectTextAreaData[0].translateTextAreaRegion.editble
            ) {
              this.isAutoSave = true
              this.isEditble = true
            }
          } else {
            this.isAutoSave = true
            this.isSaved = false
            this.$message.error(res.msg || res.errorMsg || res.message)
          }
        } catch {
          if (!res || (res && res.code != 200)) {
            this.$message.error('请求超时，数据已暂存')
            this.isAutoSave = true
            this.isSaved = false
            if (localStorage.getItem('translate_temporary_data')) {
              let translate_temporary_data = JSON.parse(localStorage.getItem('translate_temporary_data'))
              localStorage.setItem('translate_temporary_data', JSON.stringify([...translate_temporary_data, saveData]))
            } else {
              localStorage.setItem('translate_temporary_data', JSON.stringify([saveData]))
            }
            this.autoSaveTsData()
          }
        }
        /* console.log(this.changeProcess, this.saveIndex) */
      } else {
        this.isAutoSave = false
        this.isSaved = false
      }
    },
    autoSaveTsData() {
      if (localStorage.getItem('translate_temporary_data')) {
        this.$message.warning('系统存在暂存数据，后台开始尝试自动保存')
        const interval = setInterval(() => {
          let translate_temporary_data = JSON.parse(localStorage.getItem('translate_temporary_data'))
          let noPushData = []
          translate_temporary_data?.forEach(async (saveData, index) => {
            const res = await v1postAction('/textarea/save_list', saveData)
            try {
              if (res.code != 200) {
                noPushData.push(saveData)
              }
              if (translate_temporary_data.length - 1 == index) {
                if (!noPushData.length) {
                  localStorage.removeItem('translate_temporary_data')
                  this.$message.warning('暂存数据保存成功，系统将于30秒后自动刷新，您也可以直接手动刷新', 0)
                  const timeOut = setTimeout(() => {
                    window.location.reload()
                    clearInterval(interval)
                    clearTimeout(timeOut)
                  }, 30000)
                } else {
                  localStorage.setItem('translate_temporary_data', JSON.stringify(noPushData))
                }
              }
            } catch {
              noPushData.push(saveData)
              if (translate_temporary_data.length - 1 == index) {
                if (!noPushData.length) {
                  localStorage.removeItem('translate_temporary_data')
                  this.$message.warning('暂存数据保存成功，系统将于30秒后自动刷新，您也可以直接手动刷新', 0)
                  const timeOut = setTimeout(() => {
                    window.location.reload()
                    clearInterval(interval)
                    clearTimeout(timeOut)
                  }, 30000)
                } else {
                  localStorage.setItem('translate_temporary_data', JSON.stringify(noPushData))
                }
              }
            }
          })
        }, 30000)
      }
    },
    batchAddTextArea() {
      if (!this.changeAll) return
      if (!this.copyTextArea || !this.copyTextArea.length) return
      const rect_select = {
        x: this.copyMouseX,
        y: this.copyMouseY
      }
      let addPageIndex = 0
      this.showImgLeftListPostions.forEach((page, index) => {
        if (rect_select.y + this.$refs.showImgContent.scrollTop > page.top) {
          addPageIndex = index
        }
      })
      let num = this.showImgLeftListPostions[addPageIndex].width / this.pageListData[addPageIndex].width
      let y =
        rect_select.y -
        this.showImgLeftListPostions[0].top +
        this.$refs.showImgContent.scrollTop -
        (this.showImgLeftListPostions[addPageIndex].top - this.showImgLeftListPostions[0].top)
      let x = rect_select.x
      this.copyTextArea?.forEach((addTextArea, index) => {
        addTextArea.oldId = addTextArea.id
        addTextArea.id = 'new' + Math.round(Math.random() * 10000) + new Date().getTime()
        /* addTextArea.textAreaId = null */
        addTextArea.pageId = this.pageListData[addPageIndex].id
        addTextArea.textAreaRegion.selected = 0
        let moveX = (x / num).toFixed(0) - 0
        if (moveX + addTextArea.textAreaRegion.width * num > this.showImgLeftListPostions[addPageIndex].width) {
          moveX = (this.showImgLeftListPostions[addPageIndex].width - addTextArea.textAreaRegion.width * num - 5) / num
          moveX = moveX.toFixed(0) - 0
        }
        addTextArea.textAreaRegion.x = moveX
        addTextArea.textAreaRegion.y = (y / num).toFixed(0)
        addTextArea.translateTextAreaRegion.x = moveX
        addTextArea.translateTextAreaRegion.y = (y / num).toFixed(0)
        if (!this.isShear) {
          addTextArea.proofreadInjuryType = null
          addTextArea.proofreadInjuryRemark = null
          addTextArea.finalCheckInjuryType = null
          addTextArea.finalCheckInjuryRemark = null
          addTextArea.isFinalCheckInjury = null
          addTextArea.isReject = null
          addTextArea.isProofreadInjury = null
        }
        this.pageListData[addPageIndex].textAreas.push(addTextArea)
        this.pageListData[addPageIndex].textAreas.sort((a, b) => a.textAreaRegion.y - b.textAreaRegion.y)
        this.pageListData[addPageIndex].textAreas.forEach((textArea, idx) => {
          textArea.sequenceNo = idx + 1
        })
      })
      if (this.copyTextArea && this.copyTextArea.length) {
        this.addTextAreaNew(this.copyTextArea[0], true)
      }
    },
    textAreaDbclick() {
      this.isAutoSave = true
      /* this.isSaved = true */
    },
    async addTextAreaNew(textArea, type) {
      let list = this.pageListData.find(page => page.id == textArea.pageId).textAreas
      let addItem = []
      let addIndex = []
      list.forEach((item, idx) => {
        if ((item.id + '').indexOf('new') > -1 && addItem.every(add => add.id != item.id)) {
          addItem.push(item)
          addIndex.push(idx)
        }
      })
      let pageIdx = this.pageListData.findIndex(page => page.id == textArea.pageId)
      this.isAutoSave = true
      this.isSaved = true
      let saveData = {
        textAreas: JSON.parse(JSON.stringify(addItem)),
        stageName: this.taskDetail.stageName,
        userId: this.userInfo.id
      }
      saveData.textAreas?.forEach(textArea => {
        textArea.id = textArea.oldId
        textArea.characterSentences?.forEach(text => {
          if ((text.characterSentenceId + '').indexOf('new') > -1) {
            text.characterSentenceId = null
          }
        })
        delete textArea.oldId
      })
      const res = await v1postAction('/textarea/copy_text_area', saveData)
      if (res.code == 200) {
        addItem.forEach((textArea, textAreaIndex) => {
          res.data.textAreas?.forEach((rTextArea, rTextAreaIndex) => {
            if (textAreaIndex == rTextAreaIndex) {
              textArea.id = rTextArea.id
              textArea.textAreaId = rTextArea.textAreaId
              textArea.characterSentences?.forEach((text, textIndex) => {
                rTextArea.characterSentences?.forEach((rText, rIndex) => {
                  if (rIndex == textIndex) {
                    text.characterSentenceId = rText.characterSentenceId
                    if (text.phonetic && rText.phonetic) {
                      text.phonetic.targetCharacterTempFrontIds = rText.phonetic.targetCharacterTempFrontIds
                      text.phonetic.tempFrontId = rText.phonetic.tempFrontId
                      text.phonetic.characterPhoneticId = rText.phonetic.characterPhoneticId
                      text.phonetic.targetCharacterIds = JSON.parse(
                        JSON.stringify(text.phonetic.targetCharacterTempFrontIds)
                      )
                    }
                  }
                })
              })
              let pArr = []
              let brIds = []
              textArea.showTexts = []
              textArea.brIndexs = []
              textArea.pIndexs = []
              textArea.characterSentences?.forEach((t, pushTtextIndex) => {
                if (
                  textArea.characterSentences[pushTtextIndex + 1] &&
                  textArea.characterSentences[pushTtextIndex + 1].phonetic &&
                  textArea.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
                ) {
                  textArea.pIndexs.push(pushTtextIndex)
                }
                if (t.characterText != '<br>' && t.characterText != '\n') {
                  pArr.push(t)
                } else {
                  brIds.push(t.characterSentenceId)
                  textArea.brIndexs.push(pushTtextIndex)
                  if (pArr.length > 1) {
                    /* pArr = pArr.filter(pt => pt.characterText != ' ') */
                  }
                  if (!pArr.length) {
                    pArr.push({
                      ...this.$refs.centerButtonList.oldTextBatchStyle,
                      textAlign: this.$refs.centerButtonList.textBatchStyle.textAlign,
                      fontFamily: this.$refs.centerButtonList.textBatchStyle.fontFamily,
                      characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                      characterText: ' '
                    })
                  }
                  textArea.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                  pArr = []
                }
                if (pushTtextIndex == textArea.characterSentences.length - 1 && pArr.length) {
                  textArea.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                }
              })
              brIds.forEach((brId, brPushIdx) => {
                if (textArea.showTexts[brPushIdx + 1]) {
                  textArea.showTexts[brPushIdx + 1].pId = brId
                }
              })
              if (textArea.showTexts && textArea.showTexts.length) {
                textArea.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
              } else {
                textArea.showTexts = [
                  {
                    pId: 'newP' + Math.round(Math.random() * 10000) + new Date().getTime(),
                    texts: [
                      {
                        ...this.$refs.centerButtonList.oldTextBatchStyle,
                        textAlign: this.$refs.centerButtonList.textBatchStyle.textAlign,
                        fontFamily: this.$refs.centerButtonList.textBatchStyle.fontFamily,
                        characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                        characterText: ' '
                      }
                    ],
                    pushTtextIndex: 0
                  }
                ]
              }
            }
          })
        })
        if (!type) {
          addItem[0].textAreaRegion.selected = 1
        }
        if (addIndex.length) {
          addItem.forEach((addTextArea, addTextAreaIndex) => {
            addIndex.forEach((index, idx) => {
              if (addTextAreaIndex == idx) {
                list[index] = addTextArea
              }
            })
          })
        }
        if (!type) {
          this.select_list = [this.pageListData[pageIdx].id + '-' + addItem[0].id + '-right']
          this.changeSelectListAdd(addItem[0].pageId + '-' + addItem[0].id + '-left')
        } else if (this.copyTextArea) {
          this.copyTextArea = JSON.parse(JSON.stringify(this.copyTextArea))
          this.isShear = false
          localStorage.setItem('translate_copy_text_area', JSON.stringify({ isShear: false, data: this.copyTextArea }))
        }
        this.saveIndex++
        if (this.changeProcess[this.saveIndex]) {
          this.changeProcess[this.saveIndex] = JSON.parse(JSON.stringify(this.pageListData))
        } else {
          this.changeProcess.push(JSON.parse(JSON.stringify(this.pageListData)))
        }
        if (this.$refs.showImgContent) {
          this.$refs.showImgContent.yahooOrKdxList = []
        }
        this.isAutoSave = false
        this.isSaved = false
        this.$message.success('添加成功')
        if (this.$refs.showImgContent) {
          this.$refs.showImgContent.rightImgShow = false
          this.$refs.showImgContent.leftImgShow = false
        }
        this.$nextTick(() => {
          if (this.$refs.showImgContent) {
            this.$refs.showImgContent.rightImgShow = true
            this.$refs.showImgContent.leftImgShow = true
            let timeOut2 = setTimeout(() => {
              this.$refs.showImgContent.$forceUpdate()
              clearTimeout(timeOut2)
            }, 100)
          }
        })
        this.getRevisionList()
        if (this.$refs.contentList) {
          this.getTextList(
            this.$refs.contentList.textView == 'originalText' ? 1 : 2,
            this.$refs.contentList.textInputValue
          )
        }
      } else {
        this.isAutoSave = true
        this.isSaved = false
        this.$message.error(res.msg || res.errorMsg)
      }
    },
    async addTextArea(textArea, type) {
      let list = this.pageListData.find(page => page.id == textArea.pageId).textAreas
      let addItem = []
      let addIndex = []
      list.forEach((item, idx) => {
        if ((item.id + '').indexOf('new') > -1 && addItem.every(add => add.id != item.id)) {
          addItem.push(item)
          addIndex.push(idx)
        }
      })
      let pageWidth = this.pageListData.find(page => page.id == textArea.pageId).width
      let showPostion = this.showImgLeftListPostions[this.pageListData.findIndex(page => page.id == textArea.pageId)]
      let pageIdx = this.pageListData.findIndex(page => page.id == textArea.pageId)
      const dom = document.getElementsByClassName('show-img-bottom-left')[0].childNodes[0].childNodes[0].childNodes[
        pageIdx
      ]
      let num = showPostion.width / pageWidth - 0
      /*       let width = (addItem.textAreaRegion.width * num).toFixed(0) - 0 || 1
      let height = (addItem.textAreaRegion.height * num).toFixed(0) - 0 || 1
      let top = (addItem.textAreaRegion.y * num).toFixed(0) - 0
      let left = (addItem.textAreaRegion.x * num).toFixed(0) - 0 */
      this.isAutoSave = true
      this.isSaved = true
      let saveData = {
        textAreas: JSON.parse(JSON.stringify(addItem)),
        stageName: this.taskDetail.stageName,
        userId: this.userInfo.id
      }
      saveData.textAreas?.forEach(textArea => {
        textArea.characterSentences?.forEach(text => {
          text.characterSentenceId = null
        })
        textArea.id = null
      })
      const res = await v1postAction('/textarea/insert_text_area', saveData)
      if (res.code == 200) {
        let pageId = null
        let eidtTextArea = null
        addItem.forEach((textArea, textAreaIndex) => {
          res.data.textAreas?.forEach((rTextArea, rTextAreaIndex) => {
            if (textAreaIndex == rTextAreaIndex) {
              textArea.id = rTextArea.id
              textArea.textAreaId = rTextArea.textAreaId
              textArea.characterSentences?.forEach((text, textIndex) => {
                rTextArea.characterSentences?.forEach((rText, rIndex) => {
                  if (rIndex == textIndex) {
                    text.characterSentenceId = rText.characterSentenceId
                  }
                })
              })
              pageId = textArea.pageId
              eidtTextArea = textArea
            }
          })
          if (!textArea.showTexts || !textArea.showTexts.length) {
            textArea.showTexts = [
              {
                pId: 'newP' + Math.round(Math.random() * 10000) + new Date().getTime(),
                texts: textArea.characterSentences,
                pushTtextIndex: 0
              }
            ]
            textArea.brIndexs = []
            textArea.pIndexs = []
          }
        })
        this.$nextTick(() => {
          if (this.$refs.showImgContent && pageId && eidtTextArea) {
            this.$refs.showImgContent.textAreaDbclick(null, eidtTextArea, pageId + '-' + eidtTextArea.id + '-right')
          }
        })
        /*         addItem.id = res.data.textAreas[0].id
        addItem.textAreaId = res.data.textAreas[0].textAreaId
        addItem.characterSentences?.forEach((text, textIndex) => {
          res.data.textAreas[0].characterSentences?.forEach((rText, rIndex) => {
            if (rIndex == textIndex) {
              text.characterSentenceId = rText.characterSentenceId
            }
          })
        }) */
        /*         addItem.characterSentences[0].characterSentenceId =
          res.data.textAreas[0].characterSentences[0].characterSentenceId */
        /*         addItem.showTexts = [
          {
            pId: 'newP' + Math.round(Math.random() * 10000) + new Date().getTime(),
            texts: addItem.characterSentences,
            pushTtextIndex: 0
          }
        ]
        addItem.brIndexs = []
        addItem.pIndexs = [] */
        if (!type) {
          addItem[0].textAreaRegion.selected = 1
        }
        if (addIndex.length) {
          addItem.forEach((addTextArea, addTextAreaIndex) => {
            addIndex.forEach((index, idx) => {
              if (addTextAreaIndex == idx) {
                list[index] = addTextArea
              }
            })
          })
        }
        if (!type) {
          this.select_list = [this.pageListData[pageIdx].id + '-' + addItem[0].id + '-right']
          this.changeSelectListAdd(addItem[0].pageId + '-' + addItem[0].id + '-left')
        } else if (this.copyTextArea) {
          this.copyTextArea = JSON.parse(JSON.stringify(this.copyTextArea))
          this.isShear = false
          localStorage.setItem('translate_copy_text_area', JSON.stringify({ isShear: false, data: this.copyTextArea }))
        }
        this.saveIndex++
        if (this.changeProcess[this.saveIndex]) {
          this.changeProcess[this.saveIndex] = JSON.parse(JSON.stringify(this.pageListData))
        } else {
          this.changeProcess.push(JSON.parse(JSON.stringify(this.pageListData)))
        }
        if (this.$refs.showImgContent) {
          this.$refs.showImgContent.yahooOrKdxList = []
        }
        this.isAutoSave = false
        this.isSaved = false
        this.$message.success('添加成功')
        this.$forceUpdate()
      } else {
        this.isAutoSave = true
        this.isSaved = false
        this.$message.error(res.msg || res.errorMsg)
      }
      this.$forceUpdate()
      /*       html2canvas(dom, {
        useCORS: true,
        width,
        x: left,
        y: top,
        height: height
      }).then(async canvas => {
        const image = canvas.toDataURL('image/jpeg')
        const rest = await v1postAction('/ocr/recognize/base64', { base64: image })
        if (rest.code == 200) {
          addItem.originalText = rest.data.join('\n')
          let saveData = {
            textAreas: [JSON.parse(JSON.stringify(addItem))],
            stageName: this.taskDetail.stageName,
            userId: this.userInfo.id
          }
          saveData.textAreas[0].characterSentences[0].characterSentenceId = null
          saveData.textAreas[0].id = null
          const res = await v1postAction('/textarea/insert_text_area', saveData)
          if (res.code == 200) {
            addItem.id = res.data.textAreas[0].id
            addItem.textAreaId = res.data.textAreas[0].textAreaId
            addItem.textAreaRegion.selected = 1
            let pArr = []
            let brIds = []
            addItem.showTexts=[]
            addItem.characterSentences?.forEach((t, pushTtextIndex) => {
              if (t.characterText != '<br>' && t.characterText != '\n') {
                pArr.push(t)
              } else {
                brIds.push(t.characterSentenceId)
                if (!pArr.length) {
                  pArr.push({
                    ...this.oldTextBatchStyle,
                    textAlign: this.textBatchStyle.textAlign,
                    fontFamily: this.textBatchStyle.fontFamily,
                    characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                    characterText: ' '
                  })
                }
                addItem.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
                pArr = []
              }
              if (pushTtextIndex == addItem.characterSentences.length - 1 && pArr.length) {
                addItem.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
              }
            })
            if (!addItem.showTexts.length)
              addItem.showTexts.push({
                pId: '',
                texts: [
                  {
                    ...this.oldTextBatchStyle,
                    textAlign: this.textBatchStyle.textAlign,
                    fontFamily: this.textBatchStyle.fontFamily,
                    characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                    characterText: ' '
                  }
                ],
                pushTtextIndex: 0
              })
            brIds.forEach((brId, brPushIdx) => {
              if (item.showTexts[brPushIdx + 1]) {
                item.showTexts[brPushIdx + 1].pId = brId
              }
            })
            addItem.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
            if (addIndex > -1) list[addIndex] = addItem
            this.changeSelectList(addItem.pageId + '-' + addItem.id + '-left')
            this.saveIndex++
            if (this.changeProcess[this.saveIndex]) {
              this.changeProcess[this.saveIndex] = JSON.parse(JSON.stringify(this.pageListData))
            } else {
              this.changeProcess.push(JSON.parse(JSON.stringify(this.pageListData)))
            }
            this.isAutoSave = false
            this.isSaved = false
            this.$message.success('添加成功')
          } else {
            this.isAutoSave = true
            this.isSaved = false
            this.$message.error(res.msg||res.errorMsg)
          }
          this.$forceUpdate()
        } else {
          this.isAutoSave = true
          this.isSaved = false
          this.$message.eroor('OCR失败')
        }
      }) */
    },
    /*     goToSelectFirstText(){
      console.log(this.select_list,123)
    }, */
    getFile(item) {
      let url = item.sourceUrl
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url, // 原先后端下载单个文件的路径
          // responseType: "ArrayBuffer"  // 注意看下你自己的原先后端下载地址返回的文件格式 是blob 还是其他的啥 对应这里就要改成后端返回的下载格式
          responseType: 'blob'
        })
          .then(res => {
            resolve(res.data) // 返回文件的blob   Blob {size: 151382, type: 'image/png'}
          })
          .catch(error => {
            reject(error.toString())
          })
      })
    },
    base64ToFile(urlData, fileName) {
      let arr = urlData.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let bytes = atob(arr[1]) // 解码base64
      let n = bytes.length
      let ia = new Uint8Array(n)
      while (n--) {
        ia[n] = bytes.charCodeAt(n)
      }
      return new File([ia], fileName, { type: mime })
    },
    base64ToBlob(dataURI) {
      var base64Arr = dataURI.split(',')
      var imgtype = ''
      var base64String = ''
      if (base64Arr.length > 1) {
        //如果是图片base64，去掉头信息
        base64String = base64Arr[1]
        imgtype = base64Arr[0].substring(base64Arr[0].indexOf(':') + 1, base64Arr[0].indexOf(';'))
      }
      // 将base64解码
      var bytes = atob(base64String)
      //var bytes = base64;
      var bytesCode = new ArrayBuffer(bytes.length)
      // 转换为类型化数组
      var byteArray = new Uint8Array(bytesCode)

      // 将base64转换为ascii码
      for (var i = 0; i < bytes.length; i++) {
        byteArray[i] = bytes.charCodeAt(i)
      }

      // 生成Blob对象（文件对象）
      return new Blob([bytesCode], { type: imgtype })
    },
    getFile(sourceUrl) {
      let url = sourceUrl
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url, // 原先后端下载单个文件的路径
          // responseType: "ArrayBuffer"  // 注意看下你自己的原先后端下载地址返回的文件格式 是blob 还是其他的啥 对应这里就要改成后端返回的下载格式
          responseType: 'blob'
        })
          .then(res => {
            resolve(res.data) // 返回文件的blob   Blob {size: 151382, type: 'image/png'}
          })
          .catch(error => {
            reject(error.toString())
          })
      })
    },
    downLoadImg(type) {
      this.clearSelect()
      this.rightAnnotationList?.forEach(item => [(item.selected = 0)])
      this.allLoadding = true
      this.loaddingText = '图片生成中...'
      this.isDownLoad = true
      document.getElementsByClassName('show-img-bottom')[0].scrollTop = 0
      setTimeout(() => {
        const zip = new JSZip()
        const promises = []
        const cache = []
        const element = document.getElementsByClassName('show-img-bottom-right')[0].childNodes[0].childNodes[0]
        if (type == 'PDF') {
          let index = 0
          let showNodes = []
          let removeNodes = []
          Array.from(document.getElementsByClassName('show-img-bottom-right')[0].getElementsByTagName('img')).forEach(
            (img, imgIdx) => {
              let dom = document
                .getElementsByClassName('show-img-bottom-right')[0]
                .getElementsByClassName('ocr-show-page')[imgIdx]
              showNodes.push({ img: img.cloneNode(true), dom: dom.cloneNode(true) })
              removeNodes.push({ img, dom })
            }
          )
          removeNodes.forEach(item => {
            document
              .getElementsByClassName('show-img-bottom-right')[0]
              .childNodes[0].childNodes[0].removeChild(item.img)
            document.getElementsByClassName('ocr-show-right')[0].removeChild(item.dom)
          })
          removeNodes = []
          let PDF = new JsPDF('', 'pt', 'a4')
          this.nextStepPDF(index, element, zip, cache, promises, PDF, showNodes, removeNodes)
        } else {
          let index = 0
          let showNodes = []
          let removeNodes = []
          Array.from(document.getElementsByClassName('show-img-bottom-right')[0].getElementsByTagName('img')).forEach(
            (img, imgIdx) => {
              let dom = document
                .getElementsByClassName('show-img-bottom-right')[0]
                .getElementsByClassName('ocr-show-page')[imgIdx]
              showNodes.push({ img: img.cloneNode(true), dom: dom.cloneNode(true) })
              removeNodes.push({ img, dom })
            }
          )
          removeNodes.forEach(item => {
            document
              .getElementsByClassName('show-img-bottom-right')[0]
              .childNodes[0].childNodes[0].removeChild(item.img)
            document.getElementsByClassName('ocr-show-right')[0].removeChild(item.dom)
          })
          removeNodes = []
          this.nextStep(index, element, zip, cache, promises, showNodes, removeNodes)
          /*           this.showImgLeftListPostions.forEach((item, idx) => {
            html2canvas(element, {
              useCORS: true,
              width: item.width,
              x: 0,
              y: item.top - this.showImgLeftListPostions[0].top,
              height: item.height - 30
            }).then(canvas => {
              const image = canvas.toDataURL('image/jpeg')
              const promise = this.getFile(image).then(data => {
                const file_name = this.pageListData[idx].fileName.replaceAll('.psd', '.jpg')
                zip.file(file_name, data, { binary: true })
                cache[file_name] = data
              })
              promises.push(promise)
              if (promises.length == this.pageListData.length) {
                Promise.all(promises).then(() => {
                  zip.generateAsync({ type: 'blob' }).then(content => {
                    FileSaver.saveAs(
                      content,
                      this.taskDetail.productionName + '-' + this.taskDetail.chapterOrder + '.zip'
                    )
                  })
                  this.allLoadding = false
                  this.isDownLoad=false
                  this.loaddingText = '加载中...'
                })
              }
            })
          }) */
        }
      }, 1000)
    },
    nextStepPDF(idx, element, zip, cache, promises, PDF, showNodes, removeNodes) {
      let item = this.showImgLeftListPostions[idx]
      /*       Array.from(document.getElementsByClassName('show-img-bottom-right')[0].getElementsByTagName('img')).forEach(
        (img, imgIdx) => {
          let dom = document.getElementsByClassName('show-img-bottom-right')[0].getElementsByClassName('ocr-show-page')[
            imgIdx
          ]
          if (idx == imgIdx) {
            img.style.display = 'initial'
            dom.style.display = 'block'
          } else {
            dom.style.display = 'none'
            img.style.display = 'none'
          }
        }
      ) */
      if (idx) {
        removeNodes.forEach(item => {
          document.getElementsByClassName('show-img-bottom-right')[0].childNodes[0].childNodes[0].removeChild(item.img)
          document.getElementsByClassName('ocr-show-right')[0].removeChild(item.dom)
        })
      }
      document
        .getElementsByClassName('show-img-bottom-right')[0]
        .childNodes[0].childNodes[0].appendChild(showNodes[idx].img)
      document.getElementsByClassName('ocr-show-right')[0].appendChild(showNodes[idx].dom)
      removeNodes = [{ img: showNodes[idx].img, dom: showNodes[idx].dom }]
      setTimeout(() => {
        const promise = domtoimage
          .toJpeg(element, {
            width: item.width,
            height: item.height - 2
            /* filter */
          })
          .then(image => {
            /* const image = canvas.toDataURL('image/jpeg') */
            let contentWidth = item.width
            let contentHeight = item.height - 2
            if (item.height - 2 < item.width) {
              PDF.addPage([item.width, item.height - 2], 'landscape')
            } else {
              PDF.addPage([item.width, item.height - 2])
            }
            PDF.addImage(image, 'JPEG', 0, 0, contentWidth, contentHeight)
            promises.push(promise)
            if (promises.length == this.pageListData.length) {
              PDF.deletePage(1)
              PDF.save(this.taskDetail.productionName + '-' + this.taskDetail.chapterOrder + '.pdf')
              /*              Array.from(
                document.getElementsByClassName('show-img-bottom-right')[0].getElementsByTagName('img')
              ).forEach((img, imgIdx) => {
                let dom = document
                  .getElementsByClassName('show-img-bottom-right')[0]
                  .getElementsByClassName('ocr-show-page')[imgIdx]
                img.style.display = 'initial'
                dom.style.display = 'block'
              }) */
              removeNodes.forEach(node => {
                document
                  .getElementsByClassName('show-img-bottom-right')[0]
                  .childNodes[0].childNodes[0].removeChild(node.img)
                document.getElementsByClassName('ocr-show-right')[0].removeChild(node.dom)
              })
              showNodes.forEach(node => {
                document
                  .getElementsByClassName('show-img-bottom-right')[0]
                  .childNodes[0].childNodes[0].appendChild(node.img)
                document.getElementsByClassName('ocr-show-right')[0].appendChild(node.dom)
              })
              this.allLoadding = false
              this.isDownLoad = false
              this.loaddingText = '加载中...'
              setTimeout(() => {
                if (this.translate_download) {
                  localStorage.setItem('translate_download', '')
                  window.close()
                } else {
                  window.location.reload()
                }
              }, 100)
            } else {
              idx++
              this.nextStepPDF(idx, element, zip, cache, promises, PDF, showNodes, removeNodes)
            }
          })
      }, 500)
    },
    nextStep(idx, element, zip, cache, promises, showNodes, removeNodes) {
      let item = this.showImgLeftListPostions[idx]
      if (idx) {
        removeNodes.forEach(item => {
          document.getElementsByClassName('show-img-bottom-right')[0].childNodes[0].childNodes[0].removeChild(item.img)
          document.getElementsByClassName('ocr-show-right')[0].removeChild(item.dom)
        })
      }
      document
        .getElementsByClassName('show-img-bottom-right')[0]
        .childNodes[0].childNodes[0].appendChild(showNodes[idx].img)
      document.getElementsByClassName('ocr-show-right')[0].appendChild(showNodes[idx].dom)
      removeNodes = [{ img: showNodes[idx].img, dom: showNodes[idx].dom }]
      domtoimage
        .toJpeg(element, {
          width: item.width,
          height: item.height - 2
          /* filter: filter */
        })
        .then(image => {
          const promise = this.getFile(image).then(data => {
            // 下载文件, 并存成blob对象
            let index = idx ? idx - 1 : idx
            let file_name = this.pageListData[index].fileName.replaceAll('.psd', '.jpg') // 获取文件名(因为重复的文件名只会下载一个，故需要加入下标 不同名)
            for (let key in cache) {
              if (key == file_name) {
                index++
              }
            }
            file_name = this.pageListData[index].fileName.replaceAll('.psd', '.jpg')
            zip.file(file_name, data, { binary: true }) // 逐个添加文件
            cache[file_name] = data
          })
          promises.push(promise)
          if (promises.length == this.pageListData.length) {
            Promise.all(promises).then(() => {
              zip
                .generateAsync({ type: 'blob' })
                .then(content => {
                  // 生成二进制流   然后保存文件（如果这个下载不了 也可以将下方这一行换成a标签下载逻辑）
                  let productOrder = this.taskDetail.productOrder + ''
                  let chapterOrder = this.taskDetail.chapterOrder + ''
                  if (productOrder.length == 1) {
                    productOrder = '00' + productOrder
                  } else if (productOrder.length == 2) {
                    productOrder = '0' + productOrder
                  }
                  if (chapterOrder.length == 1) {
                    chapterOrder = '00' + chapterOrder
                  } else if (chapterOrder.length == 2) {
                    chapterOrder = '0' + chapterOrder
                  }
                  const fileName = productOrder + '-' + chapterOrder
                  FileSaver.saveAs(content, fileName + '.zip') // 利用file-saver保存文件  自定义文件名
                })
                .then(() => {
                  setTimeout(() => {
                    if (this.translate_download) {
                      localStorage.setItem('translate_download', '')
                      window.close()
                    } else {
                      window.location.reload()
                    }
                  }, 100)
                })
              /*               Array.from(
                document.getElementsByClassName('show-img-bottom-right')[0].getElementsByTagName('img')
              ).forEach((img, imgIdx) => {
                let dom = document
                  .getElementsByClassName('show-img-bottom-right')[0]
                  .getElementsByClassName('ocr-show-page')[imgIdx]
                img.style.display = 'initial'
                dom.style.display = 'block'
              }) */
              removeNodes.forEach(node => {
                document
                  .getElementsByClassName('show-img-bottom-right')[0]
                  .childNodes[0].childNodes[0].removeChild(node.img)
                document.getElementsByClassName('ocr-show-right')[0].removeChild(node.dom)
              })
              showNodes.forEach(node => {
                document
                  .getElementsByClassName('show-img-bottom-right')[0]
                  .childNodes[0].childNodes[0].appendChild(node.img)
                document.getElementsByClassName('ocr-show-right')[0].appendChild(node.dom)
              })

              this.allLoadding = false
              this.isDownLoad = false
              this.loaddingText = '加载中...'
            })
          } else {
            idx++
            this.nextStep(idx, element, zip, cache, promises, showNodes, removeNodes)
          }
        })
    },
    defualtIsDragText() {
      this.isDragText = false
    },
    clearSelect() {
      if (this.select_list.length && !this.isDragText) {
        if (this.select_list.length < 2) {
          this.copyData()
          this.savePageList()
        }

        this.pageListData.forEach(page => {
          page.textAreas?.forEach(i => {
            i.textAreaRegion.selected = 0
          })
        })
        this.select_list = []
        this.selectTextAreas = []
        this.selectTextAreaData = []
      }
      this.defualtTextType = this.textTypes[0]
    },
    showImgClick() {
      this.copyData()
      this.savePageList()
      this.pageListData.forEach(page => {
        page.textAreas?.forEach(i => {
          i.textAreaRegion.selected = 0
        })
        if (
          this.rightAnnotationList.findIndex(a => a.pageId == page.id) > -1 &&
          this.rightAnnotationList[this.rightAnnotationList.findIndex(a => a.pageId == page.id)]
        ) {
          this.rightAnnotationList[
            this.rightAnnotationList.findIndex(a => a.pageId == page.id)
          ].annotationList?.forEach(item => {
            item.selected = 0
          })
        }
      })
      this.select_list = []
      this.selectTextAreas = []
      this.selectTextAreaData = []
      this.defualtTextType = this.textTypes[0]
    },
    changeIsDragText(data) {
      this.isDragText = data.isDragText
      this.isSelected = true
      this.select_list = []
      this.selectTextAreas = []
      this.selectTextAreaData = []
      if (this.isDragText) {
        this.pageListData.forEach(page => {
          page.textAreas?.forEach(i => {
            if (data.item.id == i.id && data.page.id == page.id) {
              i.textAreaRegion.selected = 1
              if (this.select_list.every(s => s != data.page.id + '-' + data.item.id + '-' + data.ciickType)) {
                this.select_list.push(data.page.id + '-' + data.item.id + '-' + data.ciickType)
              }
            } else {
              i.textAreaRegion.selected = 0
              i.translateTextAreaRegion.editble = 0
              window.getSelection().removeAllRanges()
              this.phoneticTexts = []
            }
          })
        })
        this.selectTextAreaData = [data.item]
        if (this.$refs.contentList) {
          this.$refs.contentList.selectTextId = data.item.id
          this.$refs.contentList.textDefaultKey = [this.pageListData.find(page => page.id == data.page.id).id + '']
        }
      } else {
        this.clearSelect()
      }
      let timeOut = setTimeout(() => {
        this.isSelected = false
        clearTimeout(timeOut)
      }, 500)
    },
    changeLeftOption(value) {
      this.showOptions.leftOptions.forEach(item => {
        if (item.value == value) {
          item.checked = true
        } else {
          item.checked = false
        }
      })
      document.getElementsByClassName('show-img-bottom')[0].scrollTop = 0
      this.selectIdx = 0
      this.$nextTick(() => {
        this.getPageListPostions()
        this.getShowImgListPostions()
      })
    },
    changeSelectIdx(idx) {
      this.changeScrollHeihgt = 0
      this.selectIdx = idx
    },
    changeShowScroll(e, idx) {
      let num = this.showImgLeftListPostions[this.selectIdx].width / e.target.width
      this.changeScrollHeihgt = (e.offsetY * num).toFixed(0) - 0
      this.selectIdx = idx
    },
    async getPageList() {
      if (!this.isDeleteLoading) {
        this.allLoadding = true
      }
      const res = await getAction('/original/list', {
        current: 1,
        size: -1,
        chapterId: this.$route.params.chapterId,
        fileType: this.leftTab
      })
      if (res.code == 200) {
        this.selectIdx = 0
        this.pageListData = res.data?.map((page, idx) => {
          let selected = 0
          page.textAreas.forEach(item => {
            item.showTexts = []
            let arr = []
            let brIds = []
            item.brIndexs = []
            item.pIndexs = []
            item.characterSentences.forEach((t, pushTtextIndex) => {
              t.isBlack = false
              t.isYahoo = false
              if (
                item.characterSentences[pushTtextIndex + 1] &&
                item.characterSentences[pushTtextIndex + 1].phonetic &&
                item.characterSentences[pushTtextIndex + 1].phonetic.phoneticText
              ) {
                item.pIndexs.push(pushTtextIndex)
              }
              if (t.characterText != '<br>') {
                arr.push(t)
              } else {
                brIds.push(t.characterSentenceId)
                item.brIndexs.push(pushTtextIndex)
                if (!arr.length) {
                  arr.push({
                    ...this.$refs.centerButtonList.oldTextBatchStyle,
                    textAlign: this.$refs.centerButtonList.textBatchStyle.textAlign,
                    fontFamily: this.$refs.centerButtonList.textBatchStyle.fontFamily,
                    characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                    characterText: ' '
                  })
                }
                item.showTexts.push({ pId: '', texts: arr, pushTtextIndex })
                arr = []
                if (pushTtextIndex == item.characterSentences.length - 1 && !arr.length && t.characterText == '<br>') {
                  item.showTexts.push({
                    pId: '',
                    texts: [
                      {
                        ...this.$refs.centerButtonList.oldTextBatchStyle,
                        textAlign: this.$refs.centerButtonList.textBatchStyle.textAlign,
                        fontFamily: this.$refs.centerButtonList.textBatchStyle.fontFamily,
                        characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                        characterText: ' '
                      }
                    ],
                    pushTtextIndex
                  })
                }
              }
              if (pushTtextIndex == item.characterSentences.length - 1 && arr.length) {
                item.showTexts.push({ pId: '', texts: arr, pushTtextIndex })
              }
            })
            brIds.forEach((brId, brPushIdx) => {
              if (item.showTexts[brPushIdx + 1]) {
                item.showTexts[brPushIdx + 1].pId = brId
              }
            })
            item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
          })
          if (!idx) selected = 1
          return {
            ...page,
            selected
          }
        })
        this.globalSetting = this.pageListData[0] ? this.pageListData[0].globalSetting : false
        this.changeProcess = [JSON.parse(JSON.stringify(this.pageListData))]
        if (!this.isDeleteLoading) {
          this.loadImg()
        }
        this.getTask()
        const rest = await getAction('/original/list', {
          current: 1,
          size: -1,
          chapterId: this.$route.params.chapterId,
          fileType: 'JPG'
        })
        if (res.code == 200) {
          this.jpgListData = rest.data
          if (!this.pageListData.length) {
            this.leftTab = 'JPG'
            if (this.jpgListData && this.jpgListData.length && this.checkPermission('originJPG:view')) {
              this.showOptions.leftOptions = [
                {
                  title: '原稿',
                  value: 1,
                  checked: true
                }
              ]
              this.showOptions.leftOptions.forEach(item => {
                item.checked = false
              })
              this.showOptions.leftOptions.push({
                title: '原稿JPG',
                value: 2,
                checked: true
              })
            }
          } else {
            if (this.jpgListData && this.jpgListData.length && this.checkPermission('originJPG:view')) {
              this.showOptions.leftOptions = [
                {
                  title: '原稿',
                  value: 1,
                  checked: true
                }
              ]
              this.showOptions.leftOptions.push({
                title: '原稿JPG',
                value: 2,
                checked: false
              })
            }
          }
          this.contentList = this.contentList.filter(item => {
            return (
              (item.id == 1 && this.checkPermission('textType:view')) ||
              item.id == 3 ||
              (item.id == 4 && this.checkPermission('local:view')) ||
              (item.id == 5 && this.checkPermission('revision:view')) ||
              item.id == 6 ||
              item.id == 7 ||
              item.id == 8 ||
              (item.id == 101 && this.checkPermission('allchange:view'))
            )
          })
        }
      } else {
        this.allLoadding = false
        this.$message.error(res.msg || res.errorMsg)
      }
    },
    loadImg() {
      this.$nextTick(() => {
        if (this.$refs.pageListContent) {
          this.$refs.pageListContent.loadImg()
        }
        if (this.$refs.showImgContent) {
          this.$refs.showImgContent.loadImg()
        }
      })
    },
    getPageListPostions() {
      if (
        document.getElementsByClassName('page-item').length &&
        document.getElementsByClassName('page-item')[0].getClientRects()[0]
      ) {
        this.pageListPostions = Array.from(document.getElementsByClassName('page-item')).map((item, idx) => {
          return {
            ...JSON.parse(JSON.stringify(item.getClientRects()[0])),
            idx
          }
        })
      }
    },
    getShowImgListPostions() {
      let top = -1
      if (
        document.getElementsByClassName('show-img-bottom')[0] &&
        document.getElementsByClassName('show-img-bottom')[0].getClientRects()
      ) {
        top = document.getElementsByClassName('show-img-bottom')[0].getClientRects()[0].top
      }
      if (
        this.$refs.showImgContent &&
        this.$refs.showImgContent.rightImgShow &&
        (this.showOptions.leftOptions.length == 1 ||
          (this.showOptions.leftOptions.length > 1 && this.showOptions.leftOptions[1].checked))
      ) {
        this.showImgLeftListPostions = Array.from(document.getElementsByClassName('right-show-img')).map(
          (item, idx) => {
            let i = JSON.parse(JSON.stringify(item.getClientRects()[0]))
            for (let key in i) {
              i[key] = (i[key] - 0).toFixed(0)
            }
            return {
              ...i,
              idx
            }
          }
        )
      } else {
        this.showImgLeftListPostions = Array.from(document.getElementsByClassName('left-show-img')).map((item, idx) => {
          let i = JSON.parse(JSON.stringify(item.getClientRects()[0]))
          for (let key in i) {
            i[key] = (i[key] - 0).toFixed(0)
          }
          return {
            ...i,
            idx
          }
        })
      }
      if (top > -1 && this.showImgLeftListPostions.length) {
        let changeTop = top - this.showImgLeftListPostions[0].top
        this.showImgLeftListPostions.forEach(item => {
          item.top = item.top - 0 + changeTop
        })
      }
      this.bottomHeight = document.getElementsByClassName('show-img-content')[0].clientHeight
      this.textAreaRegions = []
      this.pageListData.forEach((item, idx) => {
        item.textAreas.forEach(i => {
          this.textAreaRegions.push({ pagePosition: this.showImgLeftListPostions[idx], ...i, width: item.width })
        })
      })
      try {
        /* this.allLoadding = false */
      } catch (e) {
        this.$message.error(e)
        this.allLoadding = false
      }
      if (this.$refs.showImgContent) {
        this.$refs.showImgContent.rightImgShow = false
        this.$refs.showImgContent.leftImgShow = false
        let timeOut = setTimeout(() => {
          this.$refs.showImgContent.rightImgShow = true
          this.$refs.showImgContent.leftImgShow = true
          clearTimeout(timeOut)
        }, 0)
        let timeOut2 = setTimeout(() => {
          if (this.$refs.showImgContent) this.$refs.showImgContent.$forceUpdate()
          clearTimeout(timeOut2)
        }, 100)
      }
    },
    getShowImgRightListPostions() {
      let top = -1
      if (
        document.getElementsByClassName('show-img-bottom')[0] &&
        document.getElementsByClassName('show-img-bottom')[0].getClientRects()
      ) {
        top = document.getElementsByClassName('show-img-bottom')[0].getClientRects()[0].top
      }

      this.showImgLeftListPostions = Array.from(document.getElementsByClassName('left-show-img')).map((item, idx) => {
        let i = JSON.parse(JSON.stringify(item.getClientRects()[0]))
        for (let key in i) {
          i[key] = (i[key] - 0).toFixed(0)
        }
        return {
          ...i,
          idx
        }
      })
      if (top > -1 && this.showImgLeftListPostions.length) {
        let changeTop = top - this.showImgLeftListPostions[0].top
        this.showImgLeftListPostions.forEach(item => {
          item.top = item.top - 0 + changeTop
        })
      }
      this.bottomHeight = document.getElementsByClassName('show-img-content')[0].clientHeight
      this.textAreaRegions = []
      this.pageListData.forEach((item, idx) => {
        item.textAreas.forEach(i => {
          this.textAreaRegions.push({ pagePosition: this.showImgLeftListPostions[idx], ...i, width: item.width })
        })
      })
      try {
        this.allLoadding = false
      } catch (e) {
        this.$message.error(e)
        this.allLoadding = false
      }
    },
    async getScrollTop() {
      const res = await postAction('/page/scroll/points', {
        userId: this.userInfo.id,
        productionId: this.taskDetail.productionId,
        chapterId: this.taskDetail.chapterId
      })
      if (res.code == 200) {
        if (res.data && res.data[0]) {
          document.getElementsByClassName('show-img-bottom')[0].scrollTop =
            (res.data[0].positions ? res.data[0].positions.split('-')[0] - 0 : 0) - 0
          this.selectIdx = res.data[0].positions ? res.data[0].positions.split('-')[1] - 0 : 0
        }
      } else {
        this.$message.error(res.msg || res.errorMsg)
      }
    },
    getProduction() {
      getAction('/production/get', {
        id: this.taskDetail.productionId
      }).then(({ data }) => {
        this.departName = data.departName
        this.getDictList()
        this.getFontFamily(data.departId)
      })
    },
    async getTask() {
      const res = await getAction('tasks/get/' + this.$route.params.taskId)
      if (res.code == 200) {
        this.taskDetail = res.data
        //陈立说写死翻译被驳回可编辑
        if (this.taskDetail.taskStatus == '驳回' && this.taskDetail.stageName == '翻译') {
          this.taskDetail.taskStatus = '可开始'
        }
        this.taskStatus = this.taskDetail.taskStatus
        this.taskConfirmStatus = this.taskDetail.taskConfirmStatus == '已确认' ? true : false
        localStorage.setItem('translate_record', JSON.stringify(this.taskDetail))
        document.title = this.taskDetail.productionName + ' · ' + this.taskDetail.chapterOrder
        this.loaddingText = '正在跳转至上次浏览页面'
        if (this.toPageId == 0) {
          this.getScrollTop()
        } else {
          this.copyData()
          this.changeSelectList(this.toPageId + '-' + this.toTextAreaId + '-left')
          let num = this.showImgLeftListPostions[0].width / this.pageListData[0].width - 0
          let pIdx = this.pageListData.findIndex(page => page.id == this.toPageId)
          let p = this.pageListData[pIdx].textAreas.find(i => i.id == this.toTextAreaId)
          let y = (p.textAreaRegion.y * num).toFixed(0) - 0
          document.getElementsByClassName('show-img-bottom')[0].scrollTop =
            this.showImgLeftListPostions[pIdx].top - this.showImgLeftListPostions[0].top + y - 30
        }
        this.loaddingText = '加载中'
        this.allLoadding = false
        this.getTextStyle()
        this.getListGuideByProduc()
        this.getTerms()
        this.getTermsBack()
        this.getTermsNote()
        this.getLocalList()
        this.getChapterList()
        if (this.taskDetail.taskStatus == '可开始' || this.taskDetail.taskStatus == '驳回') {
          if (this.taskDetail.taskStatus == '驳回' && this.taskDetail.rejectTaskId) {
            this.changeAll = true
            this.isRejectStatus = true
          } else {
            this.changeAll = false
          }
          if (this.taskDetail.taskStatus != '驳回') {
            this.changeAll = true
          }
        } else {
          this.changeAll = false
        }
        if (this.$refs.contentList) {
          if (this.taskDetail.stageName == '终审') {
            this.$refs.contentList.revisionType = 2
          }
        }
        this.getProduction()
        if (this.translate_download == 'JPG') {
          this.downLoadImg('JPG')
        } else if (this.translate_download == 'PDF') {
          this.downLoadImg('PDF')
        }
      } else {
        this.isDeleteLoading = false
        this.$message.error(res.msg || res.errorMsg)
      }
    },
    getAllData() {
      this.getPageList()
    },
    handleMouseDown(event) {},
    searchTextList(data) {
      const { type, keyword } = data
      this.getTextList(type, keyword)
    },
    async getTextList(type, keyword) {
      let params = {
        type: type || null,
        keyword: keyword || null,
        chapterId: this.taskDetail.chapterId
      }
      const res = await getAction('/original/text/list', params)
      if (res.code == 200) {
        this.textList = res.data
        this.$forceUpdate()
      } else {
        this.$message.error(res.msg || res.errorMsg)
      }
    },
    optionClick(e, item) {
      /*       if (item.id == 1) {
        item.mouseX = e.target.getClientRects()[0].x - 550
      } else {
        item.mouseX = e.target.getClientRects()[0].x - 350
      } */
      /*       if (item.id == 8) {
        window.open(
          `/production/manage/detail/${this.taskDetail.productionId}?&name=${this.taskDetail.productionName}&tab=6&currentType=3`
        )
        return
      } */
      item.mouseY = e.target.getClientRects()[0].y - e.target.getClientRects()[0].height
      if (item.id > 100) {
        if (!this.changeAll) return
        let position = document.getElementsByClassName('translate')[0].getClientRects()[0]
        item.mouseX = (position.width / 2).toFixed(0) - 180
        item.mouseY = (position.height / 2).toFixed(0) - 100
        if (item.id == 101) {
          /*  if (this.$refs.centerButtonList && this.$refs.contentList) {
            this.$refs.contentList.formData = JSON.parse(JSON.stringify(this.$refs.centerButtonList.textBatchStyle))
          } */
        }
      }
      item.visible = !item.visible
      switch (item.id) {
        case 3:
          this.getTextList()
          break
        case 4:
          this.getLocalList()
          break
        case 6:
          this.getLogs()
          break
        case 5:
          this.getRevisionList()
          break
      }
    },
    showImgMousedown(e) {
      if (this.isWindows && e.button == 1) return
      if (/* this.isDragText ||  */ this.isAddText || this.isAddAnnotation || this.isSelected) return
      if (this.$refs.showImgContent && this.$refs.showImgContent.isSelection) return
      if (this.$refs.showImgContent && this.$refs.showImgContent.dragTextArea) return
      if (this.$refs.showImg) {
        let domX = this.$refs.showImg.getClientRects()[0].x.toFixed(0) - 0
        let domWidth = this.$refs.showImg.getClientRects()[0].width.toFixed(0) - 0
        let domY = this.$refs.showImg.getClientRects()[0].y.toFixed(0) - 0
        if (e.clientX > domX + domWidth - 29 && e.clientX < domX + domWidth - 5 && e.clientY > domY) {
          this.showImgChangeWidth = true
          this.showImgWidth = this.$refs.showImg.clientWidth
          this.optionListWidth = this.$refs.optionList.clientWidth
        } else {
          if (this.showImgChangeWidth) return
          this.is_show_mask = true
          this.start_x = e.clientX
          this.start_y = e.clientY
          this.end_x = e.clientX
          this.end_y = e.clientY
          document.body.addEventListener('mousemove', this.handleMouseMove)
          document.body.addEventListener('mouseup', this.handleMouseUp)
        }
      }
    },
    handleMouseMove(event) {
      if (this.isDragText || this.isAddText || this.isAddAnnotation) {
        this.start_x = 0
        this.start_y = 0
        this.end_x = 0
        this.end_y = 0
        return
      }
      this.end_x = event.clientX
      this.end_y = event.clientY
    },
    handleMouseUp() {
      setTimeout(() => {
        document.body.removeEventListener('mousemove', this.handleMouseMove)
        document.body.removeEventListener('mouseup', this.handleMouseUp)
        this.is_show_mask = false
        this.handleDomSelect()
        this.resSetXY()
      }, 0)
    },
    resSetXY() {
      this.start_x = 0
      this.start_y = 0
      this.end_x = 0
      this.end_y = 0
    },
    collide(rect1, rect2) {
      if (!rect1 || !rect2) return
      const maxX = Math.max(rect1.x + rect1.width, rect2.x + rect2.width)
      const maxY = Math.max(rect1.y + rect1.height, rect2.y + rect2.height)
      const minX = Math.min(rect1.x, rect2.x)
      const minY = Math.min(rect1.y, rect2.y)
      if (maxX - minX <= rect1.width + rect2.width && maxY - minY <= rect1.height + rect2.height) {
        return true
      } else {
        return false
      }
    },
    handleDomSelect() {
      if (this.end_x - this.start_x == 0 && this.start_y - this.end_y == 0) return
      const dom_mask = window.document.querySelector('.mask')
      const rect_select = dom_mask.getClientRects()[0]
      const add_list = []
      const del_list = []
      let doms = [...document.querySelectorAll('.ocr-show-item-right'), ...document.querySelectorAll('.ocr-show-item')]
      doms.forEach(node => {
        if (!node.getClientRects()[0]) return
        const rects = node.getClientRects()[0]
        if (this.collide(rects, rect_select) === true) {
          let arr = []
          arr.push({ id: node.id })
          if (
            this.select_list?.includes(
              arr?.find(item => {
                return item.id == node.id
              }).id
            )
          ) {
            del_list.push(
              arr.find(item => {
                return item.id == node.id
              }).id
            )
          } else {
            add_list.push(
              arr.find(item => {
                return item.id == node.id
              }).id
            )
          }
        }
      })
      this.selectTextAreaData = []
      this.select_list = this.select_list.concat(add_list).filter(item => !del_list.includes(item))
      this.pageListData.forEach(page => {
        page.textAreas?.forEach(i => {
          if (this.select_list.some(item => item.split('-')[1] == i.id && item.split('-')[0] == page.id)) {
            i.textAreaRegion.selected = 1
            this.selectTextAreaData.push(i)
          } else {
            i.textAreaRegion.selected = 0
          }
        })
      })
      const timeOut = setTimeout(() => {
        if (this.selectTextAreaData && this.selectTextAreaData.length > 1) {
          this.isSaved = false
          this.isAutoSave = false
        }
        clearTimeout(timeOut)
      }, 300)
    },
    showImgMouseup(e) {},
    mousemove(e) {
      this.copyMouseX = e.layerX
      this.copyMouseY = e.clientY
      if (this.dragContent.contentDrag) {
        if (this.offsetX == -1) this.offsetX = e.offsetX
        this.dragContent.mouseX = e.clientX - this.offsetX + 20
        this.dragContent.mouseY = e.clientY - this.topHeight
      }
      if (this.$refs.showImg) {
        let domX = this.$refs.showImg.getClientRects()[0].x.toFixed(0) - 0
        let domWidth = this.$refs.showImg.getClientRects()[0].width.toFixed(0) - 0
        let domY = this.$refs.showImg.getClientRects()[0].y.toFixed(0) - 0
        if (
          e.clientX > domX + domWidth - 29 &&
          e.clientX < domX + domWidth - 5 &&
          e.clientY > domY &&
          e.clientY < domY + 30
        ) {
          this.$refs.showImg.style.cursor = 'e-resize'
          if (this.showImgWidth) {
            this.$refs.showImg.style.width = this.showImgWidth + 'px'
            this.$refs.optionList.style.width = this.optionListWidth + 'px'
          }
        } else {
          this.$refs.showImg.style = 'cursor:default '
          if (this.showImgWidth) {
            this.$refs.showImg.style.width = this.showImgWidth + 'px'
            this.$refs.optionList.style.width = this.optionListWidth + 'px'
          }
        }
        if (this.showImgChangeWidth) {
          /*           if (this.mouseX > e.clientX - 26) {
            if (this.showImgWidth <= 400) {
              this.showImgChangeWidth = false
              return
            }
            this.showImgWidth = this.showImgWidth + (e.clientX - 26 - this.mouseX)
            this.optionListWidth = this.optionListWidth - (e.clientX - 26 - this.mouseX)
          } else {
            if (this.optionListWidth <= 0) {
              this.showImgChangeWidth = false
              return
            }
            this.showImgWidth = this.showImgWidth + (e.clientX - 26 - this.mouseX)
            this.optionListWidth = this.optionListWidth - (e.clientX - 26 - this.mouseX)
          } */
          if (this.showImgWidth < 600 || this.optionListWidth < 40) {
            /* this.getAllPositions() */
            this.getPageListPostions()
            this.getShowImgListPostions()
            if (this.$refs.showImgContent) {
              this.$refs.showImgContent.getDragTextBox()
            }
            if (this.showImgWidth < 600) {
              this.showImgWidth = 600
              this.optionListWidth = 1108
            }
            if (this.optionListWidth < 40) this.optionListWidth = 40
            this.showImgChangeWidth = false
            this.$refs.showImg.style.width = this.showImgWidth + 'px'
            this.$refs.optionList.style.width = this.optionListWidth + 'px'
            return
          }
          this.showImgWidth = this.showImgWidth + e.movementX
          this.optionListWidth = this.optionListWidth - e.movementX
          this.$refs.showImg.style.width = this.showImgWidth + 'px'
          this.$refs.optionList.style.width = this.optionListWidth + 'px'
          /* this.getAllPositions() */
          this.getPageListPostions()
          this.getShowImgListPostions()
          if (this.$refs.showImgContent) {
            this.$refs.showImgContent.getDragTextBox()
          }
          /* console.log(document.getElementsByClassName('show-img-bottom')[0].scrollTop) */
          /* console.log(this.$refs.showImg.style.width-48) */
          this.ischangeScroll = true
          this.$refs.showImgContent.rightImgShow = false
          this.$refs.showImgContent.leftImgShow = false
          document.getElementsByClassName('show-img-bottom')[0].scrollTop =
            this.$refs.showImgContent.oldScrollTop * (this.showImgWidth / this.allShowImgWidth)
          this.changeIschangeScroll()
          /*           if (this.showImgWidth < 600 || this.optionListWidth < 40) {
            if (this.showImgWidth < 600) this.showImgWidth = 600
            if (this.optionListWidth < 40) this.optionListWidth = 40
            this.showImgChangeWidth = false
            return
          } */
        }
      }
      this.mouseX = e.clientX - this.offsetX
      this.mouseY = e.clientY - this.topHeight
    },
    changeIschangeScroll: simpleDebounce(async function() {
      this.allShowImgWidth = this.$refs.showImg.clientWidth
      this.ischangeScroll = false
    }, 300),
    getAllPositions: simpleDebounce(async function() {
      this.getAllPositionsSim()
    }, 600),
    getAllPositionsSim() {
      this.getPageListPostions()
      this.getShowImgListPostions()
      if (this.$refs.showImgContent) {
        this.$refs.showImgContent.getDragTextBox()
      }
    },
    mouseup(e) {
      if (this.dragContent.contentDrag) {
        this.dragContent.contentDrag = false
      }
      this.showImgChangeWidth = false
      this.offsetX = -1
      this.handleMouseUp()
    },
    changeContentDarg(item) {
      item.contentDrag = true
      const contentList = JSON.parse(JSON.stringify(this.contentList))
      if (
        contentList.sort((a, b) => {
          return b.zIndex - a.zIndex
        })[0] == 800
      ) {
        item.zIndex++
      } else {
        item.zIndex =
          contentList.sort((a, b) => {
            return b.zIndex - a.zIndex
          })[0].zIndex + 1
      }
      this.dragContent = item
    },
    blackListChangeContentDarg(item) {
      this.dragContent = item
    },
    closeContentDarg(item) {
      item.visible = false
      item.zIndex = 800
      /*       this.pageListData.forEach(page => {
        page.textAreas?.forEach(i => {
          i.textAreaRegion.selected = 0
        })
      }) */
      /*       this.select_list = []
      this.selectTextAreas = []
      this.selectTextAreaData = [] */
      this.isDragText = false
    },
    changePage() {
      if (this.$refs.showImgContent) {
        this.$nextTick(() => {
          this.$refs.showImgContent.changePage()
        })
      }
    },
    changePageListWidth() {
      if (this.pageListShow) {
        this.$refs.pageList.style.width = 0
      } else {
        this.$refs.pageList.style.width = '10%'
      }
      this.pageListShow = !this.pageListShow
      setTimeout(() => {
        this.showImgWidth = this.$refs.showImg.clientWidth
        this.optionListWidth = this.$refs.optionList.clientWidth
        const dom_box = document.querySelector('.drag-box')
        const topDom = document.querySelector('.translate-top')
        this.topHeight = topDom.getClientRects()[0].height - 0 + 12
        this.box_screen_left = dom_box.getBoundingClientRect().left - this.$refs.pageList.getClientRects()[0].width - 24
        this.box_screen_top = dom_box.getBoundingClientRect().top - this.topHeight
        /* this.getShowImgListPostions() */
        if (this.$refs.showImgContent) {
          this.$refs.showImgContent.allUpload()
        }
        if (this.pageListShow) {
          this.getPageListPostions()
        }
      }, 300)
    },
    changeLeftImgShow() {
      this.rePositionDragBox()
      let top = -1
      if (
        document.getElementsByClassName('show-img-bottom')[0] &&
        document.getElementsByClassName('show-img-bottom')[0].getClientRects()
      ) {
        top = document.getElementsByClassName('show-img-bottom')[0].getClientRects()[0].top
      }
      this.showImgLeftListPostions = Array.from(document.getElementsByClassName('right-show-img')).map((item, idx) => {
        let i = JSON.parse(JSON.stringify(item.getClientRects()[0]))
        for (let key in i) {
          i[key] = (i[key] - 0).toFixed(0)
        }
        return {
          ...i,
          idx
        }
      })
      if (top > -1 && this.showImgLeftListPostions.length) {
        let changeTop = top - this.showImgLeftListPostions[0].top
        this.showImgLeftListPostions.forEach(item => {
          item.top = item.top - 0 + changeTop
        })
      }
      this.bottomHeight = document.getElementsByClassName('show-img-content')[0].clientHeight
      this.textAreaRegions = []
      this.pageListData.forEach((item, idx) => {
        item.textAreas.forEach(i => {
          this.textAreaRegions.push({ pagePosition: this.showImgLeftListPostions[idx], ...i, width: item.width })
        })
      })
      try {
        /* this.allLoadding = false */
      } catch (e) {
        this.$message.error(e)
        this.allLoadding = false
      }
      if (this.$refs.showImgContent) {
        this.$refs.showImgContent.rightImgShow = false
        this.$refs.showImgContent.leftImgShow = !this.$refs.showImgContent.leftImgShow
        let timeOut = setTimeout(() => {
          this.$refs.showImgContent.rightImgShow = true
          this.$refs.showImgContent.leftImgShow = !this.$refs.showImgContent.leftImgShow
          clearTimeout(timeOut)
        }, 0)
      }
    },
    changeRightImgShow() {
      this.rePositionDragBox()
      this.getShowImgRightListPostions()
    },
    rePositionDragBox() {
      const dom_box = document.querySelector('.drag-box')
      const topDom = document.querySelector('.translate-top')
      this.topHeight = topDom.getClientRects()[0].height - 0 + 12
      this.box_screen_left = dom_box.getBoundingClientRect().left - this.$refs.pageList.getClientRects()[0].width - 24
      this.box_screen_top = dom_box.getBoundingClientRect().top - this.topHeight
    },
    changeOptionListWidth() {
      if (this.optionListWidth <= 50) {
        this.$refs.optionList.style.width = '5%'
      } else {
        this.$refs.optionList.style.width = '24px'
      }
      this.$nextTick(() => {
        this.showImgWidth = this.$refs.showImg.clientWidth
        this.optionListWidth = this.$refs.optionList.clientWidth
        const dom_box = document.querySelector('.drag-box')
        const topDom = document.querySelector('.translate-top')
        this.topHeight = topDom.getClientRects()[0].height - 0 + 12
        this.box_screen_left = dom_box.getBoundingClientRect().left - this.$refs.pageList.getClientRects()[0].width - 24
        this.box_screen_top = dom_box.getBoundingClientRect().top - this.topHeight
        if (this.$refs.showImgContent) {
          this.$refs.showImgContent.allUpload()
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .ant-modal-wrap {
  z-index: 1040 !important;
}
.no-permission {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.translate {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background: #f0f2f5;
  .mask {
    position: absolute;
    background: #409eff;
    opacity: 0.4;
    z-index: 999;
    transition: none !important;
  }
  .translate-top {
    height: 52px;
    background: #fff;
    position: relative;
    display: flex;
    .translate-top-center {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      .translate-top-center-content {
        width: 100%;
        height: 100%;
      }
    }
  }
  .translate-bottom {
    background: #f0f2f5;
    margin-top: 12px;
    height: calc(100% - 64px);
    display: flex;
    justify-content: space-between;
    /* position: relative; */
    > div {
      height: 100%;
    }
    .translate-bottom-left {
      width: 10%;
      transition: all 0.3s;
      &:hover {
        .translate-bottom-left-show-icon {
          opacity: 1;
        }
      }
      > div {
        height: 100%;
        position: relative;
        background: #fff;
        .translate-bottom-left-show-icon {
          position: absolute;
          z-index: 990;
          top: calc(50% - 50px);
          right: -10px;
          display: flex;
          height: 100px;
          width: 10px;
          background: #fff;
          border-bottom-right-radius: 5px;
          border-top-right-radius: 5px;
          font-size: 9px;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: all 0.3s;
          opacity: 0;
          &:hover {
            color: var(--theme-color);
          }
        }
      }
    }
    .translate-bottom-center {
      flex: 1;
      width: 85%;
      padding: 0 24px 0 24px;
      user-select: none;
      > div {
        width: 100%;
        height: 100%;
        background: #fff;
      }
    }
    .translate-bottom-right {
      width: 5%;
      > div {
        width: 100%;
        height: 100%;
        position: relative;
        .option-list {
          position: absolute;
          padding: 8px;
          &:hover {
            .translate-bottom-right-show-icon {
              opacity: 1;
            }
          }
          right: 0;
          background-color: #fff;
          border-radius: 5px;
          height: 100%;
          .translate-bottom-right-show-icon {
            position: absolute;
            z-index: 990;
            top: calc(50% - 50px);
            left: -10px;
            display: flex;
            height: 100px;
            width: 10px;
            background: #fff;
            border-bottom-left-radius: 5px;
            border-top-left-radius: 5px;
            font-size: 9px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: all 0.3s;
            opacity: 0;
            &:hover {
              color: var(--theme-color);
            }
          }
          .option {
            border-radius: 5px;
            margin-bottom: 8px;
            line-height: 32px;
            text-align: center;
            height: 32px;
            padding: 0 10px 0 10px;
            transition: all 0.3s;
            background: #f0f2f5;
            &:hover {
              color: var(--theme-color);
            }
            cursor: pointer;
          }
          .select-option {
            color: var(--theme-color);
          }
        }
      }
    }
  }
}
.ant-slider {
  width: 7%;
  margin-top: 12px;
  margin-left: 15px;
}
/deep/ .ant-slider-rail {
  background-color: #e1e1e1 !important;
}
/deep/ .ant-slider-track {
  background-color: var(--theme-color) !important;
}
/deep/ .ant-slider-handle:focus {
  border-color: var(--theme-color);
  outline: none;
  box-shadow: unset !important;
}
/deep/ .ant-slider-handle:hover {
  border-color: #6d6e72;
  outline: none;
  box-shadow: 0 0 0 0px var(--theme-color) !important;
}
/deep/ .ant-slider-handle {
  border: 2px solid rgb(223, 220, 221);
}
::-webkit-scrollbar-button {
  height: 0;
}
::-webkit-scrollbar {
  border-radius: 0;
  width: 8px;
}
::-webkit-scrollbar-track {
  border-radius: 0;
}
::-webkit-scrollbar-track-piece {
  border-radius: 0;
}
::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #c1c1c1;
}
@media screen and (max-width: 1850px) {
  .option-list {
    font-size: 12px;
  }
  /deep/ .option {
    font-size: 12px;
    padding: 0 5px 0 5px !important;
  }
}
@media screen and (max-width: 1640px) {
  .option {
    font-size: 10px;
    line-height: 25px !important;
    height: 25px !important;
    padding: 0 5px 0 5px !important;
  }
}
@media screen and (max-width: 1350px) {
  .option {
    font-size: 8px;
    line-height: 23px !important;
    height: 23px !important;
    padding: 0 5px 0 5px !important;
  }
}
@media screen and (max-width: 780px) {
  .translate-top-left-content {
    > div {
      .anticon {
        font-size: 16px !important;
        margin-right: 5px !important;
        display: none !important;
      }
      .i-icon {
        display: none !important;
      }
    }
  }
}
.all-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.1);
  user-select: none;
}
</style>
