<template>
  <a-modal dialogClass="translate-modal" :visible="visible" :footer="false" @ok="handleOk" @cancel="handleCancel">
    <div @mouseover="topCenterMouseover" slot="closeIcon">
      <a-icon type="close" />
    </div>
    <div @mouseover="topCenterMouseover" slot="title" class="modal-title">特殊符号</div>
    <div @mouseover="topCenterMouseover" class="symbol-list">
      <a-row v-for="(item, index) in emjDictList" :key="index">
        <a-col :span="6">{{ item.header }}</a-col>
        <a-col :span="18" style="font-size:16px">
          <span v-for="(i, idx) in item.children" :key="idx" @click="emjClick(i)">
            {{ i }}
          </span>
        </a-col>
      </a-row>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    emjDictList: {
      type: Array,
      defualt: () => []
    }
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    topCenterMouseover() {
      this.$emit('topCenterMouseover')
    },
    emjClick(i) {
      this.$emit('addEmj', i)
      this.visible = false
    },
    open() {
      this.visible = true
    },
    handleOk() {
      this.visible = false
    },
    handleCancel() {
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
.modal-title {
  height: 36px;
  background-color: #ebebeb;
  padding: 2px 12px 2px 12px;
  line-height: 32px;
  font-weight: 400;
}
/deep/ .translate-modal .ant-modal-header {
  padding: 0 !important;
}
/deep/ .translate-modal .ant-modal-body {
  padding: 12px 24px;
}
/deep/ .translate-modal .ant-modal-close {
  height: 36px;
}
/deep/ .translate-modal .ant-modal-close-x {
  height: 36px;
  line-height: 36px;
}
.symbol-list {
  line-height: 32px;
  padding-bottom: 20px;
  span {
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
    &:hover {
      background-color: #e1e1e1;
    }
  }
}
</style>
