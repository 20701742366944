var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        dialogClass: "translate-modal",
        visible: _vm.visible,
        footer: false,
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "div",
        {
          attrs: { slot: "closeIcon" },
          on: { mouseover: _vm.topCenterMouseover },
          slot: "closeIcon",
        },
        [_c("a-icon", { attrs: { type: "close" } })],
        1
      ),
      _c(
        "div",
        {
          staticClass: "modal-title",
          attrs: { slot: "title" },
          on: { mouseover: _vm.topCenterMouseover },
          slot: "title",
        },
        [_vm._v("特殊符号")]
      ),
      _c(
        "div",
        {
          staticClass: "symbol-list",
          on: { mouseover: _vm.topCenterMouseover },
        },
        _vm._l(_vm.emjDictList, function (item, index) {
          return _c(
            "a-row",
            { key: index },
            [
              _c("a-col", { attrs: { span: 6 } }, [
                _vm._v(_vm._s(item.header)),
              ]),
              _c(
                "a-col",
                { staticStyle: { "font-size": "16px" }, attrs: { span: 18 } },
                _vm._l(item.children, function (i, idx) {
                  return _c(
                    "span",
                    {
                      key: idx,
                      on: {
                        click: function ($event) {
                          return _vm.emjClick(i)
                        },
                      },
                    },
                    [_vm._v("\n          " + _vm._s(i) + "\n        ")]
                  )
                }),
                0
              ),
            ],
            1
          )
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }